<div class="terms-wrap">
  <div [routerLink]="'/'" class="terms-logo"></div>
  <cmv-terms-of-use></cmv-terms-of-use>
</div>

<div class="cmv-body terms-footer">
  <a class="first-footer-link" [routerLink]="'/terms'">
    {{ 'components.landingPage.termsOfUse' | translate }}
  </a>
  <a [routerLink]="'/privacy'">
    {{ 'components.landingPage.privacy' | translate }}
  </a>
</div>
