import { Action, createReducer, on } from '@ngrx/store';
import {
  closeFullscreenAction,
  fullscreenVideoAction,
  pauseVideoAction,
  playingVideoAction,
  scrubInVideoAction,
  setFullscreenAction,
  setVideoActualTimeAction,
  waitingVideoAction,
} from '../actions/video.actions';
import { VideoPlayerState } from '../../shared/models/app.state';
import { isIOS } from 'src/app/app.utils';

const initialState: VideoPlayerState = {
  videoFullscreen: false,
  enabledNativeFullscreen: !isIOS(),
  actualTime: 0,
  playerSpeed: 1,
  playing: false,
};

const reducer = createReducer(
  initialState,
  on(playingVideoAction, state => ({
    ...state,
    playing: true,
  })),
  on(pauseVideoAction, (state, { actualTime }) => ({
    ...state,
    playing: false,
    actualTime,
  })),
  on(waitingVideoAction, (state, { actualTime }) => ({
    ...state,
    playing: false,
    actualTime,
  })),
  on(scrubInVideoAction, setVideoActualTimeAction, (state, { actualTime }) => ({
    ...state,
    actualTime,
  })),
  on(fullscreenVideoAction, state => ({
    ...state,
    videoFullscreen: true,
  })),
  on(closeFullscreenAction, state => ({
    ...state,
    videoFullscreen: false,
  })),
  on(setFullscreenAction, (state, { videoFullscreen }) => ({
    ...state,
    videoFullscreen,
  })),
);

export function videoReducer(
  state: VideoPlayerState,
  action: Action,
): VideoPlayerState {
  return reducer(state, action);
}
