<div class="header-wrapper">
  <cmv-header
    [title]="'components.moreView.trash.tabTitle' | translate"
    [actionButtonEnabled]="true"
    [actionButtonIcon]="'cmv-recycle'"
    (actionButtonClicked)="emptyTrash()"
    [actionButtonTooltip]="'tooltips.emptyTrash' | translate"
  ></cmv-header>
</div>

<div
  *ngIf="(trashLoaded$ | async) && (recordersLoaded$ | async); else loading"
  class="more-content-html-elements-override"
>
  <div class="cmv-body description">
    {{ 'components.moreView.trash.description' | translate }}
  </div>

  @let recordingsInTrash = recordingsInTrash$ | async;
  <div>
    @for (recorder of recorders$ | async; track recorder.id) {
      <div>
        <div class="recorder">
          <div class="recorder--header">
            <div class="cmv-h1 recorder--name">
              {{ recorder.name }}
            </div>
            <div class="recorder--visualizer">
              <cmv-free-space-visualizer
                [usedSpace]="recorder.duration"
                [spaceLimit]="recorder.durationLimit"
              ></cmv-free-space-visualizer>
            </div>
          </div>

          <cmv-recorder-matches
            [recordingsDays]="recordingsInTrash[recorder.id]"
          ></cmv-recorder-matches>
        </div>
      </div>
    }
  </div>
</div>

<ng-template #loading>
  <cmv-loading-spinner></cmv-loading-spinner>
</ng-template>
