import { AnnotationList } from '../../../api/models';
import { ErrorModel } from '../../shared/models/app.state';
import { createAction, props } from '@ngrx/store';

export const getRecordingsAnnotationListsRequestAction = createAction(
  'GetRecordingsAnnotationListsRequest',
  props<{
    recordingId: string;
    annListId?: string;
    ids?: string[];
    status?: string;
  }>(),
);
export const getRecordingsAnnotationListsSuccessAction = createAction(
  'GetRecordingsAnnotationListsSuccess',
  props<{ annotationLists: AnnotationList[] }>(),
);
export const getRecordingsAnnotationListsFailureAction = createAction(
  'GetRecordingsAnnotationListsFailure',
  props<{ errors: ErrorModel[] }>(),
);
export const refreshRecordingsAnnotationListRequestAction = createAction(
  'RefreshRecordingsAnnotationListRequestAction',
  props<{
    recordingId: string;
    annListId?: string;
    ids?: string[];
    status?: string;
  }>(),
);
export const refreshAnnotationListRequestAction = createAction(
  'AnnotationListRequest',
  props<{ annotationListId: string; ids?: string[]; status: string }>(),
);

export const downloadAnnotationsListRequestAction = createAction(
  'DownloadAnnotationsListRequest',
  props<{ id: string; annotationsIds: string[] }>(),
);
export const downloadAnnotationsListSuccessAction = createAction(
  'DownloadAnnotationsListSuccess',
  props<{ annotationsList: AnnotationList; annotationsIds: string[] }>(),
);
export const downloadAnnotationsListFailureAction = createAction(
  'DownloadAnnotationsListFailure',
);
