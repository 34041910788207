import { CurrentSelectionsState } from '../../shared/models/app.state';
import {
  changeCurrentVideoAction,
  changeCurrentVideoDurationAction,
  getCurrentSignedVideoSuccessAction,
  noVideoToSignAction,
  setCurrentLiveVideoAction,
  setCurrentVideoAction,
  setLiveTaggingModeAction,
  clearVideoUrlAction,
  addRemuxVideoId,
  removeRemuxVideoId,
  matchesTabIndexChanged,
  setScrollPosition,
  removeDownloadPlaylistId,
  removeDownloadAnnotationList,
  removeDownloadAnnotation,
  setCurrentTaggingTeamAction,
  toggleMultiselectAction,
  toggleAnnotationFilterAction,
  disableMultiselectAction,
  setAnnotationFilterTeamsAction,
  setAnnotationFilterTagsAction,
  disableAnnotationFilterAction,
  selectAnnotationAction,
  unselectAnnotationAction,
} from '../actions/current-selections.actions';
import { Video } from '../../../api/models';
import {
  LiveTaggingModes,
  TaggingTeam,
} from '../../shared/models/current-selections.model';
import {
  downloadAnnotationSuccessAction,
  getAnnotationsRecordingSuccessAction,
} from '../actions/annotation.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { MatchList } from 'src/app/app.constants';
import { renderPlaylistSuccessAction } from '../actions/playlist-list.actions';
import { CurrentVideoModel } from '../../shared/models/current-video.model';
import { downloadAnnotationsListSuccessAction } from '../actions/annotations-lists.actions';
import { arraysEqual } from 'src/app/app.utils';

const initialVideo: CurrentVideoModel = {
  download: '',
  duration: 0,
  size: 0,
  live: false,
  name: '',
  recordingId: '',
  startAt: 0,
  stream: '',
  url: '',
  id: '',
  trueDuration: 0,
  loaded: false,
};

const matchesTabIndexFromStorage = sessionStorage.getItem('matchesTabIndex');
const currentVideoFromStorage = sessionStorage.getItem('currentVideo');
const currentLiveTaggingModeFromStorage = sessionStorage.getItem(
  'currentLiveTaggingMode',
);
const scrollPositionsFromStorage = sessionStorage.getItem('scrollPositions');
const taggingTeamFromStorage = sessionStorage.getItem('taggingTeam');

const initialState: CurrentSelectionsState = {
  currentVideo: currentVideoFromStorage
    ? JSON.parse(currentVideoFromStorage)
    : initialVideo,
  signedVideoUrl: '',
  videoOffsets: undefined,
  remuxingVideoIds: [],
  downloadingPlaylistIds: [],
  downloadingAnnotations: [],
  downloadingAnnotationList: [],
  currentLiveTaggingMode:
    currentLiveTaggingModeFromStorage === LiveTaggingModes.STREAM
      ? LiveTaggingModes.STREAM
      : LiveTaggingModes.PITCH,
  matchesTabIndex: matchesTabIndexFromStorage
    ? parseInt(matchesTabIndexFromStorage)
    : MatchList.RECORDED,
  scrollPositions: scrollPositionsFromStorage
    ? JSON.parse(scrollPositionsFromStorage)
    : {
        [MatchList.RECORDED]: 0,
        [MatchList.UPCOMING]: 0,
      },
  taggingTeam: taggingTeamFromStorage
    ? (taggingTeamFromStorage as TaggingTeam)
    : TaggingTeam.BOTH,
  multiselect: {
    enabled: false,
    selectedIds: [],
  },
  annotationsFilter: {
    enabled: false,
    teams: [],
    tags: [],
  },
};

const reducer = createReducer(
  initialState,
  on(changeCurrentVideoAction, (state, { video }) => ({
    ...state,
    signedVideoUrl:
      video.id === state.currentVideo.id ? state.signedVideoUrl : '',
    currentVideo: {
      ...state.currentVideo,
      ...video,
      loaded: video.id === state.currentVideo.id,
    },
  })),
  on(setCurrentVideoAction, (state, { videos }) => {
    const preferredVideo = checkPreferredVideoType(
      state.currentVideo,
      videos || [],
    );

    return {
      ...state,
      currentVideo: {
        ...state.currentVideo,
        ...(videos ? preferredVideo : initialState.currentVideo),
        loaded:
          !preferredVideo ||
          (state.currentVideo.id === preferredVideo?.id &&
            state.currentVideo.loaded),
      },
      signedVideoUrl:
        state.currentVideo.id === preferredVideo?.id
          ? state.signedVideoUrl
          : '',
    };
  }),
  on(setCurrentLiveVideoAction, (state, { videos }) => {
    const liveVideos = videos.filter(v => v.live);

    const preferredVideo = checkPreferredVideoType(
      state.currentVideo,
      liveVideos,
      videos[0],
    );

    return {
      ...state,
      currentVideo: {
        ...state.currentVideo,
        ...preferredVideo,
        loaded:
          state.currentVideo.id === preferredVideo.id &&
          state.currentVideo.loaded,
      },
    };
  }),
  on(changeCurrentVideoDurationAction, (state, { duration }) => ({
    ...state,
    currentVideo: {
      ...state.currentVideo,
      trueDuration: duration,
      loaded: true,
    },
  })),
  on(setLiveTaggingModeAction, (state, { mode }) => ({
    ...state,
    currentLiveTaggingMode: mode,
  })),
  on(
    getAnnotationsRecordingSuccessAction,
    (state, { recording: { videos } }) => ({
      ...state,
      currentVideo: {
        ...state.currentVideo,
        ...(videos
          ? checkPreferredVideoType(state.currentVideo, videos)
          : initialState.currentVideo),
      },
    }),
  ),
  on(getCurrentSignedVideoSuccessAction, (state, { stream }) => ({
    ...state,
    signedVideoUrl: stream,
  })),
  on(noVideoToSignAction, state => ({
    ...state,
    signedVideoUrl: '',
  })),
  on(clearVideoUrlAction, state => ({
    ...state,
    currentVideo: {
      ...state.currentVideo,
      url: '',
      stream: '',
      download: '',
      loaded: false,
    },
    signedVideoUrl: '',
  })),
  on(addRemuxVideoId, (state, { videoId }) => ({
    ...state,
    remuxingVideoIds: videoId
      ? [...state.remuxingVideoIds, videoId]
      : state.remuxingVideoIds,
  })),
  on(removeRemuxVideoId, (state, { videoId }) => ({
    ...state,
    remuxingVideoIds: state.remuxingVideoIds.filter(id => id !== videoId),
  })),
  on(matchesTabIndexChanged, (state, { index }) => ({
    ...state,
    matchesTabIndex: index,
  })),
  on(setScrollPosition, (state, { index, scrollPosition }) => ({
    ...state,
    scrollPositions: {
      ...state.scrollPositions,
      [index]: scrollPosition,
    },
  })),
  on(downloadAnnotationSuccessAction, (state, { annotation }) => ({
    ...state,
    downloadingAnnotations: [
      ...state.downloadingAnnotations,
      {
        id: annotation.id,
        recordingId: annotation.recordingId,
      },
    ],
  })),
  on(removeDownloadAnnotation, (state, { annotationId }) => ({
    ...state,
    downloadingAnnotations: state.downloadingAnnotations.filter(
      i => i.id !== annotationId,
    ),
  })),
  on(
    downloadAnnotationsListSuccessAction,
    (state, { annotationsList, annotationsIds }) => ({
      ...state,
      downloadingAnnotationList: [
        ...state.downloadingAnnotationList,
        { id: annotationsList.id, annotationsIds: annotationsIds },
      ],
    }),
  ),
  on(
    removeDownloadAnnotationList,
    (state, { annotationListId, annotationsIds }) => ({
      ...state,
      downloadingAnnotationList: state.downloadingAnnotationList.filter(
        info =>
          info.id !== annotationListId ||
          !arraysEqual(info.annotationsIds, annotationsIds),
      ),
    }),
  ),
  on(renderPlaylistSuccessAction, (state, { playlist }) => ({
    ...state,
    downloadingPlaylistIds: [...state.downloadingPlaylistIds, playlist.id],
  })),
  on(removeDownloadPlaylistId, (state, { playlistId }) => ({
    ...state,
    downloadingPlaylistIds: state.downloadingPlaylistIds.filter(
      id => id !== playlistId,
    ),
  })),
  on(setCurrentTaggingTeamAction, (state, { team }) => ({
    ...state,
    taggingTeam: team,
  })),
  on(disableMultiselectAction, state => ({
    ...state,
    multiselect: {
      enabled: false,
      selectedIds: [],
    },
  })),
  on(disableAnnotationFilterAction, state => ({
    ...state,
    annotationsFilter: {
      enabled: false,
      teams: [],
      tags: [],
    },
  })),
  on(toggleMultiselectAction, state => ({
    ...state,
    multiselect: {
      ...state.multiselect,
      enabled: !state.multiselect.enabled,
    },
  })),
  on(toggleAnnotationFilterAction, state => ({
    ...state,
    annotationsFilter: {
      ...state.annotationsFilter,
      enabled: !state.annotationsFilter.enabled,
    },
  })),
  on(setAnnotationFilterTeamsAction, (state, { teams }) => ({
    ...state,
    annotationsFilter: {
      ...state.annotationsFilter,
      teams,
    },
  })),
  on(setAnnotationFilterTagsAction, (state, { tags }) => ({
    ...state,
    annotationsFilter: {
      ...state.annotationsFilter,
      tags,
    },
  })),
  on(selectAnnotationAction, (state, { annotationId }) => ({
    ...state,
    multiselect: {
      ...state.multiselect,
      selectedIds: [...state.multiselect.selectedIds, annotationId],
    },
  })),
  on(unselectAnnotationAction, (state, { annotationId }) => ({
    ...state,
    multiselect: {
      ...state.multiselect,
      selectedIds: state.multiselect.selectedIds.filter(
        id => id !== annotationId,
      ),
    },
  })),
);

const checkPreferredVideoType = (
  currentVideo: Video,
  recordingVideos: Video[],
  defaultVideo: Video = initialVideo,
): Video => {
  const foundPreferred = (recordingVideos || []).find(
    recVid => recVid.name.toLowerCase() === currentVideo.name.toLowerCase(),
  );
  return foundPreferred
    ? foundPreferred
    : recordingVideos && recordingVideos[0]
      ? recordingVideos[0]
      : defaultVideo;
};

export function currentSelectionsReducer(
  state: CurrentSelectionsState,
  action: Action,
): CurrentSelectionsState {
  return reducer(state, action);
}
