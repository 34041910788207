import * as AWS from 'aws-sdk';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class LoggerService {
  readonly logGroupName: string;
  readonly logStreamName = `session-${Date.now()}`;
  readonly cloudWatchLogs: AWS.CloudWatchLogs;

  logStreamCreated = false;

  constructor() {
    if (environment.logger.enabled) {
      AWS.config.update({
        region: environment.logger.region,
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: environment.logger.identityPoolId,
        }),
      });

      this.cloudWatchLogs = new AWS.CloudWatchLogs();
      this.logGroupName = environment.logger.logGroupName;
    }
  }

  async createLogStream() {
    try {
      await this.cloudWatchLogs
        .createLogStream({
          logGroupName: this.logGroupName,
          logStreamName: this.logStreamName,
        })
        .promise();
      this.logStreamCreated = true;
    } catch (error) {
      console.error('Error creating log stream:', error);
    }
  }

  async logError(error: any) {
    if (!environment.logger.enabled) {
      return;
    }

    const errorMessage = {
      type: 'error',
      pageUrl: window.location.href,
      userAgent: window.navigator.userAgent,
      timestamp: new Date().getTime(),
      message: error.message || 'Unknown error',
      stack: error.stack || 'No stack trace available',
    };

    const logParams = {
      logGroupName: this.logGroupName,
      logStreamName: this.logStreamName,
      logEvents: [
        {
          message: JSON.stringify(errorMessage),
          timestamp: new Date().getTime(),
        },
      ],
    };

    if (!this.logStreamCreated) {
      await this.createLogStream();
    }

    this.cloudWatchLogs.putLogEvents(logParams, err => {
      if (err) {
        console.error('Error sending log to CloudWatch:', err);
      }
    });
  }
}
