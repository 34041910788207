import { createSelector } from '@ngrx/store';
import { Recording } from 'src/api/models';
import { dateKey, groupRecordingsByKey } from 'src/app/app.utils';
import { AppState, TrashState } from '../../shared/models/app.state';

export const $trash = (state: AppState) => state.trash;

export const $trashEntities = createSelector(
  $trash,
  (state: TrashState): Recording[] =>
    state.entities ? Object.values(state.entities) : [],
);

export const $trashLoaded = createSelector($trash, state => state.loaded);

export const $recordingsInTrash = createSelector($trashEntities, recordings => {
  let recordingsByRecorder = groupRecordingsByKey(
    recordings,
    (recording: Recording) => recording.recorderId,
  );
  let recordingGroup: { [key: string]: { [key: string]: Recording[] } } = {};
  Object.keys(recordingsByRecorder).forEach(
    recorderId =>
      (recordingGroup[recorderId] = groupRecordingsByKey(
        recordingsByRecorder[recorderId],
        (recording: Recording) => dateKey(recording.dateTime),
      )),
  );

  return recordingGroup;
});
