import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FileDownloadService {
  downloadFile(url: string, name?: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = name || this.getFilenameFromUrl(url);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private getFilenameFromUrl(url: string): string {
    return decodeURI(url).split('?')[0].split('/').pop() || '';
  }
}
