import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RoutePath } from '../../../app.constants';
import { Router, RouterLinkActive, RouterLink } from '@angular/router';
import { $lBreakPoint } from '../../../store/selectors/responsivity.selectors';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { $moreSubmenuOpen } from '../../../store/selectors/ui-flags.selectors';
import { toggleMoreSubmenuAction } from '../../../store/actions/ui-flags.actions';
import { NgIf, NgClass, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-navigation-item',
  templateUrl: './navigation-item.component.html',
  styleUrls: ['./navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    RouterLinkActive,
    RouterLink,
    NgClass,
    NgTemplateOutlet,
    MatIcon,
    AsyncPipe,
    TranslateModule,
  ],
})
export class NavigationItemComponent {
  routePath = RoutePath;
  @Input() customRouterLink: string;
  @Input() externalLink: string | undefined = undefined;
  @Input() counter: number;
  @Input() iconName: string;
  @Input() iconClass: string;
  @Input() submenu = false;
  @Output() onClick = new EventEmitter(); // eslint-disable-line @angular-eslint/no-output-on-prefix

  version: string = require('../../../../../package.json').version;

  isSubmenuOpen$ = this.store.pipe(
    filter(() => this.submenu),
    select($moreSubmenuOpen),
  );
  readonly unsubscribe$ = new Subject<void>();

  readonly lBreakPoint$ = this.store.pipe(select($lBreakPoint));

  constructor(
    readonly router: Router,
    readonly store: Store<AppState>,
  ) {}

  switchSubmenu(): void {
    if (this.submenu) {
      this.store.dispatch(toggleMoreSubmenuAction());
    }
  }
}
