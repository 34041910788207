import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, EMPTY } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ApiService } from 'src/app/core/services/api.service';
import { APIClient } from '../../../api';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  getRecordersFailureAction,
  getRecordersRequestAction,
  getRecordersSuccessAction,
  refreshRecordersByIdsAction,
  refreshRecordersRequestAction,
} from '../actions/recorders.actions';
import { AppState } from '../../shared/models/app.state';
import { $recorders, $recordersState } from '../selectors/recorders.selectors';

@Injectable()
export class RecordersEffects {
  refreshRecorders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshRecordersRequestAction),
      switchMap(() =>
        this.apiService
          .call(() => this.apiClient.getFeRecorder({}))
          .pipe(
            map(recorders => getRecordersSuccessAction({ recorders })),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getRecordersFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getRecorders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecordersRequestAction),
      map(() => refreshRecordersRequestAction()),
    ),
  );

  refreshRecordersById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshRecordersByIdsAction),
      withLatestFrom(this.store.select($recorders)),
      switchMap(([{ ids }, recorders]) => {
        for (let i = 0, len = ids.length; i < len; i++) {
          if (recorders.find(r => r.id === ids[i])) {
            return of(refreshRecordersRequestAction());
          }
        }
        return EMPTY;
      }),
    ),
  );

  saveRecordersState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getRecordersSuccessAction),
        withLatestFrom(this.store.select($recordersState)),
        tap(([{}, state]) => {
          sessionStorage.setItem('recordersState', JSON.stringify(state));
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
    private readonly store: Store<AppState>,
  ) {}
}
