import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { NgFor, NgIf } from '@angular/common';
import { VersionInfoComponent } from '../../../features/changelog/components/version-info/version-info.component';
import { LoadingSpinnerComponent } from '../../../shared/components/loading-spinner/loading-spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { CHANGELOG_VERSIONS } from 'src/app/features/changelog/changelog.contants';

@Component({
  selector: 'cmv-changelog-view',
  templateUrl: './changelog-view.component.html',
  styleUrls: ['./changelog-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    HeaderComponent,
    NgFor,
    VersionInfoComponent,
    NgIf,
    LoadingSpinnerComponent,
    TranslateModule,
  ],
})
export class ChangelogViewComponent {
  versions: Array<{ version: string; loaded: boolean }>;
  loaded = false;

  constructor() {
    this.versions = [...CHANGELOG_VERSIONS].reverse().map(v => ({
      version: v,
      loaded: false,
    }));
    this.loaded = false;
  }

  onLoad(version: string) {
    let index = this.versions.findIndex(v => v.version === version);
    this.versions[index].loaded = true;
    this.loaded = this.versions.filter(v => v.loaded === false).length === 0;
  }
}
