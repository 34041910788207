import { Action, createReducer, on } from '@ngrx/store';
import { SharingRecordingState } from '../../shared/models/app.state';
import {
  getRecordingSharingsRequestAction,
  getRecordingSharingsSuccessAction,
  postSharingRecordingSuccessAction,
  removeSharingRecordingSuccessAction,
} from '../actions/sharing-recording.actions';

const initialState: SharingRecordingState = {
  entity: {
    id: '',
    sharings: {
      users: [],
      teams: [],
    },
  },
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(getRecordingSharingsRequestAction, (state, { id }) => ({
    ...state,
    entity: {
      id,
      sharings: {
        users: [],
        teams: [],
      },
    },
    loading: true,
    loaded: false,
  })),
  on(
    getRecordingSharingsSuccessAction,
    postSharingRecordingSuccessAction,
    removeSharingRecordingSuccessAction,
    (state, { sharings }) => ({
      ...state,
      entity: {
        id: state.entity?.id ?? '',
        sharings,
      },
      loading: false,
      loaded: true,
    }),
  ),
  // TODO FAILURE
);

export function sharingRecordingReducer(
  state: SharingRecordingState,
  action: Action,
): SharingRecordingState {
  return reducer(state, action);
}
