export const RANGE_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'D MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

/* normal null is not working correctly in template if used as form value */
export const TEMPLATE_NULL = 'null';

export const ALMOST_ONE_DAY_HOURS = 23;
export const ALMOST_ONE_HOUR_MINUTES = 59;
