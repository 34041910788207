import { AppState } from '../../shared/models/app.state';
import { createSelector } from '@ngrx/store';
import {
  $currentRecordingAnnotationsIds,
  $isMultiselectEnabled,
  $selectedAnnotationIds,
} from './current-selections.selectors';
import { arraysEqual } from 'src/app/app.utils';
import { RenderStatus } from 'src/api/models';

export const $anotationLists = (state: AppState) => state.annotationLists;

export const $shareableList = createSelector(
  $anotationLists,
  ({ entities }) => entities[Object.keys(entities)[0]],
);

export const $canDownloadAnnotationList = createSelector(
  $shareableList,
  $currentRecordingAnnotationsIds,
  $isMultiselectEnabled,
  $selectedAnnotationIds,
  (
    annotationList,
    recordingAnnotationIds,
    isMultiselectEnabled,
    selectedAnnotationIds,
  ) => {
    const annotationIds = isMultiselectEnabled
      ? selectedAnnotationIds
      : recordingAnnotationIds;
    let downloadInfo = annotationList?.downloads?.find(d =>
      arraysEqual(d.ids, annotationIds),
    );

    return (
      downloadInfo &&
      downloadInfo.status === RenderStatus.DONE &&
      downloadInfo.url !== ''
    );
  },
);

export const $isAnnotationListRendering = createSelector(
  $shareableList,
  $currentRecordingAnnotationsIds,
  $isMultiselectEnabled,
  $selectedAnnotationIds,
  (
    annotationList,
    recordingAnnotationIds,
    isMultiselectEnabled,
    selectedAnnotationIds,
  ) => {
    const annotationIds = isMultiselectEnabled
      ? selectedAnnotationIds
      : recordingAnnotationIds;
    let downloadInfo = annotationList?.downloads?.find(d =>
      arraysEqual(d.ids, annotationIds),
    );

    return (
      downloadInfo &&
      (downloadInfo.status === RenderStatus.RENDERING ||
        downloadInfo.status === RenderStatus.ZIPPING)
    );
  },
);
