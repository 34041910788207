import { AuthModel } from '../../shared/models/auth.model';
import { createAction, props } from '@ngrx/store';

export const loginAction = createAction('Login');
export const loginSuccessAction = createAction(
  'LoginSuccess',
  props<{ auth: AuthModel }>(),
);
export const loginFailureAction = createAction(
  'LoginFailure',
  props<{ errors: any }>(),
);
export const handleLoginAction = createAction('HandleLogin');
export const logoutAction = createAction('Logout');
export const logoutOfPlatformAction = createAction('LogoutOfPlatformAction');
export const refreshTokenAction = createAction(
  'RefreshToken',
  props<{ token: string }>(),
);
