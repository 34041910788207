import { CurrentVideoModel } from './current-video.model';
import { OffsetModel } from '@videogular/ngx-videogular/core';
import { MultiselectModel } from './multiselect.model';
import { AnnotationsFilterModel } from './annotations-filter.model';

export enum LiveTaggingModes {
  PITCH = 'PITCH',
  STREAM = 'STREAM',
}

export enum TaggingTeam {
  BOTH = 'BOTH',
  HOME = 'HOME',
  AWAY = 'AWAY',
}

export interface CurrentSelectionsModel {
  currentVideo: CurrentVideoModel;
  signedVideoUrl: string;
  videoOffsets: OffsetModel | undefined;
  currentLiveTaggingMode: LiveTaggingModes;
  remuxingVideoIds: string[];
  downloadingPlaylistIds: string[];
  downloadingAnnotations: { id: string; recordingId: string }[];
  downloadingAnnotationList: { id: string; annotationsIds: string[] }[];
  matchesTabIndex: number;
  scrollPositions: { [x: number]: number };
  taggingTeam: TaggingTeam;
  multiselect: MultiselectModel;
  annotationsFilter: AnnotationsFilterModel;
}
