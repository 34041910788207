<div
  tabindex="0"
  #elementToFocus
  class="cmv-modal-outer-card first-login-mobile"
>
  <div class="cmv-modal-inner-card inner-card-first-login">
    <div class="cmv-modal-close-button" (click)="closeModal(false)">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info">
      <div class="icon-wrapper icon-wrapper-first-login">
        <mat-icon
          class="modal-icon cmv-gray-icon"
          svgIcon="cmv-player"
        ></mat-icon>
      </div>

      <div class="cmv-body subtitle">
        <p class="first-login-paragraph">
          <cmv-terms-of-use
            [modalVersion]="true"
          ></cmv-terms-of-use>
        </p>
      </div>
    </div>
    <div class="buttons buttons-first-time-login">
      <div class="cmv-accept-button" (click)="closeModal(true)">
        <div class="cmv-h4">{{ 'basic.accept' | translate }}</div>
      </div>
      <div class="cmv-decline-button" (click)="closeModal(false)">
        <div class="cmv-h4">
          {{ 'basic.decline' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
