import { AppState } from '../../shared/models/app.state';

export const $snackbarMessage = ({ snackbar }: AppState) =>
  snackbar.infoMessage;
export const $snackbarMessageParameters = ({ snackbar }: AppState) =>
  snackbar.messageParameters;
export const $snackbarIcon = ({ snackbar }: AppState) =>
  snackbar.icon || 'closing';
export const $snackbarEntryBegin = ({ snackbar }: AppState) =>
  snackbar.entryBegin || '';
export const $snackbarEntryEnd = ({ snackbar }: AppState) =>
  snackbar.entryEnd || '';
export const $snackbarWillHideAt = ({ snackbar }: AppState) =>
  snackbar.willHideAt;
export const $snackbarUndoTagId = ({ snackbar }: AppState) =>
  snackbar.undoTagId;
