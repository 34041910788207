import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { APIClient } from '../../../api';
import { AppState } from '../../shared/models/app.state';
import {
  downloadAnnotationsListFailureAction,
  downloadAnnotationsListRequestAction,
  downloadAnnotationsListSuccessAction,
  getRecordingsAnnotationListsFailureAction,
  getRecordingsAnnotationListsRequestAction,
  getRecordingsAnnotationListsSuccessAction,
  refreshAnnotationListRequestAction,
  refreshRecordingsAnnotationListRequestAction,
} from '../actions/annotations-lists.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  $currentRecording,
  $userDownloadingAnnotationList,
} from '../selectors/current-selections.selectors';
import { ApiService } from 'src/app/core/services/api.service';
import { $shareableList } from '../selectors/annotations-lists.selectors';
import { arraysEqual, reportToGA } from 'src/app/app.utils';
import { GoogleAnalyticsEvent } from 'src/app/app.constants';
import { showSnackbarAction } from '../actions/snackbar.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { RenderStatus } from 'src/api/models';
import { FileDownloadService } from 'src/app/core/services/file-download.service';
import { removeDownloadAnnotationList } from '../actions/current-selections.actions';

@Injectable()
export class AnnotationsListsEffects {
  getRecordingsAnnotationLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecordingsAnnotationListsRequestAction),
      withLatestFrom(this.store.select($userDownloadingAnnotationList)),
      switchMap(
        ([
          { recordingId, status, annListId, ids },
          userDownloadingAnnotationListIds,
        ]) =>
          this.apiService
            .call(() => this.apiClient.getAnnotationListId({ id: recordingId }))
            .pipe(
              switchMap(annotationLists => {
                if (status === RenderStatus.DONE && annListId && ids) {
                  const annList = annotationLists.find(l => l.id === annListId);
                  if (annList) {
                    const downloadInfo = annList?.downloads?.find(d =>
                      arraysEqual(d.ids, ids),
                    );
                    if (
                      downloadInfo &&
                      downloadInfo.url &&
                      userDownloadingAnnotationListIds.find(
                        d =>
                          d.id === annListId &&
                          arraysEqual(d.annotationsIds, ids),
                      )
                    ) {
                      this.fileDownloadService.downloadFile(downloadInfo.url);
                    }
                  }
                }

                return from([
                  getRecordingsAnnotationListsSuccessAction({
                    annotationLists,
                  }),
                  ...(annListId &&
                  ids &&
                  (status === RenderStatus.DONE ||
                    status === RenderStatus.FAILED)
                    ? [
                        removeDownloadAnnotationList({
                          annotationListId: annListId,
                          annotationsIds: ids,
                        }),
                      ]
                    : []),
                ]);
              }),
              catchError(error => {
                const parsedError = {
                  type: error.status.toString(),
                  message: error.error.msg,
                };

                return of(
                  getRecordingsAnnotationListsFailureAction({
                    errors: [parsedError],
                  }),
                  handleErrorResponseAction({
                    errorType: parsedError.type,
                  }),
                );
              }),
            ),
      ),
    ),
  );

  refreshRecordingAnnotationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshRecordingsAnnotationListRequestAction),
      switchMap(({ recordingId, annListId, status, ids }) =>
        this.store.pipe(
          select($currentRecording),
          take(1),
          switchMap(recording =>
            recording?.id === recordingId
              ? of(
                  getRecordingsAnnotationListsRequestAction({
                    recordingId,
                    annListId,
                    status,
                    ids,
                  }),
                )
              : EMPTY,
          ),
        ),
      ),
    ),
  );

  refreshAnnotationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshAnnotationListRequestAction),
      switchMap(({ annotationListId, status, ids }) =>
        this.store.pipe(
          select($shareableList),
          take(1),
          switchMap(annotationList =>
            annotationList.id === annotationListId
              ? of(
                  refreshRecordingsAnnotationListRequestAction({
                    recordingId: annotationList.recordingId,
                    annListId: annotationListId,
                    status,
                    ids,
                  }),
                )
              : EMPTY,
          ),
        ),
      ),
    ),
  );

  downloadAnnotationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadAnnotationsListRequestAction),
      switchMap(({ id, annotationsIds }) =>
        this.apiService
          .call(() =>
            this.apiClient.postAnnotationListDownloadId({
              id,
              body: { annotationsIds },
            }),
          )
          .pipe(
            switchMap(annotationsList =>
              of(
                downloadAnnotationsListSuccessAction({
                  annotationsList,
                  annotationsIds,
                }),
                showSnackbarAction({
                  infoMessage: 'success.annotationListDownload.start',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.DOWNLOAD_TAGS);
            }),
            catchError((err: HttpErrorResponse) =>
              of(
                downloadAnnotationsListFailureAction(),
                showSnackbarAction({
                  infoMessage: `error.playlistDownload.${err.status}`,
                  messageParameters: { ...err.error },
                }),
              ),
            ),
          ),
      ),
    ),
  );
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
    private readonly fileDownloadService: FileDownloadService,
  ) {}
}
