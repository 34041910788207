<cmv-video-page-layout
  [title]="'components.videoDetail.title' | translate"
  [subtitle]="'components.videoDetail.subtitle' | translate"
  [loadingResources]="loadingResources$ | async"
>
  <ng-container left-column>
    <cmv-video-player-wrapper
      class="video-player"
      [isMissingVideo]="(currentVideo$ | async) === null"
      [loading]="
        (currentVideoLoading$ | async) ||
        (currentAnnotation$ | async)?.loaded === false
      "
      [notInRange]="(playerOffsetIsValid$ | async) === false"
      [videoUrl]="currentVideoStream$ | async"
      [autoplay]="shouldAutoplay"
      (clipEnded)="shouldPlayNext($event)"
      [offset]="playerOffset$ | async"
      [currentVideo]="currentVideo$ | async"
      [videoSources]="recordingVideos$ | async"
      (changeSrc)="changeSrc($event, true)"
      #videoPlayer
    ></cmv-video-player-wrapper>
    <ng-container *ngIf="currentAnnotation$ | async as annotation">
      <cmv-video-control-panel
        [title]="annotation.name"
        [description]="annotation.description"
      ></cmv-video-control-panel>
    </ng-container>
    <div class="trimming-panel-wrapper" *ngIf="isTrimming$ | async">
      <cmv-trimming-panel
        [videoStartAt]="(currentVideo$ | async).startAt"
        [trimmingValues]="trimmingOffset$ | async"
        (submitChange)="trimAnnotation($event)"
        (seekTo)="seekWithHandleMove($event)"
        (valueRangeChange)="recalculateTag($event.from, $event.to)"
        (cancel)="cancelTrim()"
      ></cmv-trimming-panel>
    </div>
  </ng-container>

  <ng-container right-column>
    @let playlist = playlist$ | async;
    @if (playlist) {
      <div class="widget-wrapper">
        <cmv-playlist-title
          [navigation]="false"
          (playlistRemoved)="onPlaylistRemoved()"
          [playlist]="playlist"
        ></cmv-playlist-title>
      </div>
      <div class="created-tags">
        <div class="cmv-section-header">
          <div class="created-tags-header--buttons">
            <button
              mat-icon-button
              class="multiselect-icon"
              (click)="toggleMultiselect()"
              [matTooltip]="'tooltips.multiselect' | translate"
            >
              <mat-icon
                class="button-icon cmv-dim-gray-icon"
                [svgIcon]="multiselectIcon$ | async"
              ></mat-icon>
            </button>

            @if (showFilter$ | async) {
              <button
                mat-icon-button
                class="filter-icon"
                (click)="toggleFilter()"
                [matTooltip]="'tooltips.tagsFilter' | translate"
              >
                <mat-icon
                  class="button-icon cmv-dim-gray-icon"
                  [svgIcon]="filterIcon$ | async"
                ></mat-icon>
              </button>
            }
          </div>
          <div class="cmv-h4">
            {{
              ((isMultiselectEnabled$ | async)
                ? 'components.selectedTags'
                : 'components.createdTags'
              ) | translate
            }}
          </div>
          <div class="create-tags-more-wrapper">
            <cmv-created-tags-more
              *ngIf="isMultiselectEnabled$ | async"
              showSharing="false"
              showDownload="false"
              [playlist]="playlist"
            ></cmv-created-tags-more>
          </div>
        </div>
        <cmv-created-tags
          [hideDownloadTag]="true"
          [annotations]="annotations$ | async"
          [currentAnnotation]="currentAnnotation$ | async"
          (deleteTag)="deleteTag(playlist, $event)"
          (trimVideo)="redirectToTrimming($event.id)"
          (editAnnotation)="editAnnotation($event)"
          (selectedWidgetIdChange)="selectTag($event.id)"
          [fromPlaylistId]="playlist.id"
          [showRecordingName]="true"
        ></cmv-created-tags>
      </div>
    }
  </ng-container>
</cmv-video-page-layout>
