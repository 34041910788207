import { createAction, props } from '@ngrx/store';
import { RecordingAddSharing, Sharing, SharingRecording } from 'src/api/models';
import { ErrorModel } from '../../shared/models/app.state';

export const getRecordingSharingsRequestAction = createAction(
  'GetRecordingSharingsRequestAction',
  props<{ id: string }>(),
);

export const getRecordingSharingsSuccessAction = createAction(
  'GetRecordingSharingsSuccessAction',
  props<{ sharings: SharingRecording }>(),
);

export const getRecordingSharingsFailureAction = createAction(
  'GetRecordingSharingsFailureAction',
  props<{ errors: ErrorModel[] }>(),
);

export const postSharingRecordingRequestAction = createAction(
  'PostSharingRecordingRequestAction',
  props<{ id: string; add: RecordingAddSharing }>(),
);

export const postSharingRecordingSuccessAction = createAction(
  'PostSharingRecordingSuccessAction',
  props<{ sharings: SharingRecording }>(),
);

export const postSharingRecordingFailureAction = createAction(
  'PostSharingRecordingFailureAction',
  props<{ error: ErrorModel }>(),
);

export const removeSharingRecordingRequestAction = createAction(
  'RemoveSharingRecordingRequestAction',
  props<{ id: string; del: Sharing }>(),
);

export const removeSharingRecordingSuccessAction = createAction(
  'RemoveSharingRecordingSuccessAction',
  props<{ sharings: SharingRecording }>(),
);

export const removeSharingRecordingFailureAction = createAction(
  'RemoveSharingRecordingFailureAction',
  props<{ error: ErrorModel }>(),
);
