import { AppState } from '../../shared/models/app.state';
import { createSelector } from '@ngrx/store';
import { Annotation, AnnotationTeam } from '../../../api/models';

export const $annotation = (id: string) => (state: AppState) =>
  state.annotations.entities[id] || null;

export const $annotationName = (id: string) =>
  createSelector($annotation(id), (state: Annotation) =>
    state != null ? state.name : '',
  );

export const $annotationIsLoading = (state: AppState): boolean =>
  state.annotations.loading;

export const $annotations = (state: AppState) => state.annotations;

export const $annotationsLoaded = createSelector(
  $annotations,
  annotationsState => annotationsState.loaded,
);

export const $annotationEntities = createSelector(
  $annotations,
  annotationsState => annotationsState.entities,
);

export const $annotationsList = (recordingId: string) =>
  createSelector($annotationEntities, entities =>
    Object.values(entities)
      .filter(
        (annotation: Annotation) => annotation.recordingId === recordingId,
      )
      .sort((a, b) => a.timeStamp - b.timeStamp),
  );

export const $annotationsRecId = (id: string) =>
  createSelector($annotation(id), (annotation: Annotation) =>
    annotation != null ? annotation.recordingId : '',
  );

export const $annotationsCountByType = (
  type: string,
  team: AnnotationTeam,
  recordingId: string,
) =>
  createSelector(
    $annotationsList(recordingId),
    (annotations: Annotation[]) =>
      annotations.filter(
        annotation =>
          annotation.annotationTypeName === type && annotation.team === team,
      ).length,
  );
