import { AnnotationTypesState, AppState } from '../../shared/models/app.state';
import { createSelector } from '@ngrx/store';
import { AnnotationTypesPagingModel } from '../../shared/models/annotation-types-paging.model';
import {
  TAGS_PER_PAGE,
  TAGS_PER_PAGE_VIDEO_TAGGING,
} from '../../app.constants';
import { AnnotationType } from '../../../api/models';
import { $currentTaggingTeam } from './current-selections.selectors';
import { TaggingTeam } from '../../shared/models/current-selections.model';

export const $annotationTypes = (state: AppState) => state.annotationTypes;

export const $annotationTypesEntities = createSelector(
  $annotationTypes,
  (state: AnnotationTypesState) =>
    state.entity != null
      ? [...state.entity].sort((a, b) => a.order - b.order)
      : [],
);

export const $annotationTypesLoaded = createSelector(
  $annotationTypes,
  (state: AnnotationTypesState) => state.loaded,
);

export const $annotationTypesLoading = createSelector(
  $annotationTypes,
  (state: AnnotationTypesState) => !state.loaded && state.loading,
);

export const $annotationTypeById = (id: string) =>
  createSelector(
    $annotationTypesEntities,
    (entities: AnnotationType[] | null) => {
      if (entities == null) {
        return null;
      }
      const foundEntity = entities.find(entity => entity.id === id);
      return foundEntity ? foundEntity : null;
    },
  );

/* METADATA */

export const $annotationTypesMetadata = createSelector(
  $annotationTypes,
  (state: AnnotationTypesState) => state.metadata,
);

export const $tagsPerPage = createSelector(
  $currentTaggingTeam,
  (taggingTeam: TaggingTeam) =>
    taggingTeam === TaggingTeam.BOTH ? TAGS_PER_PAGE : TAGS_PER_PAGE * 2,
);

export const $videoTagsPerPage = createSelector(
  $currentTaggingTeam,
  (taggingTeam: TaggingTeam) =>
    taggingTeam === TaggingTeam.BOTH
      ? TAGS_PER_PAGE_VIDEO_TAGGING
      : TAGS_PER_PAGE_VIDEO_TAGGING * 2,
);

export const $totalTaggingPages = createSelector(
  $annotationTypesEntities,
  $tagsPerPage,
  (entities, tagsPerPage) => Math.ceil(entities.length / tagsPerPage),
);

export const $totalVideoTaggingPages = createSelector(
  $annotationTypesEntities,
  $videoTagsPerPage,
  (entities, tagsPerPage) => Math.ceil(entities.length / tagsPerPage),
);

export const $aTPagesCountAsArray = createSelector(
  $totalTaggingPages,
  totalTaggingPages => Array(totalTaggingPages).fill(0),
);
export const $aTPagesVideoTaggingCountAsArray = createSelector(
  $totalVideoTaggingPages,
  totalVideoTaggingPages => Array(totalVideoTaggingPages).fill(0),
);

export const $annotationTypesPaged = createSelector(
  $annotationTypesEntities,
  $tagsPerPage,
  (entities: AnnotationType[] | null, tagsPerPage: number) => {
    if (entities == null) {
      return [];
    }

    const helpArray = Array(Math.ceil(entities.length / tagsPerPage)).fill(0);

    return helpArray.map((_, i) =>
      entities.slice(i * tagsPerPage, i * tagsPerPage + tagsPerPage),
    );
  },
);

export const $annotationTypesVideoTaggingPaged = createSelector(
  $annotationTypesEntities,
  $videoTagsPerPage,
  (entities: AnnotationType[] | null, tagsPerPage: number) => {
    if (entities == null) {
      return [];
    }
    const helpArray = Array(Math.ceil(entities.length / tagsPerPage)).fill(0);

    return helpArray.map((_, i) =>
      entities.slice(i * tagsPerPage, i * tagsPerPage + tagsPerPage),
    );
  },
);

export const $currentTaggingPage = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPage,
);

export const $currentTaggingPageLeft = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPageLeft,
);

export const $currentTaggingPageRight = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPageRight,
);

export const $nextPageDisabled = createSelector(
  $annotationTypesMetadata,
  $totalTaggingPages,
  (paging: AnnotationTypesPagingModel, totalTaggingPages) =>
    paging.currentPage === totalTaggingPages - 1,
);

export const $prevPageDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPage === 0,
);

export const $nextPageLeftDisabled = createSelector(
  $annotationTypesMetadata,
  $totalVideoTaggingPages,
  (paging: AnnotationTypesPagingModel, totalVideoTaggingPages: number) =>
    paging.currentPageLeft === totalVideoTaggingPages - 1,
);

export const $prevPageLeftDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPageLeft === 0,
);

export const $nextPageRightDisabled = createSelector(
  $annotationTypesMetadata,
  $totalVideoTaggingPages,
  (paging: AnnotationTypesPagingModel, totalVideoTaggingPages: number) =>
    paging.currentPageRight === totalVideoTaggingPages - 1,
);

export const $prevPageRightDisabled = createSelector(
  $annotationTypesMetadata,
  (paging: AnnotationTypesPagingModel) => paging.currentPageRight === 0,
);
