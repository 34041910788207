@if (recordingsDays) {
  <div class="recorder--matches">
    @for (day of sortRecordings(recordingsDays); track day) {
      <cmv-match-day
        class="match-day"
        [matchDay]="day"
        [isInUserTrash]="true"
        [isNavigationEnabled]="false"
      ></cmv-match-day>
    }
  </div>
}
