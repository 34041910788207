<div class="header-wrapper">
  <cmv-header
    [title]="'components.moreView.changelog.tabTitle' | translate"
  ></cmv-header>
</div>

<div class="more-content-html-elements-override">
  <div *ngFor="let version of versions" class="version-info--wrapper">
    <cmv-version-info
      [version]="version.version"
      (loadInfo)="onLoad($event)"
    ></cmv-version-info>
  </div>
  <cmv-loading-spinner *ngIf="!loaded" [overlay]="true"></cmv-loading-spinner>
</div>
