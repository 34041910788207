import {
  downloadAnnotationsListSuccessAction,
  getRecordingsAnnotationListsFailureAction,
  getRecordingsAnnotationListsRequestAction,
  getRecordingsAnnotationListsSuccessAction,
} from '../actions/annotations-lists.actions';
import { AnnotationListsState } from '../../shared/models/app.state';
import { Action, createReducer, on } from '@ngrx/store';

const initialState: AnnotationListsState = {
  entities: {},
  errors: [],
  loading: false,
  loaded: false,
};

const reducer = createReducer(
  initialState,
  on(getRecordingsAnnotationListsRequestAction, state => ({
    ...state,
    loading: true,
  })),
  on(
    getRecordingsAnnotationListsSuccessAction,
    (state, { annotationLists }) => ({
      ...state,
      entities: (annotationLists || []).reduce(
        (acc, annotationList) => ({
          ...acc,
          [annotationList.id]: annotationList,
        }),
        {},
      ),
      loading: false,
      loaded: true,
    }),
  ),
  on(getRecordingsAnnotationListsFailureAction, (state, { errors }) => ({
    ...state,
    errors,
    loading: false,
  })),
  on(downloadAnnotationsListSuccessAction, (state, { annotationsList }) => ({
    ...state,
    entities: {
      ...state.entities,
      [annotationsList.id]: annotationsList,
    },
  })),
);

export function annotationListReducer(
  state: AnnotationListsState,
  action: Action,
): AnnotationListsState {
  return reducer(state, action);
}
