<cmv-page-layout
  [title]="'basic.playlists' | translate"
  [addButtonEnabled]="true"
  (addButtonClicked)="createNewPlaylist()"
  [addButtonTooltip]="'tooltips.createPlaylist' | translate"
  [loadingResources]="loadingResources$ | async"
>
  @let playlists = playlists$ | async;
  <div class="playlist-wrapper">
    <div class="cmv-section-header">
      <div class="cmv-h4">
        {{ playlists.length }}
        {{
          playlists.length === 1
            ? ('basic.playlist' | translate)
            : ('basic.playlists' | translate)
        }}
      </div>
    </div>
    <div class="playlists">
      @if (!playlists.length) {
        <div class="empty-playlists">
          <div class="empty-playlist-image">
            <img src="assets/images/empty_state.svg" />
          </div>
          <div class="empty-playlist-title cmv-h1">
            {{ 'components.playlistCollection.emptyPlaylistTitle' | translate }}
          </div>
          <div class="empty-playlist-subtitle cmv-body">
            {{
              'components.playlistCollection.emptyPlaylistSubtitle' | translate
            }}
          </div>
          <div
            class="cmv-dashed-button empty-playlist-button"
            (click)="createNewPlaylist()"
          >
            <div class="cmv-h4 text">
              {{ 'basic.createNewPlaylist' | translate }}
            </div>
            <mat-icon
              class="button-icon cmv-dim-gray"
              svgIcon="cmv-add"
            ></mat-icon>
          </div>
        </div>
      }

      @for (playlist of playlists; track playlist.id) {
        <div class="playlist">
          <cmv-playlist-title [playlist]="playlist"></cmv-playlist-title>
        </div>
      }
    </div>
  </div>
</cmv-page-layout>
