import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { APIClient } from '../../../api';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  getRecorderCameraFailureAction,
  getRecorderCameraRequestAction,
  getRecorderCameraSuccessAction,
} from '../actions/camera.actions';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable()
export class CameraEffects {
  getRecorderCamera = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecorderCameraRequestAction),
      switchMap(({ id }) =>
        this.apiService.call(() => this.apiClient.getFeRecorderId({ id })),
      ),
      map(recorder => getRecorderCameraSuccessAction({ recorder })),
      catchError(error => {
        const parsedError = {
          type: error.status.toString(),
          message: error.error.msg,
        };

        return of(
          getRecorderCameraFailureAction({
            errors: [parsedError],
          }),
          handleErrorResponseAction({
            errorType: parsedError.type,
          }),
        );
      }),
    ),
  );

  constructor(
    private readonly apiClient: APIClient,
    private readonly actions$: Actions,
    private readonly apiService: ApiService,
  ) {}
}
