import { ClubsState, StateStatus } from '../../shared/models/app.state';
import { clubsAdapter } from '../reducers/clubs.reducer';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const { selectAll: selectClubs, selectEntities: selectClubEntities } =
  clubsAdapter.getSelectors();

export const $clubsState = createFeatureSelector<ClubsState>('clubs');

export const $clubs = createSelector($clubsState, selectClubs);

export const $clubEntities = createSelector($clubsState, selectClubEntities);

export const $clubName = (id: string) =>
  createSelector($clubEntities, ({ [id]: club }) => (club ? club.name : ''));

export const $clubLogo = (id: string) =>
  createSelector($clubEntities, ({ [id]: club }) => (club ? club.logo : ''));

export const $loadingStarted = createSelector(
  $clubsState,
  clubs => clubs.status === StateStatus.START,
);

export const $clubsLoaded = createSelector($clubs, clubs => clubs.length > 0);
