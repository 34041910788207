import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LoggerService } from '../services/logger.service';

@Injectable()
export class HttpLoggingInterceptor implements HttpInterceptor {
  constructor(private loggerService: LoggerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        error: error => {
          if (error instanceof HttpErrorResponse) {
            this.loggerService.logError({
              url: req.url,
              method: req.method,
              status: error.status,
              statusText: error.statusText,
              message: error.message,
              response: error.error,
            });
          }
        },
      }),
    );
  }
}
