<div tabindex="0" #elementToFocus class="cmv-modal-outer-card">
  <div class="cmv-modal-inner-card app-news-modal-wrapper">
    <div class="cmv-h1 title">
      {{ 'components.modals.appNews.title' | translate: { version: '1.31.1' } }}
    </div>
    <div class="main-info">
      <div *ngFor="let version of versions" class="version-info--wrapper">
        <cmv-version-info
          [version]="version.version"
          (loadInfo)="onLoad($event)"
        ></cmv-version-info>
      </div>
    </div>
    <div class="buttons">
      <div class="cmv-accept-button" (click)="closeModal(true)">
        <div class="cmv-h4">
          {{ 'basic.ok' | translate }}
        </div>
      </div>
    </div>
    @if (!loaded) {
      <cmv-loading-spinner
        [overlay]="true"
        extraClass="app-news-modal-loading-spinner"
      ></cmv-loading-spinner>
    }
  </div>
</div>
