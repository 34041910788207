import { Routes } from '@angular/router';
import { RoutePath } from '../app.constants';
import { FilterResultsComponent } from '../pages/filter-results/filter-results.component';
import { LiveMatchViewComponent } from './live-match-view/live-match-view.component';
import { MatchListViewComponent } from './match-list-view/match-list-view.component';
import { VideoTaggingViewComponent } from './video-tagging-view/video-tagging-view.component';
import { PlaylistsCollectionViewComponent } from './playlists-collection-view/playlists-collection-view.component';
import { CreateRecordingComponent } from '../pages/create-recording/create-recording.component';
import { EditRecordingComponent } from '../pages/edit-recording/edit-recording.component';
import { TagPanelViewComponent } from './tag-panel-view/tag-panel-view.component';
import { VideoDetailPlaylistComponent } from './video-detail-playlist/video-detail-playlist.component';
import { TermsOfUseViewComponent } from './more/terms-of-use-view/terms-of-use-view.component';
import { HowItWorksViewComponent } from './more/how-it-works-view/how-it-works-view.component';
import { PrivacyAndCookiePolicyViewComponent } from './more/privacy-and-cookie-policy-view/privacy-and-cookie-policy-view.component';
import { TrashComponent } from './more/trash/trash.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { ChangelogViewComponent } from './more/changelog-view/changelog-view.component';

export const platformRoutes: Routes = [
  { path: '', redirectTo: RoutePath.Recordings, pathMatch: 'full' },
  {
    path: RoutePath.Recordings,
    children: [
      { path: '', component: MatchListViewComponent },
      { path: `${RoutePath.LiveMatch}`, component: LiveMatchViewComponent },
      {
        path: `${RoutePath.PlayedMatch}`,
        component: VideoTaggingViewComponent,
      },
      { path: `${RoutePath.Create}`, component: CreateRecordingComponent },
      { path: `${RoutePath.Edit}`, component: EditRecordingComponent },
    ],
  },
  {
    path: RoutePath.Playlists,
    children: [
      { path: '', component: PlaylistsCollectionViewComponent },
      { path: `${RoutePath.Clip}`, component: VideoDetailPlaylistComponent },
    ],
  },
  { path: RoutePath.Filter, component: FilterResultsComponent },
  {
    path: `${RoutePath.Trash}`,
    component: TrashComponent,
    canActivate: [AuthGuard],
  },
  {
    path: RoutePath.More,
    children: [
      { path: `${RoutePath.HowItWorks}`, component: HowItWorksViewComponent },
      { path: `${RoutePath.TermsOfUse}`, component: TermsOfUseViewComponent },
      {
        path: `${RoutePath.PrivacyAndCookiePolicy}`,
        component: PrivacyAndCookiePolicyViewComponent,
      },
      {
        path: `${RoutePath.Changelog}`,
        component: ChangelogViewComponent,
      },
    ],
  },
  { path: RoutePath.TagPanel, component: TagPanelViewComponent },
  { path: '**', redirectTo: RoutePath.Error404, pathMatch: 'full' },
];
