import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RoutePath } from '../../app.constants';
import { ErrorTypes } from '../../shared/components-old/error/error.component';
import { handleErrorResponseAction } from '../actions/error.actions';
import { showErrorOverlay } from '../actions/ui-flags.actions';

@Injectable()
export class ErrorEffects {
  handleResponseErrors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(handleErrorResponseAction),
      // TODO: add navigate action after navigate is done by ngrx
      switchMap(({ errorType }) => {
        if (errorType === ErrorTypes.NOT_FOUND) {
          this.dialog.closeAll();
          this.router.navigate([RoutePath.Error404]);
          return EMPTY;
        }
        return of(showErrorOverlay());
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly dialog: MatDialog,
    private readonly router: Router,
  ) {}
}
