import {
  Component,
  ChangeDetectionStrategy,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppState } from 'src/app/shared/models/app.state';
import { Store, select } from '@ngrx/store';
import { $authFailure } from 'src/app/store/selectors/auth.selectors';
import { MatIcon } from '@angular/material/icon';
import { NgFor, AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-login-failure-modal',
  templateUrl: './login-failure-modal.component.html',
  styleUrls: ['./login-failure-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, NgFor, AsyncPipe, TranslateModule],
})
export class LoginFailureModalComponent implements AfterViewInit {
  @ViewChild('elementToFocus')
  elementToFocus: ElementRef;

  authErrors$ = this.store.pipe(select($authFailure));

  constructor(
    private readonly dialogRef: MatDialogRef<LoginFailureModalComponent>,
    private readonly store: Store<AppState>,
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.elementToFocus.nativeElement.focus();
    }, 0);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
