import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { APIClient } from '../../../api';
import { FirstTimeLoginModalComponent } from '../../shared/components-old/modal/first-time-login-modal/first-time-login-modal.component';
import { AppState } from '../../shared/models/app.state';
import { logoutAction } from '../actions/auth.actions';
import {
  getUserFailureAction,
  getUserRequestAction,
  getUserSuccessAction,
  openTOSModalAction,
  postUserConfirmAppNewsFailureAction,
  postUserConfirmAppNewsRequestAction,
  postUserConfirmAppNewsSuccessAction,
  postUserTOSFailureAction,
  postUserTOSRequestAction,
  postUserTOSSuccessAction,
} from '../actions/user.actions';
import { $customerCurrentTos } from '../selectors/customer.selectors';
import { AppNewsModalComponent } from '../../shared/components-old/modal/app-news-modal/app-news-modal.component';
import { CHANGELOG_VERSIONS } from 'src/app/features/changelog/changelog.contants';

@Injectable()
export class UserEffects {
  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserRequestAction),
      switchMap(() =>
        this.apiService
          .call(() => this.apiClient.getUser())
          .pipe(
            map(user => getUserSuccessAction({ user })),
            catchError(error =>
              of(
                getUserFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
              ),
            ),
          ),
      ),
    ),
  );

  verifyTOS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getUserSuccessAction),
      take(1),
      withLatestFrom(this.store.pipe(select($customerCurrentTos))),
      switchMap(
        ([
          {
            user: { tosHistory },
          },
          currentTOS,
        ]) => {
          const currentAccepted = tosHistory
            ? tosHistory.find(accepted => accepted.version === currentTOS)
            : undefined;

          return !currentAccepted
            ? of(openTOSModalAction({ version: currentTOS }))
            : EMPTY;
        },
      ),
    ),
  );

  confirmAppNews$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postUserConfirmAppNewsRequestAction),
      switchMap(({ version }) =>
        this.apiService
          .call(() =>
            this.apiClient.postUserConfirmAppNews({ body: { version } }),
          )
          .pipe(
            map(user => postUserConfirmAppNewsSuccessAction({ user })),
            catchError(error =>
              of(
                postUserConfirmAppNewsFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
              ),
            ),
          ),
      ),
    ),
  );

  verifyAppNews$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(getUserSuccessAction),
        tap(({ user: { appNewsVersion } }) => {
          let index = CHANGELOG_VERSIONS.findIndex(v => v === appNewsVersion);
          let newVersions: string[] = [];
          for (let i = CHANGELOG_VERSIONS.length - 1; i >= index + 1; i--) {
            newVersions.push(CHANGELOG_VERSIONS[i]);
          }

          if (newVersions.length > 0) {
            this.dialog
              .open(AppNewsModalComponent, {
                data: { versions: newVersions },
                disableClose: true,
                panelClass: [
                  'app-news-modal-overlay-panel',
                  'app-modal-overlay-panel',
                ],
              })
              .afterClosed()
              .subscribe(confirmed => {
                if (confirmed) {
                  this.store.dispatch(
                    postUserConfirmAppNewsRequestAction({
                      version: newVersions[0],
                    }),
                  );
                }
              });
          }
        }),
      ),
    { dispatch: false },
  );

  openTOSModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openTOSModalAction),
      switchMap(({ version }) =>
        this.dialog
          .open(FirstTimeLoginModalComponent)
          .afterClosed()
          .pipe(
            map(userResponse =>
              userResponse
                ? postUserTOSRequestAction({ version })
                : logoutAction(),
            ),
          ),
      ),
    ),
  );

  postUserTOS$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postUserTOSRequestAction),
      switchMap(({ version }) =>
        this.apiService
          .call(() => this.apiClient.postUserAcceptTOS({ body: { version } }))
          .pipe(
            map(user => postUserTOSSuccessAction({ user })),
            catchError(error =>
              of(
                postUserTOSFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private readonly apiService: ApiService,
  ) {}
}
