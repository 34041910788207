export const environment = {
  production: true,
  wsUrl: 'wss://ws2.panoris.com/test?token=',
  apiUrl: `https://api2.panoris.com/test`,
  cognito: {
    clientId: '7k37jmgih6ak0oucq5rg3rbghs',
    appWebDomain: 'bat-devel.auth.eu-central-1.amazoncognito.com',
    userPoolId: 'eu-central-1_BU60kvUuD',
  },
  ga: {
    enable: false,
    id: null,
  },
  logger: {
    enabled: true,
    region: 'eu-central-1',
    logGroupName: 'AppFrontendTest',
    identityPoolId: 'eu-central-1:0dce8c4b-b26c-45a1-99d8-c414ea148053',
  },
};
