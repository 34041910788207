import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Recorder, Recording, RecordingStatus, Video } from 'src/api/models';
import { AsyncPipe, NgIf } from '@angular/common';
import { DownloadVideoMenuComponent } from '../download-video-menu/download-video-menu.component';
import { RecordingInfoComponent } from '../recording-info/recording-info.component';
import { LoadingBarComponent } from '../../components/loading-bar/loading-bar.component';
import { QueryParamNames, RoutePath } from 'src/app/app.constants';
import { ShareRecordingModalComponent } from '../modal/share-recording-modal/share-recording-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { take } from 'rxjs';
import { deleteRecordingRequestAction } from '../../../store/actions/recording.actions';
import { moveToTrashRequestAction } from '../../../store/actions/trash.actions';
import { AppState } from '../../models/app.state';
import { Store, select } from '@ngrx/store';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { $recorders } from '../../../store/selectors/recorders.selectors';
import { isRecordingEditable } from 'src/app/app.utils';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslatePipe } from '@ngx-translate/core';
import moment from 'moment';

@Component({
  selector: 'cmv-video-control-panel',
  templateUrl: './video-control-panel.component.html',
  styleUrls: ['./video-control-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    DownloadVideoMenuComponent,
    RecordingInfoComponent,
    LoadingBarComponent,
    MatIconButton,
    MatIcon,
    AsyncPipe,
    MatTooltip,
    TranslatePipe,
  ],
})
export class VideoControlPanelComponent {
  @Input() match: Recording;
  @Input() title: string;
  @Input() description: string;
  @Input() infoEnabled: boolean = false;

  @Input() isLoadingVisible: boolean = false;
  @Input() downloadEnabled: boolean = false;
  @Input() downloadVideos: Video[];
  @Input() liveVideos: Video[];

  recorders$ = this.store.pipe(select($recorders));

  constructor(
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}

  isEditButtonVisible(recorders: Recorder[]): boolean {
    return (
      this.match.manual &&
      this.match.status !== RecordingStatus.PLAYED &&
      isRecordingEditable(this.match, recorders)
    );
  }

  editRecording(): void {
    this.router.navigate(
      [RoutePath.Platform, RoutePath.Recordings, RoutePath.Edit],
      { queryParams: { [QueryParamNames.RecordingId]: this.match.id } },
    );
  }

  isShareButtonVisible(recorders: Recorder[]): boolean {
    return isRecordingEditable(this.match, recorders);
  }

  openShareModal(): void {
    this.dialog.open(ShareRecordingModalComponent, {
      data: {
        id: this.match.id,
      },
    });
  }

  isDeleteButtonVisible(recorders: Recorder[]): boolean {
    return (
      (this.match.status === RecordingStatus.PLAYED ||
        (this.match.manual &&
          this.match.status === RecordingStatus.UPCOMING)) &&
      isRecordingEditable(this.match, recorders)
    );
  }

  deleteRecording(): void {
    const { status, id, name } = this.match;
    const dialogInstance = this.dialog.open(RemoveModalComponent, {
      data: {
        title: name,
        type: INPUT_TYPE.RECORDING,
      },
    });

    if (dialogInstance != null) {
      dialogInstance
        .afterClosed()
        .pipe(take(1))
        .subscribe(value => {
          if (value) {
            if (status === RecordingStatus.UPCOMING) {
              this.store.dispatch(deleteRecordingRequestAction({ id }));
            } else if (status == RecordingStatus.PLAYED) {
              this.store.dispatch(moveToTrashRequestAction({ id }));
            } else {
              throw new Error("Can't delete live recording");
            }
          }
        });
    }
  }

  get matchDate(): string {
    const SHORT_DATE_LENGTH = 3;
    const matchTime = moment.unix(this.match.dateTime);

    return matchTime.format('D MMM').endsWith('.')
      ? matchTime.format('D MMM YYYY')
      : matchTime.format('MMMM').length <= SHORT_DATE_LENGTH
        ? matchTime.format('D MMM').concat('  ', matchTime.format('YYYY'))
        : matchTime.format('D MMM').concat('. ', matchTime.format('YYYY'));
  }

  get matchTime(): string | void {
    return moment.unix(this.match.dateTime).format('HH:mm');
  }
}
