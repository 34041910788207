import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { APIClient } from '../../../api';
import {
  getAnnotationsRecordingFailureAction,
  getAnnotationsRecordingRequestAction,
  getAnnotationsRecordingSuccessAction,
} from '../actions/annotation.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  getPlaylistsRecordingsFailureAction,
  getPlaylistsRecordingsRequestAction,
  getPlaylistsRecordingsSuccessAction,
} from '../actions/video-detail.actions';
import { getRecordingsAnnotationListsRequestAction } from '../actions/annotations-lists.actions';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable()
export class VideoDetailEffects {
  getRecordingsAnnotation = createEffect(() =>
    this.actions$.pipe(
      ofType(getAnnotationsRecordingRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.getRecordingsAnnotationId({ id }))
          .pipe(
            switchMap(recordingsAnnotation =>
              of(
                getAnnotationsRecordingSuccessAction(recordingsAnnotation),
                getRecordingsAnnotationListsRequestAction({
                  recordingId: recordingsAnnotation.recording.id,
                }),
              ),
            ),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getAnnotationsRecordingFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getPlaylistsAnnotation = createEffect(() =>
    this.actions$.pipe(
      ofType(getPlaylistsRecordingsRequestAction),
      switchMap(({ playlistId }) =>
        this.apiService
          .call(() =>
            this.apiClient.getPlaylistsRecordingsId({ id: playlistId }),
          )
          .pipe(
            map(playlistsAnnotation =>
              getPlaylistsRecordingsSuccessAction({
                response: playlistsAnnotation,
              }),
            ),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };

              return of(
                getPlaylistsRecordingsFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
  ) {}
}
