import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Recording } from '../../../../api/models';
import { AppState } from '../../models/app.state';
import { getMoreRecordingsRequestAction } from '../../../store/actions/recording-list.actions';
import {
  $moreMatchesToLoad,
  $recordingsUpdating,
} from '../../../store/selectors/recording-list.selectors';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatchDayComponent } from '../match-day/match-day.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-match-list',
  templateUrl: './match-list.component.html',
  styleUrls: ['./match-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgFor,
    MatchDayComponent,
    NgIf,
    MatProgressSpinner,
    AsyncPipe,
    TranslateModule,
  ],
})
export class MatchListComponent implements OnDestroy {
  @ViewChild('wrapper') wrapper: ElementRef;

  @Input() index: number;
  @Input() matchDays: Recording[] = [];
  @Input() showUpgrade = false;
  @Input() hideScore = true;
  @Input() showMoreButton = false;

  @Output() destroy = new EventEmitter<{
    index: number;
    scrollPosition: number;
  }>();

  moreMatchesToLoad$ = this.store.pipe(select($moreMatchesToLoad));
  updating$ = this.store.pipe(select($recordingsUpdating));

  constructor(private readonly store: Store<AppState>) {
    window.addEventListener('beforeunload', this.ngOnDestroy.bind(this));
  }

  setScrollPosition(value: number, smooth = false) {
    const el: HTMLDivElement = this.wrapper.nativeElement;

    el.scrollTo({
      top: value * el.scrollHeight,
      behavior: smooth ? 'smooth' : 'auto',
    });
  }

  get scrollPosition() {
    const el: HTMLDivElement = this.wrapper.nativeElement;
    return el.scrollTop / el.scrollHeight;
  }

  loadMore(): void {
    this.store.dispatch(getMoreRecordingsRequestAction());
  }

  nameTrackFn = (_: number, item: Recording) => item.id;

  ngOnDestroy(): void {
    this.destroy.emit({
      index: this.index,
      scrollPosition: this.scrollPosition,
    });
  }
}
