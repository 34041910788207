import { createSelector } from '@ngrx/store';
import { AppState } from '../../shared/models/app.state';

export const $authState = ({ auth }: AppState) => auth;
export const $authLoading = createSelector(
  $authState,
  ({ loading }) => loading,
);
export const $authIdToken = createSelector($authState, ({ entity }) =>
  entity ? entity.idToken : '',
);
export const $authSessionValid = createSelector(
  $authState,
  ({ entity }) => entity != null,
);
export const $authFailure = createSelector($authState, ({ errors }) => errors);
export const $premiumUser = createSelector(
  $authState,
  ({ entity }) => entity && entity.premium,
);
