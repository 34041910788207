import { ErrorHandler, Injectable } from '@angular/core';
import { LoggerService } from './core/services/logger.service';

@Injectable()
export class LoggerErrorHandler implements ErrorHandler {
  constructor(private readonly loggerService: LoggerService) {}

  async handleError(error: any) {
    console.error(error);
    this.loggerService.logError(error);
  }
}
