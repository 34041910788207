import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../shared/models/app.state';
import {
  installPwaAction,
  setClosedPWAButtonAction,
} from '../../../../store/actions/ui-flags.actions';
import { $showPWAButton } from '../../../../store/selectors/ui-flags.selectors';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-pwa-install-button',
  templateUrl: './pwa-install-button.component.html',
  styleUrls: ['./pwa-install-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, TranslateModule],
})
export class PwaInstallButtonComponent {
  readonly showPWAButton$ = this.store.pipe(select($showPWAButton));

  constructor(private readonly store: Store<AppState>) {}

  installPwa(): void {
    this.store.dispatch(installPwaAction());
  }

  hide(): void {
    this.store.dispatch(setClosedPWAButtonAction({ value: true }));
  }
}
