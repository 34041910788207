import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutePath } from '../../app.constants';
import { RouterLink } from '@angular/router';
import { PrivacyCookiePolicyComponent } from '../../features/privacy-cookie-policy/privacy-cookie-policy.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, PrivacyCookiePolicyComponent, TranslateModule],
})
export class PrivacyComponent {
  constructor() {
    window.scrollTo(0, 0);
  }

  scrollTo(id: string): void {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView();
    }
  }

  get termsRouterLink() {
    return ['/', RoutePath.Terms];
  }

  get privacyRouterLink() {
    return ['/', RoutePath.Privacy];
  }
}
