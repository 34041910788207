import {
  loginAction,
  loginFailureAction,
  loginSuccessAction,
  logoutOfPlatformAction,
} from '../actions/auth.actions';
import { AuthState } from '../../shared/models/app.state';
import { Action, createReducer, on } from '@ngrx/store';

const initialState: AuthState = {
  errors: [],
  loading: false,
  entity: null,
};

const reducer = createReducer(
  initialState,
  on(loginAction, state => ({ ...state, loading: true })),
  on(loginSuccessAction, (state, { auth }) => ({
    ...state,
    entity: {
      ...state.entity,
      idToken: auth.idToken,
      refToken: auth.refToken,
      userHasRights: auth.userHasRights,
      premium: auth.premium,
    },
    loading: false,
    loaded: true,
    errors: [],
  })),
  on(logoutOfPlatformAction, state => ({
    ...state,
    entity: null,
    loading: false,
    errors: [],
  })),
  on(loginFailureAction, (state, { errors }) => ({
    ...state,
    loading: false,
    errors,
  })),
);

export function authReducer(state: AuthState, action: Action): AuthState {
  return reducer(state, action);
}
