import { createAction, props } from '@ngrx/store';
import { SnackbarStateModel } from '../../shared/models/snackbar-state.model';
import { SnackbarInputModel } from '../../shared/models/snackbar-input.model';

export const showSnackbarAction = createAction(
  'ShowSnackbar',
  props<SnackbarInputModel>(),
);
export const scheduleSnackbarAction = createAction(
  'ScheduleSnackbar',
  props<{ snackbarState: SnackbarStateModel }>(),
);
export const hideSnackbarAction = createAction('HideSnackbar');
