import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { fromEvent, interval } from 'rxjs';
import { filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import {
  QueryParamNames,
  WEBSOCKET_RECONNECT_DELAY,
  WSEntities,
  WSMessages,
  WSStatus,
  WS_STATUS_RESTORED,
} from '../../app.constants';
import { getNextPingPongTime } from '../../app.utils';
import { AppState } from '../../shared/models/app.state';
import {
  WsAction,
  WsMessageModel,
  WsSubscribeAs,
} from '../../shared/models/ws.models';
import {
  pollAnnotationsRequestAction,
  refreshAnnotationThumbnailAction,
  showChangeAnnotationNotificationAction,
} from '../actions/annotation.actions';
import { loginSuccessAction } from '../actions/auth.actions';
import {
  refreshPlaylistRequestAction,
  deletedPlaylistRequestAction,
  createdPlaylistRequestAction,
  refreshPlaylistsByRecordingIdAction,
  refreshPlaylistsByRecordingsIdsAction,
  refreshPlaylistAnnotationThumbnailAction,
} from '../actions/playlist-list.actions';
import { storeRouteAction } from '../actions/route.actions';
import {
  wsPingPongTimeUpdateAction,
  wsSubscribeToAction,
  wsUnsubscribeAction,
} from '../actions/ws.actions';
import { $isSubscribableRoute } from '../selectors/route.selectors';
import { $wsState } from '../selectors/ws.selectors';
import { refreshSingleRecordingRequestAction } from '../actions/recording-list.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import { ErrorTypes } from '../../shared/components-old/error/error.component';
import {
  deletedRecordingRequestAction,
  deletedRecordingsRequestAction,
} from '../actions/recording.actions';
import {
  refreshAnnotationListRequestAction,
  refreshRecordingsAnnotationListRequestAction,
} from '../actions/annotations-lists.actions';
import { reactOnRemuxWsAction } from '../actions/current-selections.actions';
import { Recording, RenderStatus } from 'src/api/models';
import {
  refreshTrashRequestAction,
  restoreFromTrashSuccessAction,
} from '../actions/trash.actions';
import { refreshRecordersByIdsAction } from '../actions/recorders.actions';
import { showSnackbarAction } from '../actions/snackbar.actions';

@Injectable()
export class WsEffects {
  ws: WebSocketSubject<WsMessageModel> | null;
  wsCompleted = false;
  wsSource: string;
  websocketRetry = 0;
  websocketRetryMax = 10;

  wsInit$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccessAction),
        tap(({ auth: { idToken } }) => {
          this.wsSource = `${environment.wsUrl}${idToken}`;
          this.runWebSocket();
        }),
        switchMap(() => this.store.pipe(take(1), select($wsState))),
        tap(wsState => {
          if (
            wsState.currentlySubscribed?.id &&
            wsState.currentlySubscribed?.subscribedAs
          ) {
            this.store.dispatch(
              wsSubscribeToAction({
                id: wsState.currentlySubscribed.id,
                subscribedAs: wsState.currentlySubscribed.subscribedAs,
                nextPingPong: getNextPingPongTime(),
              }),
            );
          }
        }),
      ),
    { dispatch: false },
  );

  wsPingPongCheck$ = createEffect(
    () =>
      interval(1000).pipe(
        switchMap(() => this.store.pipe(select($wsState))),
        filter(
          ({ nextPingPong }) =>
            this.ws != null && moment.unix(nextPingPong).isBefore(),
        ),
        tap(() => {
          this.store.dispatch(
            wsPingPongTimeUpdateAction({ nextPingPong: getNextPingPongTime() }),
          );
          this.ws?.next({
            action: WsAction.PING,
          });
        }),
      ),
    { dispatch: false },
  );

  wsPingPongCheckOnFocus$ = createEffect(
    () =>
      fromEvent(document, 'visibilitychange').pipe(
        filter(() => document.visibilityState === 'visible'),
        tap(() => {
          if (this.wsCompleted) {
            this.runWebSocket();
          }
        }),
        tap(() => {
          this.store.dispatch(
            wsPingPongTimeUpdateAction({ nextPingPong: getNextPingPongTime() }),
          );
          this.ws?.next({
            action: WsAction.PING,
          });
        }),
      ),
    { dispatch: false },
  );

  recognizeRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(storeRouteAction),
        withLatestFrom(
          this.store.pipe(select($isSubscribableRoute)),
          this.store.pipe(select($wsState)),
        ),
        tap(
          ([
            {
              route: { params },
            },
            isSubscribableRoute,
            { currentlySubscribed },
          ]) => {
            {
              if (
                this.ws &&
                currentlySubscribed &&
                currentlySubscribed.subscribedAs
              ) {
                this.ws.next({
                  action: WsAction.UNSUB,
                  entity: WSEntities.ANNOTATION,
                  ...(currentlySubscribed.subscribedAs === WsSubscribeAs.REC
                    ? { recordingId: currentlySubscribed.id }
                    : { annotationId: currentlySubscribed.id }),
                });

                this.store.dispatch(
                  wsUnsubscribeAction({ nextPingPong: getNextPingPongTime() }),
                );
              }
              if (isSubscribableRoute) {
                const { annotationId } = params;

                this.store.dispatch(
                  wsSubscribeToAction({
                    id: params[QueryParamNames.RecordingId]
                      ? params[QueryParamNames.RecordingId]
                      : annotationId,
                    subscribedAs: params[QueryParamNames.RecordingId]
                      ? WsSubscribeAs.REC
                      : WsSubscribeAs.ANN,
                    nextPingPong: getNextPingPongTime(),
                  }),
                );
              }
            }
          },
        ),
      ),
    { dispatch: false },
  );

  subscribeTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(wsSubscribeToAction),
        tap(({ id, subscribedAs }) => {
          if (this.ws) {
            this.ws.next({
              action: WsAction.SUB,
              entity: WSEntities.ANNOTATION,
              ...(subscribedAs === WsSubscribeAs.REC
                ? { recordingId: id }
                : { annotationId: id }),
            });
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
  ) {}

  runWebSocket(): void {
    if (this.ws) {
      return;
    }

    this.ws = webSocket({
      url: this.wsSource,
      openObserver: {
        next: () => {
          this.websocketRetry = 0;
        },
      },
    });

    this.ws.subscribe(
      msg => this.resolveWsMessage(msg),
      () => this.reconnectWebSocket(),
      () => this.reconnectWebSocket(),
    );
  }

  reconnectWebSocket(): void {
    this.ws = null;

    if (this.websocketRetry < this.websocketRetryMax) {
      setTimeout(() => {
        this.runWebSocket();
      }, WEBSOCKET_RECONNECT_DELAY);
      this.websocketRetry++;
    } else {
      this.store.dispatch(
        handleErrorResponseAction({
          errorType: ErrorTypes.WEBSOCKET_DISCONNECTED,
        }),
      );
    }
  }

  resolveWsMessage(msg: WsMessageModel): void {
    switch (msg.msg) {
      case WSMessages.PONG:
        return this.resolveWsPongMessage();
      case WSMessages.REFRESH:
        return this.resolveWsRefreshMessage(msg);
      case WSMessages.DELETED:
        return this.resolveWsDeleteMessage(msg);
      case WSMessages.CREATED:
        return this.resolveWsCreatedMessage(msg);
      case WSMessages.CHANGE_SHARING:
        return this.resolveWsChangeSharing(msg);
      default:
        return;
    }
  }

  resolveWsCreatedMessage(msg: WsMessageModel): void {
    if (msg.entity === WSEntities.PLAYLIST && msg.id) {
      this.store.dispatch(createdPlaylistRequestAction({ id: msg.id }));
      this.store.dispatch(
        wsPingPongTimeUpdateAction({
          nextPingPong: getNextPingPongTime(),
        }),
      );
    }
  }

  resolveWsRefreshPlaylistMessage(msg: WsMessageModel): void {
    this.store.dispatch(
      refreshPlaylistRequestAction({
        id: msg.id as string,
        showSnackbar: msg.status !== WSStatus.NONE,
        status: msg.status || WSStatus.NONE,
      }),
    );

    this.store.dispatch(
      wsPingPongTimeUpdateAction({
        nextPingPong: getNextPingPongTime(),
      }),
    );
  }

  resolveWsRefreshAnnotationMessage(msg: WsMessageModel): void {
    if (msg.id && msg.recordingId) {
      this.store.dispatch(
        refreshRecordingsAnnotationListRequestAction({
          recordingId: msg.recordingId,
        }),
      );
      this.store.dispatch(
        pollAnnotationsRequestAction({
          id: msg.id,
          nextPingPong: getNextPingPongTime(),
          status: msg.status,
        }),
      );
      if (msg.status && msg.userId) {
        this.store.dispatch(
          showChangeAnnotationNotificationAction({
            status: msg.status,
            userId: msg.userId,
          }),
        );
      }
    }
  }

  resolveWsRefreshAnnotationListMessage(msg: WsMessageModel): void {
    if (msg.id) {
      this.store.dispatch(
        refreshAnnotationListRequestAction({
          annotationListId: msg.id,
          ids: msg.ids,
          status: msg.status || WSStatus.NONE,
        }),
      );

      if (msg.status === RenderStatus.DONE) {
        this.store.dispatch(
          showSnackbarAction({
            infoMessage: `success.annotationListDownload.end`,
          }),
        );
      }

      if (msg.status === RenderStatus.FAILED) {
        this.store.dispatch(
          showSnackbarAction({
            infoMessage: `error.annotationListDownload.400`,
          }),
        );
      }
    }
  }

  resolveWsRefreshRecordingMessage(msg: WsMessageModel): void {
    if (msg.id) {
      this.store.dispatch(
        refreshSingleRecordingRequestAction({
          id: msg.id,
          nextPingPong: getNextPingPongTime(),
          refreshMetadata: false,
        }),
      );
      this.store.dispatch(
        refreshRecordersByIdsAction({
          ids: msg.recordersIds ?? [],
        }),
      );
      if (msg.status === WS_STATUS_RESTORED) {
        this.store.dispatch(
          restoreFromTrashSuccessAction({
            recording: <Recording>{ id: msg.id },
          }),
        );
        this.store.dispatch(
          refreshPlaylistsByRecordingIdAction({ id: msg.id }),
        );
      }
    }
  }

  resolveWsRefreshRemuxMessage(msg: WsMessageModel): void {
    if (msg.recordingId) {
      this.store.dispatch(
        reactOnRemuxWsAction({
          status: msg.status,
          userId: msg.userId,
          videoId: msg.id,
        }),
      );
      this.store.dispatch(
        refreshSingleRecordingRequestAction({
          id: msg.recordingId,
          nextPingPong: getNextPingPongTime(),
          refreshMetadata: false,
        }),
      );
    }
  }

  resolveWsRefreshThumbnailMessage(msg: WsMessageModel): void {
    if (msg.id && msg.url) {
      if (msg.playlistId) {
        this.store.dispatch(
          refreshPlaylistAnnotationThumbnailAction({
            playlistId: msg.playlistId,
            annotationId: msg.id,
            url: msg.url,
          }),
        );
      } else {
        this.store.dispatch(
          refreshAnnotationThumbnailAction({
            annotationId: msg.id,
            url: msg.url,
          }),
        );
      }
    }
  }

  resolveWsRefreshMessage(msg: WsMessageModel): void {
    switch (msg.entity) {
      case WSEntities.PLAYLIST:
        this.resolveWsRefreshPlaylistMessage(msg);
        break;
      case WSEntities.ANNOTATION:
        this.resolveWsRefreshAnnotationMessage(msg);
        break;
      case WSEntities.ANNOTATION_LIST:
        this.resolveWsRefreshAnnotationListMessage(msg);
        break;
      case WSEntities.RECORDING:
        this.resolveWsRefreshRecordingMessage(msg);
        break;
      case WSEntities.REMUX:
        this.resolveWsRefreshRemuxMessage(msg);
        break;
      case WSEntities.TRASH:
        this.store.dispatch(refreshTrashRequestAction());
        break;
      case WSEntities.THUMBNAIL:
        this.resolveWsRefreshThumbnailMessage(msg);
        break;
    }
  }

  resolveWsChangeSharing(msg: WsMessageModel): void {
    if (msg.entity === WSEntities.RECORDING && msg.id) {
      this.store.dispatch(
        refreshSingleRecordingRequestAction({
          id: msg.id,
          nextPingPong: getNextPingPongTime(),
          refreshMetadata: true,
        }),
      );
      this.store.dispatch(refreshPlaylistsByRecordingIdAction({ id: msg.id }));
      return;
    }
  }

  resolveWsDeleteMessage(msg: WsMessageModel): void {
    if (msg.entity === WSEntities.RECORDING && msg.id) {
      this.store.dispatch(deletedRecordingRequestAction({ id: msg.id }));
      this.store.dispatch(
        wsPingPongTimeUpdateAction({
          nextPingPong: getNextPingPongTime(),
        }),
      );
      this.store.dispatch(refreshPlaylistsByRecordingIdAction({ id: msg.id }));
      this.store.dispatch(
        refreshRecordersByIdsAction({
          ids: msg.recordersIds ?? [],
        }),
      );
    }

    if (msg.entity === WSEntities.PLAYLIST && msg.id) {
      this.store.dispatch(deletedPlaylistRequestAction({ id: msg.id }));
      this.store.dispatch(
        wsPingPongTimeUpdateAction({
          nextPingPong: getNextPingPongTime(),
        }),
      );
    }

    if (msg.entity === WSEntities.RECORDINGS && msg.ids) {
      this.store.dispatch(refreshTrashRequestAction());
      this.store.dispatch(deletedRecordingsRequestAction({ ids: msg.ids }));
      this.store.dispatch(
        refreshPlaylistsByRecordingsIdsAction({ ids: msg.ids }),
      );
      this.store.dispatch(
        refreshRecordersByIdsAction({
          ids: msg.recordersIds ?? [],
        }),
      );
    }
  }

  resolveWsPongMessage(): void {
    this.store.dispatch(
      wsPingPongTimeUpdateAction({
        nextPingPong: getNextPingPongTime(),
      }),
    );
  }
}
