import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { filter, switchMapTo, take } from 'rxjs/operators';
import { getPlaylistListRequestAction } from '../actions/playlist-list.actions';
import { getRecordersRequestAction } from '../actions/recorders.actions';
import { getRecordingsMetaRequestAction } from '../actions/recording-list.actions';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../shared/models/app.state';
import { $authSessionValid } from '../selectors/auth.selectors';
import { refreshTrashRequestAction } from '../actions/trash.actions';

@Injectable()
export class InitEffects {
  init$ = createEffect(() =>
    this.store.pipe(
      select($authSessionValid),
      filter(valid => valid),
      take(1),
      switchMapTo(
        of(
          getRecordingsMetaRequestAction({
            query: { from: 0, to: 9999999999 },
          }),
          getRecordersRequestAction(),
          getPlaylistListRequestAction(),
          refreshTrashRequestAction(),
        ),
      ),
    ),
  );

  constructor(private readonly store: Store<AppState>) {}
}
