import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import {
  Annotation,
  AnnotationList,
  AnnotationListDownload,
  Playlist,
  RenderStatus,
} from 'src/api/models';
import { AppState } from '../../models/app.state';
import {
  ShareableEntityModel,
  ShareableEntityType,
} from '../../models/sharing.model';
import { getSharingTeamsRequestAction } from '../../../store/actions/sharing.actions';
import { ShareModalComponent } from '../modal/share-modal/share-modal.component';
import { downloadAnnotationsListRequestAction } from '../../../store/actions/annotations-lists.actions';
import {
  $currentRecordingAnnotationsIds,
  $currentRecordingHasVideo,
  $filteredSelectedAnnotationIds,
  $isMultiselectEnabled,
  $isSelectionEmpty,
  $selectedAnnotations,
  $selectionEmptyOrContainsSharedAnnotations,
} from '../../../store/selectors/current-selections.selectors';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { deleteAnnotationsRequestAction } from '../../../store/actions/annotation.actions';
import {
  INPUT_TYPE,
  RemoveModalComponent,
} from '../modal/remove-modal/remove-modal.component';
import { take } from 'rxjs';
import { AddToPlaylistModalComponent } from '../modal/add-to-playlist-modal/add-to-playlist-modal.component';
import { removeFromPlaylistRequestAction } from '../../../store/actions/playlist-list.actions';
import { FileDownloadService } from 'src/app/core/services/file-download.service';
import { arraysEqual } from 'src/app/app.utils';

@Component({
  selector: 'cmv-created-tags-more',
  templateUrl: './created-tags-more.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIcon,
    MatIconButton,
    MatMenuTrigger,
    MatMenu,
    MatMenuItem,
    AsyncPipe,
    TranslateModule,
  ],
})
export class CreatedTagsMoreComponent {
  @Input() shareableList: AnnotationList;
  @Input() annotationsEmpty: Boolean = true;
  @Input() isDownloadPrepared: Boolean = false;
  @Input() showDownload: Boolean = true;
  @Input() showSharing: Boolean = true;
  @Input() playlist: Playlist | null = null;

  readonly currentRecordingHasVideo$ = this.store.pipe(
    select($currentRecordingHasVideo),
  );

  readonly isMultiselectEnabled$ = this.store.pipe(
    select($isMultiselectEnabled),
  );

  readonly isSelectionEmpty$ = this.store.pipe(select($isSelectionEmpty));
  readonly selectionEmptyOrContainsSharedAnnotations$ = this.store.pipe(
    select($selectionEmptyOrContainsSharedAnnotations),
  );

  readonly selectedAnnotations$ = this.store.pipe(select($selectedAnnotations));
  readonly selectedAnnotationsIds$ = this.store.pipe(
    select($filteredSelectedAnnotationIds),
  );

  readonly currentRecordingAnnotationsIds$ = this.store.pipe(
    select($currentRecordingAnnotationsIds),
  );

  constructor(
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
    private readonly fileDownloadService: FileDownloadService,
  ) {}

  shareAnnotationList(annotationList: AnnotationList): void {
    const shareable: ShareableEntityModel = {
      annotationListId: annotationList.id,
      sharings: annotationList.sharings,
      type: ShareableEntityType.LIST,
    };
    this.store.dispatch(getSharingTeamsRequestAction({ shareable }));
    this.dialog.open(ShareModalComponent, {
      data: {
        entityType: ShareableEntityType.LIST,
      },
    });
  }

  downloadAnnotationList(annotationIds: string[]): void {
    const downloadInfo = this.getDownloadInfo(annotationIds);
    if (downloadInfo?.status === RenderStatus.DONE && downloadInfo?.url) {
      this.fileDownloadService.downloadFile(downloadInfo?.url);
    } else if (!this.isDownloadInfoRendering(downloadInfo)) {
      this.store.dispatch(
        downloadAnnotationsListRequestAction({
          id: this.shareableList.id,
          annotationsIds: annotationIds,
        }),
      );
    }
  }

  addToPlaylist(annotations: Annotation[]): void {
    this.dialog.open(AddToPlaylistModalComponent, {
      data: {
        annotations,
        fromPlaylistId: this.playlist?.id,
      },
    });
  }

  shareAnnotations(annotations: Annotation[]): void {
    const shareable: ShareableEntityModel = {
      annotationsIds: annotations.map(annotation => annotation.id),
      sharings: annotations[0].sharings?.filter(
        sharing =>
          annotations.every(
            annotation =>
              annotation.sharings?.findIndex(
                s => s.teamId === sharing.teamId && s.userId === sharing.userId,
              ) > -1,
          ) ?? [],
      ),
      type: ShareableEntityType.ANNOTATIONS,
    };

    this.store.dispatch(getSharingTeamsRequestAction({ shareable }));
    this.dialog.open(ShareModalComponent, {
      data: {
        entityType: ShareableEntityType.ANNOTATIONS,
        multiple: annotations.length > 1,
      },
    });
  }

  deleteAnnotations(annotations: Annotation[]): void {
    const dialog = this.dialog.open(RemoveModalComponent, {
      data: {
        title: annotations.map(annotation => annotation.name).join(', '),
        type:
          annotations.length > 1
            ? INPUT_TYPE.ANNOTATIONS
            : INPUT_TYPE.ANNOTATION,
      },
    });
    const handleFunction = (value: boolean) => {
      if (value) {
        if (this.playlist) {
          this.store.dispatch(
            removeFromPlaylistRequestAction({
              annotationsIds: annotations.map(annotation => annotation.id),
              annotationName: annotations.map(a => a.name).join(', '),
              playlistId: this.playlist.id,
              playlistName: this.playlist.name,
            }),
          );
        } else {
          this.store.dispatch(deleteAnnotationsRequestAction({ annotations }));
        }
      }
    };

    dialog.afterClosed().pipe(take(1)).subscribe(handleFunction);
  }

  getDownloadButtonLabel(annotationIds: string[]): string {
    const downloadInfo = this.getDownloadInfo(annotationIds);
    return downloadInfo?.status === RenderStatus.RENDERING
      ? 'basic.renderingAnnotations'
      : 'basic.downloadAnnotations';
  }

  getDownloadButtonIcon(annotationIds: string[]): string {
    const downloadInfo = this.getDownloadInfo(annotationIds);

    switch (downloadInfo?.status) {
      case RenderStatus.RENDERING:
        return 'cmv-loading';
      case RenderStatus.FAILED:
        return 'cmv-retry';
      default:
        return 'cmv-download';
    }
  }

  getDownloadInfo(annotationIds: string[]) {
    return this.shareableList?.downloads?.find(d =>
      arraysEqual(d.ids, annotationIds),
    );
  }

  isDownloadInfoRendering(info: AnnotationListDownload | undefined) {
    return (
      info?.status === RenderStatus.RENDERING ||
      info?.status === RenderStatus.ZIPPING
    );
  }
}
