import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageLayoutComponent } from '../../layouts/page-layout/page-layout.component';
import { ManualSchedulingFormComponent } from '../../shared/components-old/manual-scheduling-form/manual-scheduling-form.component';
import { HeaderComponent } from '../../shared/components/header/header.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-create-recording',
  templateUrl: './create-recording.component.html',
  styleUrls: ['./create-recording.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PageLayoutComponent,
    ManualSchedulingFormComponent,
    HeaderComponent,
    TranslateModule,
  ],
})
export class CreateRecordingComponent {
  constructor() {}
}
