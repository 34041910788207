<div class="created-tags-filter">
  @let tags = filterSearchTags$ | async;
  @if (tags.length > 1) {
    <mat-form-field floatLabel="never">
      <mat-label>{{ 'components.search.tags' | translate }}</mat-label>
      <mat-select
        (selectionChange)="tagSelectChanged($event)"
        [multiple]="true"
      >
        @for (tag of tags; track tag.id) {
          <mat-option [value]="tag.id">
            {{ tag.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @let teams = currentRecordingTeams$ | async;
  @if (teams !== null && teams.length > 1) {
    <mat-form-field floatLabel="never">
      <mat-label>{{ 'components.search.teams' | translate }}</mat-label>
      <mat-select
        (selectionChange)="teamSelectChanged($event)"
        [multiple]="true"
      >
        @for (team of currentRecordingTeams$ | async; track team.id) {
          <mat-option [value]="team.id">
            {{ team.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
</div>
