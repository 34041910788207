import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, from, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { APIClient } from '../../../api';
import { Annotation, RenderStatus } from '../../../api/models';
import {
  ANNOTATIONS_POLLING_INTERVAL,
  GoogleAnalyticsEvent,
  QueryParamNames,
  RoutePath,
} from '../../app.constants';
import { reportToGA } from '../../app.utils';
import { isNoRightError } from '../../shared/components-old/error/error.component';
import { AppState } from '../../shared/models/app.state';
import {
  deleteAnnotationsFailureAction,
  deleteAnnotationsRequestAction,
  deleteAnnotationsSuccessAction,
  downloadAnnotationFailureAction,
  downloadAnnotationRequestAction,
  downloadAnnotationSuccessAction,
  editAnnotationNameFailureAction,
  editAnnotationNameRequestAction,
  editAnnotationNameSuccessAction,
  editAnnotationTrimmingFailureAction,
  editAnnotationTrimmingNoRightsAction,
  editAnnotationTrimmingRequestAction,
  editAnnotationTrimmingSuccessAction,
  getAnnotationsFailureAction,
  getAnnotationsRequestAction,
  getAnnotationsSuccessAction,
  pollAnnotationsFailureAction,
  pollAnnotationsRequestAction,
  pollAnnotationsSuccessAction,
  postAnnotationFailureAction,
  postAnnotationRequestAction,
  postAnnotationSuccessAction,
  prepareAnnotationPostRequestAction,
  prepareCurrentAnnotationTrimmingRequestAction,
  prepareLiveMatchAnnotationPostRequestAction,
  showChangeAnnotationNotificationAction,
} from '../actions/annotation.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import { showSnackbarAction } from '../actions/snackbar.actions';
import {
  $annotation,
  $annotationName,
  $annotationsCountByType,
} from '../selectors/annotation.selectors';
import {
  $currentAnnotation,
  $currentPlaylistsRecording,
  $currentRecording,
  $currentVideo,
  $isStreamTagging,
  $userDownloadingAnnotation,
} from '../selectors/current-selections.selectors';
import {
  $pathSegments,
  $sectionRoute,
  $subSectionRoute,
} from '../selectors/route.selectors';
import {
  removeDownloadAnnotation,
  setCurrentVideoAction,
} from '../actions/current-selections.actions';
import { ApiService } from 'src/app/core/services/api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { $userId } from '../selectors/user.selectors';
import { FileDownloadService } from 'src/app/core/services/file-download.service';

@Injectable()
export class AnnotationEffects {
  getAnnotations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAnnotationsRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.getAnnotation({ recordingId: id }))
          .pipe(
            map(annotations => getAnnotationsSuccessAction({ annotations })),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };
              return of(
                getAnnotationsFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  getAnnotationsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAnnotationsSuccessAction),
      switchMap(() =>
        this.store.pipe(
          select($pathSegments),
          filter(([, section]) => section === RoutePath.Playlists),
          switchMap(([, , subsection]) => {
            switch (subsection) {
              case RoutePath.Clip:
                return this.store.pipe(
                  select($currentPlaylistsRecording),
                  filter(recording => !!recording),
                  map(recording =>
                    setCurrentVideoAction({
                      videos: recording!.videos,
                    }),
                  ),
                );
              default:
                return EMPTY;
            }
          }),
        ),
      ),
    ),
  );

  pollAnnotations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollAnnotationsRequestAction),
      withLatestFrom(
        this.store.select($currentAnnotation),
        this.store.select($sectionRoute),
        this.store.select($subSectionRoute),
        this.store.select($userDownloadingAnnotation),
      ),
      switchMap(
        ([
          { id, nextPingPong, status },
          currentAnnotation,
          section,
          subsection,
          userDownloadingAnnotation,
        ]) =>
          this.apiService
            .call(() => this.apiClient.getAnnotation({ recordingId: id }))
            .pipe(
              switchMap(annotations => {
                if (
                  section === RoutePath.Recordings &&
                  (subsection === RoutePath.LiveMatch ||
                    subsection === RoutePath.PlayedMatch) &&
                  !annotations.find(a => a.id === currentAnnotation?.id)
                ) {
                  this.router.navigate(
                    [RoutePath.Platform, RoutePath.Recordings, subsection],
                    {
                      queryParams: { [QueryParamNames.RecordingId]: id },
                      replaceUrl: true,
                    },
                  );
                }

                let removeDownloadAnnotations: Action[] = [];
                if (
                  status == RenderStatus.FAILED ||
                  status == RenderStatus.DONE
                ) {
                  const downloadingAnnotations =
                    userDownloadingAnnotation.filter(u => u.recordingId === id);

                  downloadingAnnotations.forEach(downloadingAnnotation => {
                    let annotation = annotations.find(
                      a => a.id === downloadingAnnotation.id,
                    );

                    if (annotation) {
                      if (
                        annotation.status == RenderStatus.DONE &&
                        annotation.url
                      ) {
                        this.fileDownloadService.downloadFile(
                          annotation.url,
                          annotation.name,
                        );
                      }
                      if (
                        annotation.status == RenderStatus.DONE ||
                        annotation.status == RenderStatus.FAILED
                      ) {
                        removeDownloadAnnotations.push(
                          removeDownloadAnnotation({
                            annotationId: annotation.id,
                          }),
                        );
                      }
                    }
                  });
                }

                return from([
                  ...removeDownloadAnnotations,
                  pollAnnotationsSuccessAction({
                    annotations,
                    nextPingPong,
                    id,
                  }),
                ]);
              }),
              catchError(errors =>
                of(pollAnnotationsFailureAction({ errors })),
              ),
            ),
      ),
    ),
  );

  pollAnnotationsCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pollAnnotationsSuccessAction),
      filter(({ annotations }) =>
        annotations.some(a => a.thumbnail == null || a.thumbnail.length < 1),
      ),
      debounceTime(ANNOTATIONS_POLLING_INTERVAL),
      map(({ id, nextPingPong }) =>
        pollAnnotationsRequestAction({ id, nextPingPong }),
      ),
    ),
  );

  prepareAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareAnnotationPostRequestAction),
      switchMap(({ annotation, currentTime, inOverlay }) =>
        this.store.pipe(
          select($currentRecording),
          take(1),
          switchMap(match =>
            this.store.pipe(
              select(
                $annotationsCountByType(
                  annotation!.annotationTypeName!,
                  annotation!.team!,
                  match.id!,
                ),
              ),
              take(1),
              withLatestFrom(this.store.pipe(select($currentVideo))),
              map(([, video]) =>
                postAnnotationRequestAction({
                  live: false,
                  annotation: {
                    ...annotation,
                    name: annotation.annotationTypeName,
                    recordingId: match.id,
                    timeStamp: video.startAt + currentTime,
                  },
                  inOverlay,
                }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  prepareLiveMatchAnnotation = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareLiveMatchAnnotationPostRequestAction),
      switchMap(({ annotation, currentTime, videoPlayer, inOverlay }) =>
        this.store.pipe(
          select($currentRecording),
          take(1),
          switchMap(match =>
            this.store.pipe(
              select(
                $annotationsCountByType(
                  annotation!.annotationTypeName!,
                  annotation!.team!,
                  match.id!,
                ),
              ),
              take(1),
              map(() => ({
                ...annotation,
                name: annotation.annotationTypeName,
                recordingId: match.id,
              })),
              withLatestFrom(
                this.store.pipe(take(1), select($isStreamTagging)),
                this.store.pipe(select($currentVideo)),
              ),
              switchMap(([preparedAnnotation, isStreamTagging, video]) =>
                isStreamTagging
                  ? !!video && videoPlayer
                    ? of(
                        postAnnotationRequestAction({
                          live: false,
                          annotation: {
                            ...preparedAnnotation,
                            timeStamp: video.startAt + currentTime,
                          },
                          inOverlay,
                        }),
                      )
                    : EMPTY
                  : of(
                      postAnnotationRequestAction({
                        annotation: preparedAnnotation,
                        live: true,
                        inOverlay,
                      }),
                    ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  postAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postAnnotationRequestAction),
      switchMap(({ annotation, live, inOverlay }) =>
        this.apiService
          .call(() =>
            this.apiClient.postAnnotation({
              body: annotation as Annotation,
              live,
            }),
          )
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.CREATE_TAG, {
                annotation: (annotation as Annotation).annotationTypeName,
                overlay: inOverlay,
              });
            }),
            switchMap(response =>
              of(
                postAnnotationSuccessAction({ annotation: response }),
                showSnackbarAction({
                  entryBegin: response.name,
                  infoMessage: 'success.createTag.create',
                  undoTagId: response.id,
                }),
              ),
            ),
            catchError(errors =>
              of(
                postAnnotationFailureAction({ errors }),
                showSnackbarAction({
                  entryBegin: annotation.name,
                  infoMessage: 'error.createTag.create',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  scrollToCreatedAnnotation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(postAnnotationSuccessAction),
        map(({ annotation }) => {
          setTimeout(() => {
            const el = document.getElementById((annotation as Annotation).id);
            if (!!el) {
              el.scrollIntoView();
            }
          });
        }),
      ),
    { dispatch: false },
  );

  editAnnotationName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAnnotationNameRequestAction),
      switchMap(payload =>
        this.apiService
          .call(() => this.apiClient.patchAnnotation({ body: payload }))
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.EDIT_TAG, {
                playlist: false,
              });
            }),
            switchMap(annotation =>
              of(
                editAnnotationNameSuccessAction({ annotation }),
                showSnackbarAction({
                  entryBegin: payload.name,
                  infoMessage: 'success.createTag.edit',
                  icon: 'edit',
                }),
              ),
            ),
            catchError(errors =>
              of(
                editAnnotationNameFailureAction({ errors }),
                showSnackbarAction({
                  entryBegin: payload.name,
                  infoMessage: 'error.createTag.edit',
                  icon: 'edit',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  prepareCurrentAnnotationTrimmingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prepareCurrentAnnotationTrimmingRequestAction),
      withLatestFrom(
        this.store.pipe(select($currentAnnotation)),
        this.store.pipe(select($currentVideo)),
      ),
      filter(([, annotation, video]) => !!(annotation && video)),
      switchMap(([{ newValues }, annotation, video]) => {
        const tagCreated = annotation!.timeStamp - video.startAt;
        const tagStart = tagCreated - annotation!.from;
        const timestamp =
          annotation!.timeStamp +
          (newValues.from - tagStart) -
          annotation!.from;

        const to = newValues.to - newValues.from;

        return of(
          editAnnotationTrimmingRequestAction({
            id: annotation!.id,
            from: 0,
            to,
            timestamp,
          }),
        );
      }),
    ),
  );

  editAnnotationTrimming$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editAnnotationTrimmingRequestAction),
      switchMap(payload =>
        this.apiService
          .call(() =>
            this.apiClient.getRecordingsAnnotationId({ id: payload.id }),
          )
          .pipe(
            switchMap(recAnnotations =>
              of(recAnnotations).pipe(
                withLatestFrom(
                  this.store.pipe(select($annotationName(payload.id))),
                ),
              ),
            ),
            switchMap(([, annotationName]) =>
              this.apiService
                .call(() => this.apiClient.patchAnnotation({ body: payload }))
                .pipe(
                  tap(() => {
                    reportToGA(GoogleAnalyticsEvent.TRIM_TAG, {
                      playlist: false,
                    });
                  }),
                  switchMap(annotation =>
                    of(
                      editAnnotationTrimmingSuccessAction({ annotation }),
                      showSnackbarAction({
                        entryBegin: annotationName,
                        infoMessage: 'success.videoDetail.trim',
                        icon: 'trim',
                      }),
                    ),
                  ),
                  catchError(errors =>
                    of(
                      editAnnotationTrimmingFailureAction({ errors }),
                      showSnackbarAction({
                        entryBegin: annotationName,
                        infoMessage: 'error.videoDetail.trim',
                        icon: 'trim',
                      }),
                    ),
                  ),
                ),
            ),
            catchError(errors => {
              const errorAction = isNoRightError(errors)
                ? editAnnotationTrimmingNoRightsAction({ id: payload.id })
                : editAnnotationTrimmingFailureAction({ errors });
              return of(errorAction);
            }),
          ),
      ),
    ),
  );

  deleteAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteAnnotationsRequestAction),
      switchMap(({ annotations }) => {
        const ids = annotations.map(a => a.id);
        const annotationsNamesText = annotations.map(a => a.name).join(', ');

        return this.apiService
          .call(() =>
            this.apiClient.deleteAnnotation({
              body: {
                annotationsIds: ids,
              },
            }),
          )
          .pipe(
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.REMOVE_TAG);
            }),
            switchMap(() =>
              of(
                deleteAnnotationsSuccessAction({
                  ids,
                }),
                showSnackbarAction({
                  entryBegin: annotationsNamesText,
                  infoMessage: 'success.createTag.delete',
                  icon: 'delete',
                }),
              ),
            ),
            catchError(errors =>
              of(
                deleteAnnotationsFailureAction({ errors }),
                showSnackbarAction({
                  entryBegin: annotationsNamesText,
                  infoMessage: 'error.createTag.delete',
                  icon: 'delete',
                }),
              ),
            ),
          );
      }),
    ),
  );

  editAnnotationTrimmingNoRights$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editAnnotationTrimmingNoRightsAction),
        switchMap(({ id }) =>
          this.store.pipe(take(1), select($annotation(id))),
        ),
        switchMap(annotation =>
          this.apiService
            .call(() =>
              this.apiClient.getAnnotation({
                recordingId: annotation.recordingId,
              }),
            )
            .pipe(
              tap(annotations => this.handleRedirect(annotation, annotations)),
              switchMap(annotations =>
                of(
                  getAnnotationsSuccessAction({ annotations }),
                  showSnackbarAction({ infoMessage: 'neutral.unavailableTag' }),
                ),
              ),
              catchError(error => {
                const parsedError = {
                  type: error.status.toString(),
                  message: error.error.msg,
                };
                return of(
                  getAnnotationsFailureAction({
                    errors: [parsedError],
                  }),
                  handleErrorResponseAction({
                    errorType: parsedError.type,
                  }),
                );
              }),
            ),
        ),
      ),
    { dispatch: true },
  );

  downloadAnnotation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(downloadAnnotationRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.getAnnotationDownloadId({ id }))
          .pipe(
            switchMap(annotation =>
              of(
                downloadAnnotationSuccessAction({ annotation }),
                showSnackbarAction({
                  infoMessage: 'success.annotationDownload.start',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.DOWNLOAD_TAG);
            }),
            catchError((err: HttpErrorResponse) =>
              of(
                downloadAnnotationFailureAction(),
                showSnackbarAction({
                  infoMessage: `error.playlistDownload.${err.status}`,
                  messageParameters: { ...err.error },
                }),
              ),
            ),
          ),
      ),
    ),
  );

  showChangeAnnotationNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showChangeAnnotationNotificationAction),
      withLatestFrom(this.store.select($userId)),
      filter(([{ userId }, currentUserId]) => userId === currentUserId),
      filter(
        ([{ status }]) =>
          status == RenderStatus.FAILED || status == RenderStatus.DONE,
      ),
      map(([{ status }]) =>
        showSnackbarAction({
          infoMessage:
            status == RenderStatus.DONE
              ? `success.annotationDownload.end`
              : `error.annotationDownload.400`,
        }),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
    private readonly fileDownloadService: FileDownloadService,
  ) {}

  nextPlayingAnnId(
    annotations: Annotation[],
    missingAnnotation: Annotation,
  ): string {
    const sortedAnnotation = [...annotations].sort(
      (a, b) => a.timeStamp - b.timeStamp,
    );
    const nextAnn = sortedAnnotation.find(
      ann => ann.timeStamp >= missingAnnotation.timeStamp,
    );
    const prevtAnn = sortedAnnotation
      .reverse()
      .find(ann => ann.timeStamp <= missingAnnotation.timeStamp);
    return nextAnn
      ? nextAnn.id
      : prevtAnn
        ? prevtAnn.id
        : missingAnnotation.recordingId;
  }

  handleRedirect(annotation: Annotation, annotations: Annotation[]): void {
    if (annotations.length) {
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Recordings, RoutePath.Clip],
        {
          queryParams: {
            [QueryParamNames.AnnotationId]: this.nextPlayingAnnId(
              annotations,
              annotation,
            ),
            [QueryParamNames.RecordingId]: annotation.recordingId,
          },
        },
      );
    } else {
      this.router.navigate(
        [RoutePath.Platform, RoutePath.Recordings, RoutePath.PlayedMatch],
        {
          queryParams: {
            [QueryParamNames.RecordingId]: annotation.recordingId,
          },
        },
      );
    }
  }
}
