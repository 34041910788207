import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AppState } from '../../shared/models/app.state';
import { Store, select } from '@ngrx/store';
import {
  handleLoginAction,
  loginAction,
} from '../../store/actions/auth.actions';
import { $authLoading } from '../../store/selectors/auth.selectors';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatProgressSpinner, RouterLink, AsyncPipe, TranslateModule],
})
export class LandingComponent {
  readonly authLoading$ = this.store.pipe(select($authLoading));

  constructor(
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
  ) {
    const { fragment, queryParams } = this.route.snapshot;
    if (fragment && fragment.indexOf('access_token=') !== -1) {
      this.store.dispatch(handleLoginAction());
    }
    if (queryParams?.code) {
      this.store.dispatch(handleLoginAction());
    }
  }

  logIn(): void {
    this.store.dispatch(loginAction());
  }
}
