import { createSelector } from '@ngrx/store';
import { AppState } from '../../shared/models/app.state';
import { $currentRecording } from './current-selections.selectors';
import { RecordingStatus, RecordingType } from 'src/api/models';
import { $recorderEntities } from './recorders.selectors';

export const $ptz = (state: AppState) => state.ptz;

export const $isPtzEnabled = createSelector(
  $currentRecording,
  $recorderEntities,
  (recording, recorders) =>
    recording?.status === RecordingStatus.LIVE &&
    recorders[recording.recorderId] &&
    (recording.type === RecordingType.OTHER ||
      recording.type === RecordingType.TRAINING),
);

export const $ptzOverlayVisible = createSelector(
  $ptz,
  state => state.overlayVisible,
);

export const $isManualActive = createSelector($ptz, state => state.manual);

export const $ptzActiveView = createSelector($ptz, state => ({
  manual: state.manual,
  view: state.view,
}));

export const $ptzSetInfo = createSelector($ptz, state =>
  state.setAt && state.changeAt
    ? {
        setAt: state.setAt,
        changeAt: state.changeAt,
      }
    : null,
);
