import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs/operators';
import { QueryParamNames, RoutePath } from '../../app.constants';
import { AppState } from '../../shared/models/app.state';
import {
  $recordingAsFormInput,
  $recordingsLoaded,
} from '../../store/selectors/recording-list.selectors';
import { $currentRecordingId } from '../../store/selectors/route.selectors';
import { PageLayoutComponent } from '../../layouts/page-layout/page-layout.component';
import { AsyncPipe } from '@angular/common';
import { ManualSchedulingFormComponent } from '../../shared/components-old/manual-scheduling-form/manual-scheduling-form.component';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'cmv-edit-recording',
  templateUrl: './edit-recording.component.html',
  styleUrls: ['./edit-recording.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PageLayoutComponent,
    ManualSchedulingFormComponent,
    MatIcon,
    AsyncPipe,
    TranslateModule,
  ],
})
export class EditRecordingComponent implements OnInit {
  readonly recordingsLoaded$ = this.store.pipe(select($recordingsLoaded));

  readonly currentRecordingId$ = this.store.pipe(select($currentRecordingId));
  readonly recordingAsFormInput$ = (id: string) =>
    this.store.pipe(select($recordingAsFormInput(id)));
  readonly formInput$ = this.currentRecordingId$.pipe(
    switchMap(id => this.recordingAsFormInput$(id)),
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    const queryParams = await firstValueFrom(
      this.route.queryParams.pipe(take(1)),
    );

    if (
      !Object.keys(queryParams).length ||
      !queryParams[QueryParamNames.RecordingId]
    ) {
      this.createRecording();
    }
  }

  createRecording(): void {
    this.router.navigate([
      RoutePath.Platform,
      RoutePath.Recordings,
      RoutePath.Create,
    ]);
  }
}
