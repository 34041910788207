<cmv-search></cmv-search>

<div class="filter-result-container">
  @if (filterResults$ | async) {
    <div class="filter-results-wrapper">
      <div class="results">
        @if (noResults$ | async) {
          <div class="empty-results">
            <div class="cmv-h1">
              {{ 'components.search.noResults' | translate }}
            </div>
          </div>
        }

        @for (result of filterResults$ | async; track result.id) {
          @if (result.itemType === FilterSearchModelType.RECORDING) {
            <cmv-match-widget
              [match]="result"
              navigation="true"
            ></cmv-match-widget>
          } @else {
            <cmv-video-widget
              hideDownload="true"
              [widgetData]="result"
              showRecordingName="true"
              (deleteTag)="deleteTag($event)"
              (editAnnotation)="editAnnotation($event)"
              (click)="redirectToAnnotation(result.id, result.recordingId)"
              (trimVideo)="redirectToTrimming($event.id, $event.recordingId)"
            ></cmv-video-widget>
          }
        }
      </div>
    </div>
  }
</div>
