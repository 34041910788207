import { ErrorModel } from '../../shared/models/app.state';
import { Sharing, SharingUserTeam } from '../../../api/models';
import { SharingTypes } from '../../shared/components-old/tree/tree.component';
import {
  ShareableEntityModel,
  SharingTeamModel,
  SharingTreeUserModel,
} from '../../shared/models/sharing.model';
import { createAction, props } from '@ngrx/store';

export const getSharingTeamsRequestAction = createAction(
  'GetSharingTeamsRequest',
  props<{ shareable: ShareableEntityModel }>(),
);
export const getSharingTeamsSuccessAction = createAction(
  'GetSharingTeamsSuccess',
  props<{ shareable: ShareableEntityModel; sharingTeams: SharingUserTeam[] }>(),
);
export const getSharingTeamsFailureAction = createAction(
  'GetSharingTeamsFailure',
  props<{ errors: ErrorModel[] }>(),
);

/* Tree Control */
export const toggleTreeExpandedAction = createAction(
  'ToggleTreeExpanded',
  props<{ teamId: string }>(),
);

export const toggleUserRequestAction = createAction(
  'ToggleUserRequest',
  props<{ user: SharingTreeUserModel }>(),
);
export const toggleUserSuccessAction = createAction(
  'ToggleUserSuccess',
  props<{ sharings: Sharing[] }>(),
);
export const toggleUserFailureAction = createAction(
  'ToggleUserFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const changeSharingMethodRequestAction = createAction(
  'ChangeSharingMethodRequest',
  props<{ team: SharingTeamModel; sharingType: SharingTypes }>(),
);
export const changeSharingMethodSuccessAction = createAction(
  'ChangeSharingMethodSuccess',
  props<{ sharings: Sharing[] }>(),
);
export const changeSharingMethodFailureAction = createAction(
  'ChangeSharingMethodFailure',
  props<{ errors: ErrorModel[] }>(),
);
