import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import {
  catchError,
  debounce,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { APIClient } from '../../../api';
import { AppState } from '../../shared/models/app.state';
import { handleErrorResponseAction } from '../actions/error.actions';
import {
  getTeamsFailureAction,
  getTeamsRequestAction,
  getTeamsSuccessAction,
} from '../actions/teams.actions';
import { $returnIdIfUnloaded } from '../selectors/teams.selectors';

@Injectable()
export class TeamsEffects {
  getTeams$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getTeamsRequestAction),
      debounce(({ forceSearch }) => (!forceSearch ? timer(1000) : of({}))),
      switchMap(({ id }) => this.store.pipe(select($returnIdIfUnloaded(id)))),
      filter(clubId => !!clubId),
      mergeMap((clubId: string) =>
        this.apiService
          .call(() => this.apiClient.getFeTeam({ clubId }))
          .pipe(
            map(teams =>
              getTeamsSuccessAction({
                data: teams.map(team => ({ ...team, clubId })),
                clubId,
              }),
            ),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };
              return of(
                getTeamsFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly store: Store<AppState>,
    private readonly apiService: ApiService,
  ) {}
}
