<a
  [routerLink]="routerLink"
  [queryParams]="routerQueryParameters"
  [class.canNavigate]="navigation === true"
  class="playlist-title-container"
  [ngClass]="{
    'empty-playlist': playlistLength === 0,
  }"
>
  <div class="cmv-h2 playlist-name">{{ playlist.name }}</div>
  <div class="right-side">
    <div class="cmv-h2 playlist-count">
      {{ playlistLength }}
      {{ playlistCountSuffixKey | translate }}
    </div>
    <button
      mat-icon-button
      class="cmv-more-button"
      [matMenuTriggerFor]="detailMenu"
      (click)="menuButtonClickHandler($event)"
    >
      <img src="assets/icons/more_icon_ver.svg" />
    </button>

    <mat-menu class="cmv-menu-container" #detailMenu="matMenu">
      <div (keydown)="$event.stopPropagation()">
        <cmv-menu-item
          (action)="openModal('EditModal')"
          [label]="'basic.editPlaylist' | translate"
          icon="cmv-edit"
        />
        @if (canDownload) {
          <cmv-menu-item
            [icon]="downloadButtonIcon"
            [label]="downloadButtonLabel | translate"
            (action)="downloadHandler(playlist.id)"
          />
        }
        <cmv-menu-item
          icon="cmv-delete"
          [label]="'basic.removePlaylist' | translate"
          (action)="openModal('RemoveModal')"
        />
      </div>
    </mat-menu>
  </div>
</a>

@if (isPlaylistRendering) {
  <cmv-loading-bar></cmv-loading-bar>
}
