import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../models/app.state';
import { hideErrorOverlay } from '../../../store/actions/ui-flags.actions';
import { $errorOverlayVisible } from '../../../store/selectors/ui-flags.selectors';
import { NgIf, AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export enum ErrorTypes {
  NOT_FOUND = '404',
  NO_RIGHTS = 'no rights for this object',
  NO_DOCUMENT = 'mongo: no documents in result',
  WEBSOCKET_DISCONNECTED = 'WebSocket disconnected',
  DEFAULT = 'default',
}

@Component({
  selector: 'cmv-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, AsyncPipe, TranslateModule],
})
export class ErrorComponent {
  readonly errorOverlayVisible$ = this.store.pipe(select($errorOverlayVisible));

  constructor(private readonly store: Store<AppState>) {}

  reloadPage(): void {
    location.reload();
  }
  continueWithErrors(): void {
    this.store.dispatch(hideErrorOverlay());
  }
}

export const isNoRightError = (error: HttpErrorResponse): boolean =>
  error.error.msg === ErrorTypes.NO_RIGHTS ||
  error.error.msg === ErrorTypes.NO_DOCUMENT;
