export const APP_ICONS: { iconName: string; iconPath: string }[] = [
  {
    iconName: 'cmv-add-playlist',
    iconPath: 'assets/icons/add_playlist_icon.svg',
  },
  {
    iconName: 'cmv-checkmark',
    iconPath: 'assets/icons/checkmark.svg',
  },
  {
    iconName: 'cmv-closing',
    iconPath: 'assets/icons/closing_icon.svg',
  },
  {
    iconName: 'cmv-delete',
    iconPath: 'assets/icons/delete_icon.svg',
  },
  {
    iconName: 'cmv-clock',
    iconPath: 'assets/icons/clock_icon.svg',
  },
  {
    iconName: 'cmv-edit',
    iconPath: 'assets/icons/edit_icon.svg',
  },
  {
    iconName: 'cmv-matches',
    iconPath: 'assets/icons/matches_icon.svg',
  },
  {
    iconName: 'cmv-menu',
    iconPath: 'assets/icons/menu_icon.svg',
  },
  {
    iconName: 'cmv-more',
    iconPath: 'assets/icons/more_icon.svg',
  },
  {
    iconName: 'cmv-pause',
    iconPath: 'assets/icons/pause_icon.svg',
  },
  {
    iconName: 'cmv-play',
    iconPath: 'assets/icons/play_icon.svg',
  },
  {
    iconName: 'cmv-playlist',
    iconPath: 'assets/icons/playlist_icon.svg',
  },
  {
    iconName: 'cmv-plus',
    iconPath: 'assets/icons/plus_icon.svg',
  },
  {
    iconName: 'cmv-shield',
    iconPath: 'assets/icons/shield_icon.svg',
  },
  {
    iconName: 'cmv-search',
    iconPath: 'assets/icons/search_icon.svg',
  },
  {
    iconName: 'cmv-stopwatch',
    iconPath: 'assets/icons/stopwatch_icon.svg',
  },
  {
    iconName: 'cmv-minus',
    iconPath: 'assets/icons/minus_icon.svg',
  },
  {
    iconName: 'cmv-trim',
    iconPath: 'assets/icons/trim_icon.svg',
  },
  {
    iconName: 'cmv-arrow-left',
    iconPath: 'assets/icons/arrow_left_icon.svg',
  },
  {
    iconName: 'cmv-arrow-right',
    iconPath: 'assets/icons/arrow_right_icon.svg',
  },
  {
    iconName: 'cmv-back',
    iconPath: 'assets/icons/arrow_back.svg',
  },
  {
    iconName: 'cmv-faq',
    iconPath: 'assets/icons/faq.svg',
  },
  {
    iconName: 'cmv-help',
    iconPath: 'assets/icons/help.svg',
  },
  {
    iconName: 'cmv-whistle',
    iconPath: 'assets/icons/help-copy.svg',
  },
  {
    iconName: 'cmv-info',
    iconPath: 'assets/icons/info.svg',
  },
  {
    iconName: 'cmv-privacy',
    iconPath: 'assets/icons/privacy.svg',
  },
  {
    iconName: 'cmv-text',
    iconPath: 'assets/icons/text.svg',
  },
  {
    iconName: 'cmv-arrow-down',
    iconPath: 'assets/icons/arrow_down_icon.svg',
  },
  {
    iconName: 'cmv-add',
    iconPath: 'assets/icons/add_icon.svg',
  },
  {
    iconName: 'cmv-player',
    iconPath: 'assets/icons/player_icon.svg',
  },
  {
    iconName: 'cmv-player-team',
    iconPath: 'assets/icons/player_team.svg',
  },
  {
    iconName: 'cmv-camera',
    iconPath: 'assets/icons/videocamera_icon.svg',
  },
  {
    iconName: 'cmv-download',
    iconPath: 'assets/icons/download_icon.svg',
  },
  {
    iconName: 'cmv-share',
    iconPath: 'assets/icons/share_icon.svg',
  },
  {
    iconName: 'cmv-drag',
    iconPath: 'assets/icons/drag_icon.svg',
  },
  {
    iconName: 'cmv-filters',
    iconPath: 'assets/icons/filters_icon.svg',
  },
  {
    iconName: 'cmv-arrow-back',
    iconPath: 'assets/icons/arrow_back_icon.svg',
  },
  {
    iconName: 'cmv-search-left',
    iconPath: 'assets/icons/search_left_icon.svg',
  },
  {
    iconName: 'cmv-calendar',
    iconPath: 'assets/icons/calendar_icon.svg',
  },
  {
    iconName: 'cmv-stopwatch',
    iconPath: 'assets/icons/stopwatch.svg',
  },
  {
    iconName: 'cmv-oval',
    iconPath: 'assets/icons/oval_icon.svg',
  },
  {
    iconName: 'cmv-line',
    iconPath: 'assets/icons/line_icon.svg',
  },
  {
    iconName: 'cmv-undo',
    iconPath: 'assets/icons/undo.svg',
  },
  {
    iconName: 'cmv-send',
    iconPath: 'assets/icons/send-icon.svg',
  },
  {
    iconName: 'cmv-warning',
    iconPath: 'assets/icons/warning.svg',
  },
  {
    iconName: 'cmv-loading',
    iconPath: 'assets/icons/loading.svg',
  },
  {
    iconName: 'cmv-retry',
    iconPath: 'assets/icons/retry.svg',
  },
  {
    iconName: 'cmv-recordings',
    iconPath: 'assets/icons/recordings.svg',
  },
  {
    iconName: 'cmv-tag',
    iconPath: 'assets/icons/tag.svg',
  },
  {
    iconName: 'cmv-logout',
    iconPath: 'assets/icons/logout.svg',
  },
  {
    iconName: 'cmv-trash',
    iconPath: 'assets/icons/trash.svg',
  },
  {
    iconName: 'cmv-restore',
    iconPath: 'assets/icons/restore.svg',
  },
  {
    iconName: 'cmv-recycle',
    iconPath: 'assets/icons/recycle.svg',
  },
  {
    iconName: 'cmv-field',
    iconPath: 'assets/icons/field.svg',
  },
  {
    iconName: 'cmv-multiselect',
    iconPath: 'assets/icons/multiselect.svg',
  },
  {
    iconName: 'cmv-multiselect-cancel',
    iconPath: 'assets/icons/multiselect-cancel.svg',
  },
  {
    iconName: 'cmv-filter',
    iconPath: 'assets/icons/filter.svg',
  },
  {
    iconName: 'cmv-filter-cancel',
    iconPath: 'assets/icons/filter-cancel.svg',
  },
];

export enum RoutePath {
  Landing = 'landing',
  Platform = 'platform',
  LiveMatch = 'live-match',
  PlayedMatch = 'played-match',
  Playlists = 'playlists',
  Clip = 'clip',
  More = 'more',
  Error404 = 'error404',
  Privacy = 'privacy',
  Terms = 'terms',
  TagPanel = 'tag-panel',
  Recordings = 'recordings',
  Create = 'create',
  Edit = 'edit',
  Filter = 'filter',
  HowItWorks = 'how-it-works',
  TermsOfUse = 'terms-of-use',
  PrivacyAndCookiePolicy = 'privacy-and-cookie-policy',
  Trash = 'trash',
  Changelog = 'changelog',
}

export enum QueryParamNames {
  RecordingId = 'recordingId',
  PlaylistId = 'playlistId',
  AnnotationId = 'annotationId',
  Trimming = 'trimming',
}

export const POLLABLE_ROUTES = {
  playedMatch: `${RoutePath.Platform}/${RoutePath.Recordings}/${RoutePath.PlayedMatch}`,
  liveMatch: `${RoutePath.Platform}/${RoutePath.Recordings}/${RoutePath.LiveMatch}`,
  videoDetailPlaylist: `${RoutePath.Platform}/${RoutePath.Playlists}/${RoutePath.Clip}`,
};

/* eslint-disable no-magic-numbers */
export const PANORIS_ERROR_CODES: number[] = [
  403, 423, 1000, 1001, 1002, 1003, 1004, 1005, 1006, 1007, 1008, 1009, 1010,
  1011, 1012, 1013, 1014, 1015, 1016, 1017, 1018, 1022, 1023,
];
export const SHARE_RECORDING_ERROR_CODES: number[] = [403, 404, 420, 432, 433];
export const SpeedRateOptions = [0.25, 0.5, 0.75, 1, 2, 4];
/* eslint-enable no-magic-numbers */

export const HTTP_STATUS_CODE_REMOVE_RECORDING_WITH_COMPETITION = 420;
export const HTTP_STATUS_CODE_RECORDER_NO_SPACE = 421;

export const MATCH_DAY_FORMAT = 'YYYY-MM-DD';
// prettier-ignore
export const BASE_URL_REGEX = new RegExp(
  `^.*?(?=$|/(?:$|#|\\?|index\.html|${RoutePath.Landing}|${
    RoutePath.Platform
    }))`,
);
export const RECORDINGS_POLLING_INTERVAL = 120000;
export const ANNOTATIONS_POLLING_INTERVAL = 5000;
export const USER_ACCEPT_TOS = '2018-12-18T00:00:00+00:00';

export const TAGS_PER_PAGE = 6;
export const TAGS_PER_PAGE_VIDEO_TAGGING = 12;

export const TRIMPANEL_EXPAND = 180;

/*Ping-Pong message interval in seconds*/
export const PING_PONG_INTERVAL = 545;

export const MAX_TIMESTAMP = 99999999999;

export const DEFAULT_LANG = 'en';

export const VALID_EDITABLE_ELEMENTS = ['TEXTAREA', 'INPUT'];
export const TAGGING_NUMBERS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export const UNTIL_DEFAULT_DELAY = 500;
export const API_SERVICE_UNTIL_DELAY = 1500;
export const API_SERVICE_UNTIL_MAX_TRY = 5;
export const CHANGE_CURRENT_VIDEO_DURATION_IOS_DELAY = 750;
export const WEBSOCKET_RECONNECT_DELAY = 1500;
export const WS_STATUS_RESTORED = 'RESTORED';

export enum WSMessages {
  PONG = 'pong',
  CREATED = 'created',
  REFRESH = 'refresh',
  DELETED = 'deleted',
  CHANGE_SHARING = 'changeSharing',
}

export enum WSEntities {
  PLAYLIST = 'playlist',
  ANNOTATION = 'annotation',
  ANNOTATION_LIST = 'annotationList',
  RECORDING = 'recording',
  RECORDINGS = 'recordings',
  REMUX = 'remuxRecording',
  RECORDING_STATE = 'recordingState',
  TRASH = 'trash',
  THUMBNAIL = 'thumbnail',
}

export enum WSStatus {
  NONE = 'NONE',
  DEFAULT = 'DEFAULT',
  RENDERING = 'RENDERING',
  ZIPPING = 'ZIPPING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export enum VideoStatus {
  NONE = 'NONE',
  REMUXING = 'REMUXING',
  DONE = 'DONE',
  FAILED = 'FAILED',
}

export const MatchList = {
  RECORDED: 0,
  UPCOMING: 1,
};

export const TagTabList = {
  ADD_TAGS: 0,
  CREATED_TAGS: 1,
};

export const BREAKPOINT_L = 667;
export const BREAKPOINT_XL = 1025;
export const BREAKPOINT_M = 601;
export const BREAKPOINT_S = 376;

export enum GoogleAnalyticsEvent {
  // Authentication
  LOGIN = 'login',
  LOGOUT = 'logout',

  // Recording
  RECORDING_CREATE = 'recording_create',
  RECORDING_EDIT = 'recording_edit',
  RECORDING_REMOVE = 'recording_remove',
  RECORDING_MOVE_TO_TRASH = 'recording_move_to_trash',
  RECORDING_RESTORE = 'recording_restore',
  RECORDING_STOP = 'recording_stop',
  RECORDING_ADD_SHARE = 'recording_add_share',
  RECORDING_REMOVE_SHARE = 'recording_remove_share',

  // Video
  REMUX_VIDEO = 'remux_video',
  DOWNLOAD_VIDEO = 'download_video',
  VIDEO_PLAY = 'video_play',
  VIDEO_PAUSE = 'video_pause',
  FULLSCREEN = 'fullscreen',
  CHANGE_VIDEO_SOURCE = 'change_video_source',
  CHANGE_VIDEO_SPEED = 'change_video_speed',
  CHANGE_VIDEO_VOLUME = 'change_video_volume',
  VIDEO_SEEK = 'video_seek',
  OPEN_DRAWING = 'open_drawing',

  // Tag
  CREATE_TAG = 'create_tag',
  EDIT_TAG = 'edit_tag',
  SHARE_WITH_USER = 'share_with_user',
  SHARE_WITH_TEAM = 'share_with_team',
  REMOVE_SHARE_WITH_USER = 'remove_share_with_user',
  REMOVE_SHARE_WITH_TEAM = 'remove_share_with_team',
  TRIM_TAG = 'trim_tag',
  REMOVE_TAG = 'remove_tag',
  DOWNLOAD_TAG = 'download_tag',
  DOWNLOAD_TAGS = 'download_tags',

  // Tag panel
  TAG_PANEL_MOVE = 'tag_panel_move',
  TAG_PANEL_ADD = 'tag_panel_add',
  TAG_PANEL_EDIT = 'tag_panel_edit',
  TAG_PANEL_DELETE = 'tag_panel_delete',

  // Playlist
  PLAYLIST_CREATE = 'playlist_create',
  PLAYLIST_EDIT = 'playlist_edit',
  PLAYLIST_DELETE = 'playlist_delete',
  PLAYLIST_DOWNLOAD = 'playlist_download',
  ADD_TO_PLAYLIST = 'add_to_playlist',
  REMOVE_FROM_PLAYLIST = 'remove_from_playlist',

  // Others
  CHANGE_RECORDINGS_TAB = 'change_recordings_tab',
  TOGGLE_MENU = 'toggle_menu',
  MORE_MENU = 'more_menu',
  FILTER = 'filter',
  INSTALL_PWA = 'install_pwa',
}
