import { MetaRecording, Recording } from '../../../api/models';
import { ErrorModel } from '../../shared/models/app.state';
import { RecordingListQuery } from '../../shared/models/recording-list-query.model';
import { createAction, props } from '@ngrx/store';

export const getMoreRecordingsRequestAction = createAction(
  'GetMoreRecordingsRequest',
);
export const getMoreRecordingsSuccessAction = createAction(
  'GetMoreRecordingsSuccess',
  props<{ recordings: Recording[] }>(),
);
export const getMoreRecordingsFailureAction = createAction(
  'GetMoreRecordingsFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const refreshRecordingsRequestAction = createAction(
  'RefreshRecordingsRequest',
);
export const refreshRecordingsSuccessAction = createAction(
  'RefreshRecordingsSuccess',
  props<{ recordings: Recording[] }>(),
);
export const refreshRecordingsFailureAction = createAction(
  'RefreshRecordingsFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const getRecordingsMetaRequestAction = createAction(
  'GetRecordingsMetaRequest',
  props<{ query: RecordingListQuery }>(),
);
export const getRecordingsMetaSuccessAction = createAction(
  'GetRecordingsMetaSuccess',
  props<{ metaRecording: MetaRecording[] }>(),
);
export const getRecordingsMetaFailureAction = createAction(
  'GetRecordingsMetaFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const refreshSingleRecordingRequestAction = createAction(
  'RefreshSingleRecordingRequestAction',
  props<{ id: string; nextPingPong: number; refreshMetadata: boolean }>(),
);
export const refreshSingleRecordingSuccessAction = createAction(
  'RefreshSingleRecordingSuccessAction',
  props<{ recording: Recording }>(),
);
export const refreshSingleRecordingFailureAction = createAction(
  'RefreshSingleRecordingFailureAction',
  props<{ errors: ErrorModel[] }>(),
);

export const refreshRecordingsMetadataRequestAction = createAction(
  'RefreshRecordingsMetadata',
  props<{ fromDateTime: number }>(),
);
