import { Actions, createEffect, ofType } from '@ngrx/effects';
import { APIClient } from 'src/api';
import { ApiService } from 'src/app/core/services/api.service';
import {
  emptyTrashFailureAction,
  emptyTrashRequestAction,
  emptyTrashSuccessAction,
  moveToTrashFailureAction,
  moveToTrashRequestAction,
  moveToTrashSuccessAction,
  refreshTrashRequestAction,
  refreshTrashSuccessAction,
  restoreFromTrashFailureAction,
  restoreFromTrashRequestAction,
  restoreFromTrashSuccessAction,
} from '../actions/trash.actions';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { deleteRecordingSuccessAction } from '../actions/recording.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import { showSnackbarAction } from '../actions/snackbar.actions';
import {
  GoogleAnalyticsEvent,
  HTTP_STATUS_CODE_RECORDER_NO_SPACE,
  HTTP_STATUS_CODE_REMOVE_RECORDING_WITH_COMPETITION,
} from 'src/app/app.constants';
import { reportToGA } from 'src/app/app.utils';

@Injectable()
export class TrashEffects {
  refreshTrash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshTrashRequestAction),
      switchMap(() =>
        this.apiService
          .call(() => this.apiClient.getTrash())
          .pipe(
            map(recordings => refreshTrashSuccessAction({ recordings })),
            catchError(error => {
              const parsedError = {
                type: error.status.toString(),
                message: error.error.msg,
              };
              return of(
                moveToTrashFailureAction({
                  errors: [parsedError],
                }),
                handleErrorResponseAction({
                  errorType: parsedError.type,
                }),
              );
            }),
          ),
      ),
    ),
  );

  moveToTrash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(moveToTrashRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.postTrashId({ id }))
          .pipe(
            switchMap(recording =>
              of(
                deleteRecordingSuccessAction({ id: recording.id }),
                moveToTrashSuccessAction({ recording }),
                showSnackbarAction({
                  infoMessage: 'success.trash.moveToTrash',
                  icon: 'checkmark',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.RECORDING_MOVE_TO_TRASH);
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                moveToTrashFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  infoMessage: `error.trash.moveToTrash.${
                    error.status ===
                    HTTP_STATUS_CODE_REMOVE_RECORDING_WITH_COMPETITION
                      ? HTTP_STATUS_CODE_REMOVE_RECORDING_WITH_COMPETITION.toString()
                      : 'default'
                  }`,
                  icon: 'closing',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  restoreFromTrash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(restoreFromTrashRequestAction),
      switchMap(({ id }) =>
        this.apiService
          .call(() => this.apiClient.postTrashIdRestore({ id }))
          .pipe(
            switchMap(recording =>
              of(
                restoreFromTrashSuccessAction({ recording }),
                showSnackbarAction({
                  infoMessage: 'success.trash.restoreFromTrash',
                  icon: 'checkmark',
                }),
              ),
            ),
            tap(() => {
              reportToGA(GoogleAnalyticsEvent.RECORDING_RESTORE);
            }),
            catchError((error: HttpErrorResponse) =>
              of(
                restoreFromTrashFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  infoMessage:
                    error.status === HTTP_STATUS_CODE_RECORDER_NO_SPACE
                      ? 'error.trash.notEnoughtSpace'
                      : 'error.trash.restoreFromTrash',
                  icon: 'closing',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  emptyTrash$ = createEffect(() =>
    this.actions$.pipe(
      ofType(emptyTrashRequestAction),
      switchMap(() =>
        this.apiService
          .call(() => this.apiClient.deleteTrash())
          .pipe(
            switchMap(() =>
              of(
                emptyTrashSuccessAction(),
                showSnackbarAction({
                  infoMessage: 'success.trash.emptyTrash',
                  icon: 'checkmark',
                }),
              ),
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                emptyTrashFailureAction({
                  errors: [
                    {
                      type: error.status.toString(),
                      message: error.error.msg,
                    },
                  ],
                }),
                showSnackbarAction({
                  infoMessage: 'error.trash.emptyTrash',
                  icon: 'closing',
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
  ) {}
}
