<div class="cmv-checkbox-container" (click)="toggleCheckbox()">
  @if (label?.length > 0) {
    <div class="cmv-h2 checkbox-label">{{ label }}</div>
  }
  <div class="checkbox">
    @if (checked) {
      <mat-icon class="cmv-dim-gray-icon" svgIcon="cmv-checkmark"></mat-icon>
    }
  </div>
</div>
