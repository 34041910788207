import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AppState } from '../../models/app.state';
import { select, Store } from '@ngrx/store';
import {
  changeSharingMethodRequestAction,
  toggleTreeExpandedAction,
  toggleUserRequestAction,
} from '../../../store/actions/sharing.actions';
import {
  $isSharingLoaded,
  $treeExists,
} from '../../../store/selectors/sharing.selectors';
import {
  SharingExpandIndex,
  SharingTeamModel,
  SharingTreeUserModel,
} from '../../models/sharing.model';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatRipple } from '@angular/material/core';
import { LoadingSpinnerComponent } from '../../components/loading-spinner/loading-spinner.component';
import { TranslateModule } from '@ngx-translate/core';

export enum SharingTypes {
  TEAM = 'team',
  SINGLE = 'single',
}

@Component({
  selector: 'cmv-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    MatIcon,
    MatRipple,
    LoadingSpinnerComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class TreeComponent {
  @Input() data: { tree: SharingTeamModel[]; expanded: SharingExpandIndex[] };

  readonly isSharingLoaded$ = this.store.pipe(select($isSharingLoaded));
  readonly $treeExists = this.store.pipe(select($treeExists));

  sharingTypes = SharingTypes;

  constructor(private readonly store: Store<AppState>) {}

  toggleExpanded(teamId: string): void {
    this.store.dispatch(toggleTreeExpandedAction({ teamId }));
  }

  changeToTeamSharing(team: SharingTeamModel): void {
    if (team.sharingMode === SharingTypes.SINGLE) {
      this.store.dispatch(
        changeSharingMethodRequestAction({
          team,
          sharingType: SharingTypes.TEAM,
        }),
      );
    }
  }

  changeToSingleSharing(team: SharingTeamModel): void {
    if (team.sharingMode === SharingTypes.TEAM) {
      this.store.dispatch(
        changeSharingMethodRequestAction({
          team,
          sharingType: SharingTypes.SINGLE,
        }),
      );
    }
  }

  toggleUser(user: SharingTreeUserModel): void {
    this.store.dispatch(toggleUserRequestAction({ user }));
  }
}
