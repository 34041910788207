import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { APIClient } from '../../../api';
import { LogEvent } from '../../../api/models';
import {
  loginSuccessAction,
  logoutAction,
  logoutOfPlatformAction,
} from '../actions/auth.actions';
import { catchError, switchMap } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators/map';
import { of } from 'rxjs/internal/observable/of';
import { logVideoDownloadAction } from '../actions/log.actions';
import { ApiService } from 'src/app/core/services/api.service';

@Injectable()
export class LogEffects {
  logLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccessAction),
        switchMap(() =>
          this.apiService
            .call(() =>
              this.apiClient.postLog({ body: { event: LogEvent.LOGIN } }),
            )
            .pipe(
              catchError(() => {
                console.error('Cannot post log');
                return EMPTY;
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  logLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      switchMap(() =>
        this.apiService
          .call(() =>
            this.apiClient.postLog({ body: { event: LogEvent.LOGOUT } }),
          )
          .pipe(
            map(() => logoutOfPlatformAction()),
            catchError(() => {
              console.error('Cannot post log');
              return of(logoutOfPlatformAction());
            }),
          ),
      ),
    ),
  );

  logDownload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logVideoDownloadAction),
        switchMap(({ videoId, recordingId }) =>
          this.apiService
            .call(() =>
              this.apiClient.postLog({
                body: { event: LogEvent.VIDEO_DOWNLOAD, videoId, recordingId },
              }),
            )
            .pipe(
              catchError(() => {
                console.error('Cannot post log');
                return EMPTY;
              }),
            ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly apiService: ApiService,
  ) {}
}
