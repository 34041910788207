<div class="landing-page">
  <div class="landing-top">
    <div class="landing-titles-wrapper">
      <div class="logo">
        <div class="landing-logo-image"></div>
      </div>
      <div class="cmv-paragraph subtitle">
        {{ 'components.landingPage.default.subtitle' | translate }}
      </div>
      <div
        class="cmv-accept-button btn-desktop button-login cmv-button-with-spinner"
        (click)="logIn()"
      >
        <div class="cmv-h4">
          {{ 'components.landingPage.login' | translate }}
        </div>

        @if (authLoading$ | async) {
          <mat-spinner class="button-spinner"></mat-spinner>
        }
      </div>
    </div>
    <div class="landing-image-wrapper">
      <img src="assets/images/mockup_product_page.png" class="landing-image" />
    </div>
  </div>

  <div class="landing-content-wrapper">
    <div class="landing-content">
      <div class="landing-question">
        <div class="cmv-h1 question-title">
          {{ 'components.landingPage.gotQuestion' | translate }}
        </div>
        <div class="cmv-paragraph question-paragraph">
          {{ 'components.landingPage.sendMail' | translate }}
          {{ 'components.landingPage.mail' | translate }}
        </div>
        <a
          href="mailto:info@panoris.com"
          class="cmv-accept-button btn-desktop question-button"
        >
          <span class="cmv-h4">
            {{ 'components.landingPage.sendMailButton' | translate }}
          </span>
        </a>
      </div>
    </div>
  </div>

  <div class="landing-empty-space"></div>

  <div class="cmv-body landing-footer">
    <a class="first-footer-link" [routerLink]="'/terms'">
      {{ 'components.landingPage.termsOfUse' | translate }}
    </a>
    <a [routerLink]="'/privacy'">
      {{ 'components.landingPage.privacy' | translate }}
    </a>
  </div>
</div>
