import { createSelector } from '@ngrx/store';
import moment from 'moment';
import { Recording, RecordingStatus } from '../../../api/models';
import { AppState, RecordingListState } from '../../shared/models/app.state';
import { ManualSchedulingModel } from '../../shared/models/manual-scheduling.model';
import {
  getTomorrowDatetime,
  getRecordingDatetime,
  dateKey,
  groupRecordingsByKey,
  sortCurrentMatchDay,
} from 'src/app/app.utils';

export const $recordingList = (state: AppState) => state.recordingList;

export const $recordingListEntities = createSelector(
  $recordingList,
  (state: RecordingListState): Recording[] =>
    state.entities ? Object.values(state.entities) : [],
);

export const $recordingsLoaded = createSelector(
  $recordingList,
  (state: RecordingListState) => state.loaded,
);

export const $recordingsLoading = createSelector(
  $recordingList,
  state => state.loading,
);

export const $recordingsUpdating = createSelector(
  $recordingList,
  (state: RecordingListState) => state.loaded && state.loading,
);

export const $recordingsMetadata = createSelector(
  $recordingList,
  state => state.metadata!,
);

export const $moreMatchesToLoad = createSelector(
  $recordingsMetadata,
  metadata =>
    moment
      .unix(metadata.from)
      .isAfter(metadata.days[metadata.days.length - 1]?.day, 'day'),
);

export const $recordingAsFormInput = (recordingId: string) =>
  createSelector(
    $recordingList,
    (state: RecordingListState): ManualSchedulingModel | undefined => {
      if (!state.entities[recordingId]) {
        return undefined;
      }

      const {
        id,
        dateTime,
        duration,
        manualExports,
        recorderId,
        type,
        teamHome,
        teamAway,
        name,
      } = state.entities[recordingId];

      return {
        id,
        date: dateTime,
        duration,
        manualExports,
        recorderId,
        type,
        team1: teamHome
          ? { teamId: teamHome.teamId, clubId: teamHome.clubId }
          : undefined,
        team2: teamAway
          ? { teamId: teamAway.teamId, clubId: teamAway.clubId }
          : undefined,
        title: name,
      };
    },
  );

export const $liveRecordingsCount = createSelector(
  $recordingListEntities,
  (recordings: Recording[]) =>
    recordings.filter(
      (recording: Recording) => recording.status === RecordingStatus.LIVE,
    ).length,
);

const $matchesSelectorFactory = (
  nonPremiumOnly: boolean,
  current = false,
  upcoming = false,
) =>
  createSelector($recordingListEntities, (state: Recording[]) => {
    const tommorow = getTomorrowDatetime();

    return state !== null
      ? state
          .map(recording => ({ ...recording, show: recording.show || false }))
          .filter(recording => recording.show === nonPremiumOnly)
          .filter(recording =>
            current ? getRecordingDatetime(recording).isBefore(tommorow) : true,
          )
          .filter(recording =>
            upcoming
              ? getRecordingDatetime(recording).isSameOrAfter(tommorow)
              : true,
          )
      : [];
  });

const $matchDaysFactory = (
  nonPremiumOnly: boolean,
  current = false,
  upcoming = false,
) =>
  createSelector(
    $matchesSelectorFactory(nonPremiumOnly, current, upcoming),
    recordings =>
      groupRecordingsByKey(recordings, recording =>
        dateKey(recording.dateTime),
      ),
  );

export const $premiumMatches = $matchesSelectorFactory(false);
export const $premiumMatchesExist = createSelector(
  $matchDaysFactory(false),
  matchDays => matchDays.length,
);
export const $matchDays = createSelector($matchDaysFactory(true), matchDay =>
  Object.keys(matchDay)
    .sort((a, b) => b.localeCompare(a))
    .map(key => matchDay[key]),
);

export const $nearestPremiumMatchDate = createSelector(
  $premiumMatches,
  (state: Recording[]) =>
    moment.max(state.map(recording => moment.unix(recording.dateTime))).unix(),
);

export const $currentOnlyRecordingDays = createSelector(
  $matchDaysFactory(true, true),
  matchDay => sortCurrentMatchDay(matchDay),
);

export const $upcomingOnlyRecordingDays = createSelector(
  $matchDaysFactory(true, false, true),
  matchDay =>
    Object.keys(matchDay)
      .sort((a, b) => a.localeCompare(b))
      .map(key => matchDay[key].sort((a, b) => a.dateTime - b.dateTime)),
);
