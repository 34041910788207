import { TeamModel } from '../../shared/models/manual-scheduling.model';
import {
  INITIAL_ENTITY_STATE,
  StateStatus,
  TeamsState,
} from '../../shared/models/app.state';
import {
  getTeamsFailureAction,
  getTeamsRequestAction,
  getTeamsSuccessAction,
} from '../actions/teams.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

export const teamsAdapter = createEntityAdapter<TeamModel>();

const initialState: TeamsState = {
  ...INITIAL_ENTITY_STATE,
  alreadyLoadedIds: [],
};

const reducer = createReducer(
  initialState,
  on(getTeamsRequestAction, state => ({
    ...state,
    status: StateStatus.UPDATING,
  })),
  on(getTeamsSuccessAction, (state, { data, clubId }) => ({
    ...state,
    ...teamsAdapter.addMany(data, state),
    status: StateStatus.LOADED,
    alreadyLoadedIds: [...state.alreadyLoadedIds, clubId],
    errors: [],
  })),
  on(getTeamsFailureAction, (state, { errors }) => ({
    ...state,
    errors,
  })),
);

export function teamsReducer(state: TeamsState, action: Action): TeamsState {
  return reducer(state, action);
}
