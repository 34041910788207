import { createSelector } from '@ngrx/store';
import {
  POLLABLE_ROUTES,
  QueryParamNames,
  RoutePath,
} from '../../app.constants';
import { AppState } from '../../shared/models/app.state';

export const $route = (state: AppState) => state.route;

export const $isTrimming = createSelector(
  $route,
  route => route.params.trimming === 'true',
);

export const $path = createSelector($route, ({ path }) => path);

export const $queryParameters = createSelector($route, ({ params }) => params);

export const $pathSegments = createSelector(
  $route,
  ({ pathSegments }) => pathSegments,
);

export const $sectionRoute = createSelector(
  $pathSegments,
  ([, section]) => section || null,
);

export const $isPlaylistRoute = createSelector(
  $sectionRoute,
  section => section === RoutePath.Playlists,
);

export const $subSectionRoute = createSelector(
  $pathSegments,
  ([, , subsection]) => subsection || null,
);

export const $queryParameterByName = (name: string) =>
  createSelector($queryParameters, parameters => parameters[name] || null);

export const $version = createSelector(
  $route,
  route => route.params.version === 'true',
);

export const $currentRecordingId = createSelector(
  $route,
  route => route.params[QueryParamNames.RecordingId],
);

export const $isSubscribableRoute = createSelector(
  $route,
  route =>
    Object.values(POLLABLE_ROUTES).includes(route.path) &&
    Object.values(route.params).length,
);

export const $routeWithPlayer = createSelector($route, route =>
  Object.values(POLLABLE_ROUTES).includes(route.path),
);
