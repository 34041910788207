import { createAction, props } from '@ngrx/store';
import { Annotation, Playlist } from '../../../api/models';
import { ErrorModel } from '../../shared/models/app.state';

export const getPlaylistListRequestAction = createAction(
  'GetPlaylistListRequest',
);
export const getPlaylistListSuccessAction = createAction(
  'GetPlaylistListSuccess',
  props<{ playlists: Playlist[] }>(),
);
export const getPlaylistListFailureAction = createAction(
  'GetPlaylistListFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const postPlaylistRequestAction = createAction(
  'PostPlaylistRequest',
  props<{ playlist: Partial<Playlist> }>(),
);
export const postPlaylistSuccessAction = createAction(
  'PostPlaylistSuccess',
  props<{ playlist: Playlist }>(),
);
export const postPlaylistFailureAction = createAction(
  'PostPlaylistFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const putPlaylistRequestAction = createAction(
  'PutPlaylistRequest',
  props<{ playlist: Playlist }>(),
);
export const putPlaylistSuccessAction = createAction(
  'PutPlaylistSuccess',
  props<{ playlist: Playlist }>(),
);
export const putPlaylistFailureAction = createAction(
  'PutPlaylistFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const editAnnotationNameInPlaylistRequestAction = createAction(
  'EditAnnotationNameInPlaylistRequest',
  props<{ playlistId: string; annotation: Annotation }>(),
);

export const editAnnotationTrimmingInPlaylistAction = createAction(
  'EditAnnotationTrimmingInPlaylist',
  props<{
    id: string;
    body: {
      id: string;
      name: string;
      from: number;
      to: number;
      timestamp: number;
    };
  }>(),
);

export const addToPlaylistRequestAction = createAction(
  'AddToPlaylistRequest',
  props<{
    annotationsIds: string[];
    playlistId: string;
    annotationName: string;
    playlistName: string;
    fromPlaylistId: string | undefined;
  }>(),
);
export const addToPlaylistSuccessAction = createAction(
  'AddToPlaylistSuccess',
  props<{ playlist: Playlist }>(),
);
export const addToPlaylistFailureAction = createAction(
  'AddToPlaylistFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const removeFromPlaylistRequestAction = createAction(
  'RemoveFromPlaylistRequest',
  props<{
    annotationsIds: string[];
    playlistId: string;
    playlistName: string;
    annotationName: string;
  }>(),
);
export const removeFromPlaylistSuccessAction = createAction(
  'RemoveFromPlaylistSuccess',
  props<{ playlist: Playlist }>(),
);
export const removeFromPlaylistFailureAction = createAction(
  'RemoveFromPlaylistFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const deletePlaylistRequestAction = createAction(
  'DeletePlaylistRequest',
  props<{ playlist: Playlist }>(),
);
export const deletePlaylistSuccessAction = createAction(
  'DeletePlaylistSuccess',
  props<{ id: string }>(),
);
export const deletePlaylistFailureAction = createAction(
  'DeletePlaylistFailure',
  props<{ errors: ErrorModel[] }>(),
);

export const renderPlaylistRequestAction = createAction(
  'RenderPlaylistRequest',
  props<{ id: string }>(),
);
export const renderPlaylistSuccessAction = createAction(
  'RenderPlaylistSuccess',
  props<{ playlist: Playlist }>(),
);
export const renderPlaylistFailureAction = createAction(
  'RenderPlaylistFailure',
);

export const refreshPlaylistRequestAction = createAction(
  'RefreshPlaylistRequest',
  props<{ id: string; showSnackbar: boolean; status: string }>(),
);
export const refreshPlaylistSuccessAction = createAction(
  'RefreshPlaylistSuccess',
  props<{ playlist: Playlist }>(),
);
export const refreshPlaylistFailureAction = createAction(
  'RefreshPlaylistFailure',
  props<{ errors: ErrorModel[] }>(),
);
export const deletedPlaylistRequestAction = createAction(
  'DeletedPlaylistRequestAction',
  props<{ id: string }>(),
);
export const createdPlaylistRequestAction = createAction(
  'CreatedPlaylistRequestAction',
  props<{ id: string }>(),
);
export const createdPlaylistSuccessAction = createAction(
  'CreatedPlaylistSuccessAction',
  props<{ playlist: Playlist }>(),
);
export const createdPlaylistFailureAction = createAction(
  'CreatedPlaylistFailureAction',
  props<{ errors: ErrorModel[] }>(),
);
export const refreshPlaylistsByRecordingIdAction = createAction(
  'RefreshPlaylistsByRecordingId',
  props<{ id: string }>(),
);
export const refreshPlaylistsByRecordingsIdsAction = createAction(
  'RefreshPlaylistsByRecordingsIds',
  props<{ ids: string[] }>(),
);
export const refreshPlaylistAnnotationThumbnailAction = createAction(
  'RefreshPlaylistAnnotationThumbnail',
  props<{ annotationId: string; playlistId: string; url: string }>(),
);
