<cmv-page-layout
  [title]="'components.manualScheduling.recordingEditTitle' | translate"
  [loadingResources]="(recordingsLoaded$ | async) === false"
>
  <div class="cmv-recording-form-grid">
    @let formInput = formInput$ | async;
    @if (formInput) {
      <cmv-manual-scheduling-form
        [data]="formInput"
      ></cmv-manual-scheduling-form>
    } @else {
      <div class="recording-not-found">
        <div class="cmv-h1 recording-not-found-title">
          {{ 'components.manualScheduling.notFountTitle' | translate }}
        </div>

        <div class="cmv-h3 recording-not-found-subtitle">
          {{ 'components.manualScheduling.notFountSubtitle' | translate }}
        </div>

        <div
          class="cmv-dashed-button empty-recording-button"
          (click)="createRecording()"
        >
          <div class="cmv-h4 text">
            {{ 'basic.createRecording' | translate }}
          </div>
          <mat-icon
            class="button-icon cmv-dim-gray"
            svgIcon="cmv-add"
          ></mat-icon>
        </div>
      </div>
    }
  </div>
</cmv-page-layout>
