import { WsSubscribeAs } from '../../shared/models/ws.models';
import { createAction, props } from '@ngrx/store';

export const wsPingPongTimeUpdateAction = createAction(
  'WsPingPongTimeUpdate',
  props<{ nextPingPong: number }>(),
);
export const wsSubscribeToAction = createAction(
  'WsSubscribeTo',
  props<{ id: string; subscribedAs: WsSubscribeAs; nextPingPong: number }>(),
);
export const wsUnsubscribeAction = createAction(
  'WsUnsubscribe',
  props<{ nextPingPong: number }>(),
);
