import { ResponsivityState } from '../../shared/models/app.state';
import { screenResizeAction } from '../actions/responsivity.actions';
import { Action, createReducer, on } from '@ngrx/store';

const initialState: ResponsivityState = {
  screenWidth: window.innerWidth,
};

const reducer = createReducer(
  initialState,
  on(screenResizeAction, (state, { screenWidth }) => ({
    ...state,
    screenWidth,
  })),
);

export function responsivityReducer(
  state: ResponsivityState,
  action: Action,
): ResponsivityState {
  return reducer(state, action);
}
