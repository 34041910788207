import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { Playlist } from '../../../api/models';
import {
  EditModalComponent,
  EditModalTypes,
} from '../../shared/components-old/modal/edit-modal/edit-modal.component';
import { AppState } from '../../shared/models/app.state';
import { EditModalModel } from '../../shared/models/edit-modal.model';
import { postPlaylistRequestAction } from '../../store/actions/playlist-list.actions';
import { $playlistCollectionViewLoading } from '../../store/selectors/loading-components.selectors';
import { $playlistEntities } from '../../store/selectors/playlist-list.selectors';
import { PageLayoutComponent } from '../../layouts/page-layout/page-layout.component';
import { AsyncPipe } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { PlaylistTitleComponent } from '../../features/playlists/components/playlist-title/playlist-title.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-playlists-collection-view',
  templateUrl: './playlists-collection-view.component.html',
  styleUrls: ['./playlists-collection-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    PageLayoutComponent,
    MatIcon,
    PlaylistTitleComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class PlaylistsCollectionViewComponent {
  playlists$: Observable<Playlist[]> = this.store.pipe(
    select($playlistEntities),
  );

  loadingResources$: Observable<boolean> = this.store.pipe(
    select($playlistCollectionViewLoading),
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
  ) {}

  createNewPlaylist(): void {
    const dialogInstance = this.dialog.open(EditModalComponent, {
      data: {
        title: '',
        type: EditModalTypes.PLAYLIST_CREATE,
      },
    });

    dialogInstance
      ?.afterClosed()
      .pipe(take(1))
      .subscribe((value: EditModalModel) => {
        if (value?.title.length > 0) {
          this.store.dispatch(
            postPlaylistRequestAction({ playlist: { name: value.title } }),
          );
        }
      });
  }
}
