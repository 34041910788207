import { WsState } from '../../shared/models/app.state';
import { getNextPingPongTime } from '../../app.utils';
import { pollAnnotationsAction } from '../actions/annotation.actions';
import {
  wsPingPongTimeUpdateAction,
  wsSubscribeToAction,
  wsUnsubscribeAction,
} from '../actions/ws.actions';
import { Action, createReducer, on } from '@ngrx/store';
import { refreshSingleRecordingRequestAction } from '../actions/recording-list.actions';

const initialState: WsState = {
  nextPingPong: getNextPingPongTime(),
  currentlySubscribed: undefined,
};

const reducer = createReducer(
  initialState,
  on(
    wsPingPongTimeUpdateAction,
    pollAnnotationsAction,
    refreshSingleRecordingRequestAction,
    (state, { nextPingPong }) => ({
      ...state,
      nextPingPong,
    }),
  ),
  on(wsSubscribeToAction, (state, { id, subscribedAs, nextPingPong }) => ({
    ...state,
    currentlySubscribed: {
      id,
      subscribedAs,
    },
    nextPingPong,
  })),
  on(wsUnsubscribeAction, (state, { nextPingPong }) => ({
    ...state,
    currentlySubscribed: undefined,
    nextPingPong,
  })),
);

export function wsReducer(state: WsState, action: Action): WsState {
  return reducer(state, action);
}
