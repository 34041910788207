import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ApiService } from 'src/app/core/services/api.service';
import { AppState } from 'src/app/shared/models/app.state';
import { APIClient } from '../../../api';
import {
  getClubsFailureAction,
  getClubsRequestAction,
  getClubsSuccessAction,
} from '../actions/clubs.actions';
import { handleErrorResponseAction } from '../actions/error.actions';
import { $clubsLoaded } from '../selectors/clubs.selectors';

@Injectable()
export class ClubsEffects {
  getClubs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getClubsRequestAction),
      switchMap(() => this.store.pipe(select($clubsLoaded))),
      filter(loaded => !loaded),
      switchMap(() => this.apiService.call(() => this.apiClient.getFeClub({}))),
      map(clubs => getClubsSuccessAction({ clubs })),
      catchError(error => {
        const parsedError = {
          type: error.status.toString(),
          message: error.error.msg,
        };

        return of(
          getClubsFailureAction({
            errors: [parsedError],
          }),
          handleErrorResponseAction({
            errorType: parsedError.type,
          }),
        );
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly apiClient: APIClient,
    private readonly store: Store<AppState>,
    private readonly apiService: ApiService,
  ) {}
}
