import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Annotation } from '../../../api/models';
import { QueryParamNames, RoutePath } from '../../app.constants';
import { AppState } from '../../shared/models/app.state';
import { FilterSearchModelType } from '../../shared/models/filter-search-model-type.enum';
import {
  deleteAnnotationsRequestAction,
  editAnnotationNameRequestAction,
} from '../../store/actions/annotation.actions';
import {
  $filterSearchNoResult,
  $filterSearchResult,
} from '../../store/selectors/filter-search.selectors';
import { $recordingSubroute } from 'src/app/store/selectors/recording.selectors';
import { firstValueFrom, take } from 'rxjs';
import { LiveTaggingModes } from 'src/app/shared/models/current-selections.model';
import { changeLiveTaggingModeAction } from 'src/app/store/actions/current-selections.actions';
import { SearchComponent } from '../../shared/components-old/search/search.component';
import { AsyncPipe } from '@angular/common';
import { MatchWidgetComponent } from '../../shared/components-old/match-widget/match-widget.component';
import { VideoWidgetComponent } from '../../shared/components-old/video-widget/video-widget.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-filter-result',
  templateUrl: './filter-results.component.html',
  styleUrls: ['./filter-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SearchComponent,
    MatchWidgetComponent,
    VideoWidgetComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class FilterResultsComponent {
  FilterSearchModelType = FilterSearchModelType;

  readonly filterResults$ = this.store.pipe(select($filterSearchResult));
  readonly noResults$ = this.store.pipe(select($filterSearchNoResult));

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
  ) {}

  editAnnotation(annotation: Annotation): void {
    this.store.dispatch(
      editAnnotationNameRequestAction({
        id: annotation.id,
        name: annotation.name,
        description: annotation.description,
      }),
    );
  }

  async redirectToTrimming(id: string, recordingId: string) {
    this.store.dispatch(
      changeLiveTaggingModeAction({ mode: LiveTaggingModes.STREAM }),
    );

    const subroute = await firstValueFrom(
      this.store.select($recordingSubroute(recordingId)).pipe(take(1)),
    );

    this.router.navigate([RoutePath.Platform, RoutePath.Recordings, subroute], {
      queryParams: {
        [QueryParamNames.Trimming]: true,
        [QueryParamNames.AnnotationId]: id,
        [QueryParamNames.RecordingId]: recordingId,
      },
    });
  }

  async redirectToAnnotation(id: string, recordingId: string) {
    this.store.dispatch(
      changeLiveTaggingModeAction({ mode: LiveTaggingModes.STREAM }),
    );

    const subroute = await firstValueFrom(
      this.store.select($recordingSubroute(recordingId)).pipe(take(1)),
    );

    this.router.navigate([RoutePath.Platform, RoutePath.Recordings, subroute], {
      queryParams: {
        [QueryParamNames.AnnotationId]: id,
        [QueryParamNames.RecordingId]: recordingId,
      },
    });
  }

  deleteTag(annotation: Annotation): void {
    this.store.dispatch(
      deleteAnnotationsRequestAction({ annotations: [annotation] }),
    );
  }
}
