import { LoginFailureModalComponent } from '../../shared/components-old/modal/login-failure-modal/login-failure-modal.component';
import { Injectable } from '@angular/core';
import {
  Actions,
  createEffect,
  ofType,
  ROOT_EFFECTS_INIT,
} from '@ngrx/effects';
import { switchMapTo, take, takeUntil, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { CognitoAuth, CognitoAuthSession } from 'amazon-cognito-auth-js';
import { AppState } from '../../shared/models/app.state';
import {
  handleLoginAction,
  loginAction,
  loginFailureAction,
  loginSuccessAction,
  logoutAction,
  logoutOfPlatformAction,
  refreshTokenAction,
} from '../actions/auth.actions';
import { Router } from '@angular/router';
import { $authProvider } from '../selectors/customer.selectors';
import { MatDialog } from '@angular/material/dialog';
import { NotAuthorizedModalComponent } from '../../shared/components-old/modal/not-authorized-modal/not-authorized-modal.component';
import { reportToGA } from 'src/app/app.utils';
import {
  BASE_URL_REGEX,
  GoogleAnalyticsEvent,
  RoutePath,
} from 'src/app/app.constants';
import { timer } from 'rxjs';
import { getUserRequestAction } from '../actions/user.actions';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/core/services/api.service';

const REF_TOKEN_EXP_KEY = 'refTokenExp';
const INIT_REFRESH_SUBSTRACT_SECONDS = 15; // eslint-disable-line no-magic-numbers
const EXPIRY_TIMER_HOURS = 24; // eslint-disable-line no-magic-numbers

@Injectable()
export class AuthEffect {
  initAuth$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        switchMapTo(this.store.pipe(select($authProvider))),
        take(1),
        tap(authProvider => {
          this.initAuth(authProvider);

          const refTokenExp = this.getRefreshTokenExpiration();
          if (refTokenExp.isBefore()) {
            this.store.dispatch(logoutOfPlatformAction());
          }
        }),
      ),
    { dispatch: false },
  );

  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginAction),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.LOGIN);
          this.auth.getSession();
        }),
      ),
    { dispatch: false },
  );

  handleLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(handleLoginAction),
        tap(() => {
          this.auth.parseCognitoWebResponse(window.location.href);
        }),
      ),
    { dispatch: false },
  );

  loginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginSuccessAction),
        tap(({ auth }) => {
          if (auth.userHasRights && auth.loginFromLanding) {
            this.router.navigate([RoutePath.Platform]);
          }

          if (auth.userHasRights) {
            this.store.dispatch(getUserRequestAction());

            timer(this.initTimeToRefresh.toDate())
              .pipe(
                takeUntil(this.actions$.pipe(ofType(logoutOfPlatformAction))),
              )
              .subscribe(() =>
                this.store.dispatch(
                  refreshTokenAction({ token: auth.refToken }),
                ),
              );

            const refTokenExp = this.getRefreshTokenExpiration().toDate();
            timer(refTokenExp)
              .pipe(
                takeUntil(this.actions$.pipe(ofType(logoutOfPlatformAction))),
              )
              .subscribe(() => {
                this.store.dispatch(logoutOfPlatformAction());
              });
          }

          if (!auth.userHasRights) {
            this.dialog
              .open(NotAuthorizedModalComponent)
              .afterClosed()
              .subscribe(() => {
                this.store.dispatch(logoutAction());
              });
          }
        }),
      ),
    { dispatch: false },
  );

  loginFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginFailureAction),
        tap(() => {
          this.dialog.open(LoginFailureModalComponent);
        }),
      ),
    { dispatch: false },
  );

  refreshToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(refreshTokenAction),
        tap(({ token }) => {
          this.auth.refreshSession(token);
        }),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(logoutOfPlatformAction),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.LOGOUT);
          sessionStorage.removeItem('recordersState');
          localStorage.removeItem(REF_TOKEN_EXP_KEY);
          this.auth.signOut();
        }),
      ),
    { dispatch: false },
  );

  private auth: CognitoAuth;
  private initTimeToRefresh: moment.Moment;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly apiService: ApiService,
  ) {}

  private initAuth(authProvider: string): void {
    const baseUrl = window.location.href.match(BASE_URL_REGEX)![0];
    this.auth = new CognitoAuth({
      ClientId: environment.cognito.clientId,
      AppWebDomain: environment.cognito.appWebDomain,
      TokenScopesArray: ['openid', 'email', 'profile'],
      RedirectUriSignIn: baseUrl,
      RedirectUriSignOut: baseUrl,
      IdentityProvider: authProvider,
      UserPoolId: environment.cognito.userPoolId,
      AdvancedSecurityDataCollectionFlag: false,
    });
    this.auth.useCodeGrantFlow();

    this.auth.userhandler = {
      onFailure: error => {
        const parsedError = JSON.parse(error);
        if (parsedError.error !== 'invalid_grant') {
          this.store.dispatch(
            loginFailureAction({
              errors: [
                {
                  type: parsedError.status
                    ? parsedError.status.toString()
                    : 'COGNITO_FAILURE',
                  message: parsedError.error,
                },
              ],
            }),
          );
        } else {
          this.store.dispatch(logoutOfPlatformAction());
          this.auth.launchUri(this.auth.getFQDNSignIn());
        }
      },
      onSuccess: authSession => {
        this.successLogin(authSession);
      },
    };

    const session = this.auth.getSignInUserSession();
    if (session.isValid()) {
      this.successLogin(session);
    }
  }

  private successLogin(session: CognitoAuthSession): void {
    const idToken = session.getIdToken().getJwtToken();
    const refToken = session.getRefreshToken().getToken();
    const decodedToken: any = session.getIdToken().decodePayload(); // eslint-disable-line
    this.initTimeToRefresh = moment
      .unix(decodedToken.exp)
      .subtract(INIT_REFRESH_SUBSTRACT_SECONDS, 'seconds');
    const userHasRights: boolean = 'hasRights' in decodedToken;
    const premium: boolean = 'premium' in decodedToken;

    this.setRefreshTokenExpiration();
    this.apiService.setTokenValidity(this.initTimeToRefresh);

    const loginFromLanding = this.router.isActive(RoutePath.Landing, false);

    this.store.dispatch(
      loginSuccessAction({
        auth: {
          idToken,
          refToken,
          userHasRights,
          loginFromLanding,
          premium,
        },
      }),
    );
  }

  private setRefreshTokenExpiration(): void {
    const expiryDate = window.localStorage.getItem(REF_TOKEN_EXP_KEY);
    if (!expiryDate || moment(expiryDate).isBefore()) {
      const tomorrow = moment().add(EXPIRY_TIMER_HOURS, 'hours'); // eslint-disable-line no-magic-numbers
      window.localStorage.setItem(REF_TOKEN_EXP_KEY, tomorrow.format());
    }
  }

  private getRefreshTokenExpiration(): moment.Moment {
    const refTokenExp = window.localStorage.getItem(REF_TOKEN_EXP_KEY);
    return refTokenExp
      ? moment(refTokenExp)
      : moment().add(EXPIRY_TIMER_HOURS, 'hours'); // eslint-disable-line no-magic-numbers
  }
}
