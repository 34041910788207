import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { iif, merge, Subject } from 'rxjs';
import { debounceTime, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SearchType } from '../../../../api/models';

import { AppState } from '../../models/app.state';
import { FILTER_FORM_CONTROLS } from '../../models/filter-form-controls.enum';
import { FilterSearchTagModel } from '../../models/filter-search-tag.model';
import {
  getFilterSearchTagsRequestAction,
  resetSearchingAction,
  setFilterSearchOption,
} from '../../../store/actions/filter-search.actions';
import {
  hideSearchFiltersUiAction,
  toggleSearchFiltersUiAction,
} from '../../../store/actions/ui-flags.actions';
import {
  $filterSearchOptions,
  $filterSearchTags,
} from '../../../store/selectors/filter-search.selectors';
import {
  $searchFiltersOverlayVisible,
  $searchFiltersVisible,
} from '../../../store/selectors/ui-flags.selectors';
import { $isLargeDevice } from '../../../store/selectors/responsivity.selectors';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatOption,
} from '@angular/material/core';
import { Languages } from '../../models/customer';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { NgClass, NgIf, NgFor, AsyncPipe } from '@angular/common';
import {
  MatDateRangeInput,
  MatStartDate,
  MatEndDate,
  MatDatepickerToggleIcon,
  MatDatepickerToggle,
  MatDateRangePicker,
} from '@angular/material/datepicker';
import { MatSelect } from '@angular/material/select';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
} from '@angular/material/expansion';
import {
  ALMOST_ONE_DAY_HOURS,
  ALMOST_ONE_HOUR_MINUTES,
  RANGE_DATE_FORMATS,
  TEMPLATE_NULL,
} from 'src/app/features/search/search.constants';

const SEARCH_DEBOUNCE = 500;

@Component({
  selector: 'cmv-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: RANGE_DATE_FORMATS },
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatIcon,
    MatFormField,
    MatInput,
    NgClass,
    NgIf,
    MatDateRangeInput,
    MatStartDate,
    MatEndDate,
    MatDatepickerToggleIcon,
    MatDatepickerToggle,
    MatSuffix,
    MatDateRangePicker,
    MatSelect,
    NgFor,
    MatOption,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SearchComponent implements OnDestroy {
  readonly unsubscribe$ = new Subject<void>();
  tagForm: FormGroup;
  readonly filterFormControls = FILTER_FORM_CONTROLS;
  readonly searchType = SearchType;
  readonly templateNull = TEMPLATE_NULL;

  readonly typeFormControl: AbstractControl<any, any> | null;
  readonly typeIdFormControl: AbstractControl<any, any> | null;
  readonly textFormControl: AbstractControl<any, any> | null;
  readonly fromFormControl: AbstractControl<any, any> | null;

  readonly isLargeDevice$ = this.store.pipe(select($isLargeDevice));
  readonly filterSearchTags$ = this.store.pipe(select($filterSearchTags));
  readonly filterSearchOptions$ = this.store.pipe(select($filterSearchOptions));
  readonly searchFiltersVisible$ = this.store.pipe(
    select($searchFiltersVisible),
  );
  readonly searchFiltersOverlayVisible$ = this.store.pipe(
    select($searchFiltersOverlayVisible),
  );

  readonly contentTypesArray = Object.values(SearchType);

  readonly minDate = moment().subtract(1, 'year');
  readonly maxDate = moment()
    .set('hours', ALMOST_ONE_DAY_HOURS)
    .set('minutes', ALMOST_ONE_HOUR_MINUTES);
  constructor(
    private readonly store: Store<AppState>,
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService,
    private readonly dateAdapter: DateAdapter<Date>,
  ) {
    this.dateAdapter.setLocale(Languages.ENGLISH);
    this.store.dispatch(getFilterSearchTagsRequestAction());

    this.tagForm = this.fb.group({
      [FILTER_FORM_CONTROLS.TEXT]: null,
      [FILTER_FORM_CONTROLS.FROM]: null,
      [FILTER_FORM_CONTROLS.TO]: null,
      [FILTER_FORM_CONTROLS.TYPE]: SearchType.BOTH,
      [FILTER_FORM_CONTROLS.TYPE_ID]: TEMPLATE_NULL,
    });

    this.typeFormControl = this.tagForm.get(FILTER_FORM_CONTROLS.TYPE);
    this.typeIdFormControl = this.tagForm.get(FILTER_FORM_CONTROLS.TYPE_ID);
    this.textFormControl = this.tagForm.get(FILTER_FORM_CONTROLS.TEXT);
    this.fromFormControl = this.tagForm.get(FILTER_FORM_CONTROLS.FROM);

    this.filterSearchOptions$.pipe(take(1)).subscribe(form => {
      this.tagForm.setValue(
        {
          [FILTER_FORM_CONTROLS.TEXT]: form.text,
          [FILTER_FORM_CONTROLS.FROM]: form.from,
          [FILTER_FORM_CONTROLS.TO]: form.to,
          [FILTER_FORM_CONTROLS.TYPE]: form.type || SearchType.BOTH,
          [FILTER_FORM_CONTROLS.TYPE_ID]: form.typeId || TEMPLATE_NULL,
        },
        { emitEvent: false },
      );
    });

    this.typeFormControl!.valueChanges.subscribe(currentType => {
      if (currentType === SearchType.RECORDINGS) {
        if (this.typeIdFormControl!.value !== TEMPLATE_NULL) {
          this.typeIdFormControl!.setValue(TEMPLATE_NULL);
        }
      }
    });

    this.typeIdFormControl!.valueChanges.subscribe(typeId => {
      if (typeId !== TEMPLATE_NULL) {
        this.typeFormControl!.setValue(SearchType.ANNOTATIONS);
      }
    });

    this.isLargeDevice$
      .pipe(
        switchMap(isLarge =>
          iif(
            () => isLarge,
            merge(
              this.tagForm.valueChanges.pipe(map(values => ({ ...values }))),
              this.typeIdFormControl!.valueChanges.pipe(
                map(typeId =>
                  typeId && typeId !== TEMPLATE_NULL
                    ? { typeId, type: SearchType.ANNOTATIONS }
                    : { typeId },
                ),
              ),
            ),

            this.textFormControl!.valueChanges.pipe(map(text => ({ text }))),
          ),
        ),
        takeUntil(this.unsubscribe$),
        debounceTime(SEARCH_DEBOUNCE),
      )
      .subscribe((options: any) => {
        this.store.dispatch(
          setFilterSearchOption({
            options,
          }),
        );
      });
  }

  clearDate(event: MouseEvent) {
    this.tagForm.patchValue({
      [FILTER_FORM_CONTROLS.FROM]: null,
      [FILTER_FORM_CONTROLS.TO]: null,
    });
    event.stopPropagation();
  }

  toggleFilters(): void {
    this.store.dispatch(toggleSearchFiltersUiAction());
  }

  updateFilters(): void {
    this.store.dispatch(
      setFilterSearchOption({
        options: { ...this.tagForm.value },
      }),
    );
    this.store.dispatch(toggleSearchFiltersUiAction());
  }

  resetFilters(): void {
    this.store.dispatch(resetSearchingAction());
  }

  patchContentType(type: SearchType): void {
    this.typeFormControl!.patchValue(type);
  }

  get selectedContentType(): string {
    return this.typeFormControl!.value || 'Content';
  }

  patchTag(tagId: string): void {
    this.typeIdFormControl!.patchValue(tagId);
  }

  selectedTag(tags: FilterSearchTagModel[]): string {
    const tag = tags.find(x => this.typeIdFormControl!.value === x.id);
    return tag ? tag.name : this.translate.instant('components.search.all');
  }

  hideSearchFilter(): void {
    this.store.dispatch(hideSearchFiltersUiAction());
  }

  tagsTrackBy(_: number, value: FilterSearchTagModel): string {
    return value.id;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
