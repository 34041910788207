import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  adjustVideoVolumeAction,
  fullscreenVideoAction,
  pauseVideoAction,
  playVideoAction,
  scrubInVideoAction,
} from '../actions/video.actions';
import { tap } from 'rxjs/operators';
import { reportToGA } from '../../app.utils';
import { GoogleAnalyticsEvent } from 'src/app/app.constants';

@Injectable()
export class VideoEffects {
  playVideo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(playVideoAction),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.VIDEO_PLAY);
        }),
      ),
    { dispatch: false },
  );

  pauseVideo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(pauseVideoAction),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.VIDEO_PAUSE);
        }),
      ),
    { dispatch: false },
  );

  fullscreenVideo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fullscreenVideoAction),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.FULLSCREEN);
        }),
      ),
    { dispatch: false },
  );

  adjustVolume$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(adjustVideoVolumeAction),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.CHANGE_VIDEO_VOLUME);
        }),
      ),
    { dispatch: false },
  );

  scrubInVideo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scrubInVideoAction),
        tap(() => {
          reportToGA(GoogleAnalyticsEvent.VIDEO_SEEK);
        }),
      ),
    { dispatch: false },
  );

  constructor(private readonly actions$: Actions) {}
}
