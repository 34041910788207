<div
  class="snackbar"
  [class.menuCollapsed]="isMenuCollapsed$ | async"
  [@snackBarState]="snackbarState"
  (@snackBarState.done)="snackbarShowDone($event)"
>
  <div class="snackbar-content">
    <div class="message cmv-body">
      {{ snackbarEntryBegin$ | async }}
      {{
        'components.snackBar.' + (snackbarMessage$ | async)
          | translate: (snackbarMessageParameters$ | async)
      }}
      {{ snackbarEntryEnd$ | async }}
    </div>
    <div class="undo-snackbar">
      @if (snackbarUndoTagId$ | async) {
        <div class="cmv-small-button" (click)="onClickUndo()">
          <div class="cmv-h6">
            {{ 'components.snackBar.undoButton.label' | translate }}
          </div>
        </div>
      }
    </div>
    <div class="close-snackbar" (click)="hideSnackbar()">
      <mat-icon
        class="cmv-white-icon"
        svgIcon="cmv-{{ snackbarIcon$ | async }}"
      ></mat-icon>
    </div>
  </div>
  <div class="snackbar-progress-container">
    <div
      class="snackbar-progress"
      [ngStyle]="{ width: progressWidth$ | async }"
    ></div>
  </div>
</div>
