export const CHANGELOG_VERSIONS = [
  '1.31.0',
  '1.32.0',
  '1.33.0',
  '1.34.0',
  '1.35.0',
  '1.36.0',
  '1.37.0',
  '1.38.0',
];
