<div class="tree-wrap" *ngIf="isSharingLoaded$ | async; else loadingState">
  <div class="tree-ui-wrap" *ngIf="$treeExists | async; else emptyState">
    <div class="teams-wrap" *ngFor="let team of data.tree; let i = index">
      <div
        class="team-group"
        [ngClass]="{
          'team-group-expanded': data.expanded[i][team.id].expanded || false,
        }"
      >
        <div class="team-controls">
          <div class="team-controls-switch">
            <div
              class="sharing-team-label tree-icon"
              (click)="changeToTeamSharing(team)"
              [ngClass]="{
                'tree-icon-active': team.sharingMode === sharingTypes.TEAM,
              }"
            >
              <mat-icon
                class="cmv-primary-brand-icon tree-icon-team-share"
                svgIcon="cmv-player-team"
              ></mat-icon>
              <input
                type="radio"
                class="sharing-team-icon"
                value="{{ sharingTypes.TEAM }}"
              />
            </div>
            <div
              class="sharing-single-label tree-icon"
              (click)="changeToSingleSharing(team)"
              [ngClass]="{
                'tree-icon-active': team.sharingMode === sharingTypes.SINGLE,
              }"
            >
              <mat-icon
                class="cmv-white-icon tree-icon-single-share"
                svgIcon="cmv-player"
              ></mat-icon>
              <input
                type="radio"
                class="sharing-single-icon"
                value="{{ sharingTypes.SINGLE }}"
              />
            </div>
          </div>
          <div
            class="team-label cmv-h3"
            #ripple
            (click)="toggleExpanded(team.id)"
          >
            <div class="sharing-team-label-wrap">
              <div>{{ team.label }}</div>
              <div class="team-label-sharing-with cmv-listing">
                {{
                  (team.sharingMode === sharingTypes.TEAM
                    ? 'components.modals.shareModal.sharingModeTeam'
                    : 'components.modals.shareModal.sharingModeSingle'
                  ) | translate
                }}
              </div>
            </div>
            <div
              class="tree-ripple-expand"
              matRipple
              matRippleCentered="centered"
              [matRippleTrigger]="ripple"
            >
              <mat-icon
                class="cmv-gray-icon tree-expand-icon"
                svgIcon="cmv-arrow-right"
                [ngClass]="{
                  'tree-expand-icon-rotated':
                    data.expanded[i][team.id].expanded,
                }"
              ></mat-icon>
            </div>
            <input type="checkbox" class="team-expand-checkbox" />
          </div>
        </div>

        <div class="child-container" *ngIf="data.expanded[i][team.id].expanded">
          <div *ngFor="let user of team.users" class="child-array">
            <div
              class="cmv-h3 child-label"
              (click)="toggleUser(user)"
              [ngClass]="{
                'disabled-child': team.sharingMode === sharingTypes.TEAM,
              }"
            >
              <input
                *ngIf="team.sharingMode === sharingTypes.SINGLE"
                class="child-checkbox"
                type="checkbox"
              />
              <div
                class="tree-user-icon tree-user-icon-inactive tree-icon"
                [ngClass]="{
                  'tree-icon-active':
                    user.selected || team.sharingMode === sharingTypes.TEAM,
                }"
              >
                <mat-icon
                  class="cmv-gray-icon tree-child-icon"
                  svgIcon="cmv-player"
                ></mat-icon>
              </div>
              {{ user.label }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-spacer"></div>
    <div class="scroll-gradient"></div>
  </div>
  <ng-template class="empty-state" #emptyState>
    <div class="cmv-h2 empty-state">
      {{ 'components.modals.shareModal.emptyState' | translate }}
    </div>
  </ng-template>
</div>

<ng-template #loadingState>
  <cmv-loading-spinner></cmv-loading-spinner>
</ng-template>
