import { select, Store } from '@ngrx/store';
import { AppState } from '../shared/models/app.state';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { $isMenuCollapsed } from '../store/selectors/ui-flags.selectors';
import { $isFakeFullscreenActive } from '../store/selectors/video.selectors';
import { NgClass, AsyncPipe } from '@angular/common';
import { PwaInstallButtonComponent } from '../features/pwa/components/pwa-install-button/pwa-install-button.component';
import { SidebarComponent } from '../shared/components-old/sidebar/sidebar.component';
import { RouterOutlet } from '@angular/router';
import { ErrorComponent } from '../shared/components-old/error/error.component';
import { SnackbarComponent } from '../shared/components/snackbar/snackbar.component';

@Component({
  selector: 'cmv-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    PwaInstallButtonComponent,
    SidebarComponent,
    RouterOutlet,
    ErrorComponent,
    SnackbarComponent,
    AsyncPipe,
  ],
})
export class PlatformComponent {
  fakeFullscreenActive$ = this.store.pipe(select($isFakeFullscreenActive));
  isMenuCollapsed$ = this.store.pipe(select($isMenuCollapsed));

  constructor(private readonly store: Store<AppState>) {}
}
