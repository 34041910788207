import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { AppState } from '../../models/app.state';
import { Store } from '@ngrx/store';
import {
  hidePtzRequestAction,
  setPtzViewRequestAction,
} from '../../../store/actions/ptz.actions';
import { PtzView } from 'src/api/models';
import {
  $ptzActiveView,
  $ptzSetInfo,
} from '../../../store/selectors/ptz.selectors';
import { $getVideoPlayerActualTime } from '../../../store/selectors/video.selectors';
import { BehaviorSubject, Subscription } from 'rxjs';
import { MatIcon } from '@angular/material/icon';
import { NgIf, AsyncPipe } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-ptz',
  templateUrl: './ptz.component.html',
  styleUrls: ['./ptz.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, NgIf, MatProgressBar, AsyncPipe, TranslateModule],
})
export class PtzComponent implements OnDestroy {
  readonly ptzView = PtzView;

  @Input() recordingId: string;
  @Input() source: string;

  readonly ptzActiveView$ = this.store.select($ptzActiveView);

  private readonly ptzStatusValueSubject = new BehaviorSubject<Number | null>(
    null,
  );

  readonly ptzSetStatusbarValue$ = this.ptzStatusValueSubject.asObservable();
  private currentTimeSubscription: Subscription | null;
  private setInfoSubscription = this.store
    .select($ptzSetInfo)
    .subscribe(setInfo => {
      if (!setInfo) {
        this.ptzStatusValueSubject.next(null);
        return;
      }

      if (this.currentTimeSubscription) {
        this.currentTimeSubscription.unsubscribe();
        this.currentTimeSubscription = null;
      }

      this.currentTimeSubscription = this.store
        .select($getVideoPlayerActualTime)
        .subscribe(currentTime => {
          const currentTimeInSeconds = currentTime / 1000;

          const value =
            ((currentTimeInSeconds - setInfo.setAt) /
              (setInfo.changeAt - setInfo.setAt)) *
            100;

          if (value > 100) {
            this.store.dispatch(hidePtzRequestAction());
            this.currentTimeSubscription?.unsubscribe();
            this.currentTimeSubscription = null;
          }

          this.ptzStatusValueSubject.next(value);
        });
    });

  constructor(private readonly store: Store<AppState>) {}
  ngOnDestroy(): void {
    if (this.currentTimeSubscription) {
      this.currentTimeSubscription.unsubscribe();
    }
    this.setInfoSubscription.unsubscribe();
  }

  setView(manual: boolean, view?: PtzView): void {
    this.store.dispatch(
      setPtzViewRequestAction({
        recordingId: this.recordingId,
        source: this.source,
        manual,
        view,
      }),
    );
  }

  close(): void {
    this.store.dispatch(hidePtzRequestAction());
  }
}
