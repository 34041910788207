import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import moment from 'moment';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {
  Annotation,
  MetaRecording,
  Playlist,
  Recorder,
  Recording,
  RecordingTeam,
  RecordingType,
  Video,
} from '../api/models';
import { RecordingListQuery } from './shared/models/recording-list-query.model';
import {
  MATCH_DAY_FORMAT,
  MAX_TIMESTAMP,
  PANORIS_ERROR_CODES,
  PING_PONG_INTERVAL,
  TRIMPANEL_EXPAND,
  UNTIL_DEFAULT_DELAY,
  VideoStatus,
} from './app.constants';
import { TrimmingInputModel } from './shared/models/trimming-input.model';
import { CurrentVideoModel } from './shared/models/current-video.model';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

type RequiredKeys<Type> = {
  [Key in keyof Type]: {} extends { [K in Key]: Type[Key] } ? never : Key;
}[keyof Type];

export type OnlyRequired<T> = Pick<T, RequiredKeys<T>>;

export const getFullTeamName = (team: RecordingTeam): string => {
  if (!team) {
    return '';
  }
  const isClubNameSameAsTeamName = team.clubName === team.teamName;

  return isClubNameSameAsTeamName
    ? `${team.teamName}`
    : `${team.clubName}/${team.teamName}`;
};

export const getFullMatchName = (recording: Recording): string => {
  if (!recording || !recording.type) {
    return '';
  }
  switch (recording.type) {
    case RecordingType.MATCH:
      return `${getFullTeamName(recording.teamHome)} - ${getFullTeamName(
        recording.teamAway,
      )}`;
    case RecordingType.TRAINING:
      return `${getFullTeamName(recording.teamHome)}`;
    case RecordingType.OTHER:
      return `${recording.name}`;
    default:
      return '';
  }
};

export const isOlderThan = (
  unixDate: number,
  amount: number = 1,
  granularity: moment.unitOfTime.Base = 'day',
): boolean =>
  moment
    .unix(unixDate || 0)
    .add(amount, granularity)
    .unix() <= moment().unix();

export const reportToGA = (action: string, params: object = {}) => {
  const acceptedCookies = localStorage.getItem('acceptedCookies');
  if (
    acceptedCookies === 'true' &&
    !!(window as any).gtag &&
    environment.ga.enable
  ) {
    (window as any).gtag('event', action, params);
  }
};

export const TAGS_ANIMATION = [
  trigger('tagsAnimation', [
    state(
      'created',
      style({
        opacity: 1,
      }),
    ),
    transition('void=>created', [
      style({ opacity: 0 }),
      animate('1s cubic-bezier(0.4, 0.0, 0.2, 1)'),
    ]),
  ]),
];

export const getNextPingPongTime = (
  timeFromNow = PING_PONG_INTERVAL,
  unit: moment.unitOfTime.DurationConstructor = 's',
) => moment().add(timeFromNow, unit).unix();

export const registerIconsFactory =
  (registry: MatIconRegistry, sanitizer: DomSanitizer) =>
  (icons: { iconName: string; iconPath: string }[]) => {
    // eslint-disable-next-line
    for (const icon of icons) {
      registry.addSvgIcon(
        icon.iconName,
        sanitizer.bypassSecurityTrustResourceUrl(icon.iconPath),
      );
    }
  };

export const constructRecordingsQuery = (
  data: MetaRecording[],
  lastQueriedMatch = 0,
  limit = 100,
): RecordingListQuery => {
  const lastQueriedDay = lastQueriedMatch
    ? moment.unix(lastQueriedMatch).startOf('day').unix()
    : lastQueriedMatch;
  const metaQuery = data.reduce(
    (acc, curr) => {
      const currDay: moment.Moment = moment(curr.day);
      const from = currDay.unix();

      // skip if limit is already reached
      if (acc.totalCount && acc.totalCount >= limit) {
        return acc;
      }

      // skip if day is already loaded
      if (lastQueriedDay && from >= lastQueriedDay) {
        return acc;
      }

      // do not add future matches to count, because Talpa fucked up and shows only 1 hour to future
      return moment().isAfter(currDay)
        ? {
            ...acc,
            from,
            totalCount: acc.totalCount + curr.count,
          }
        : {
            ...acc,
            from,
          };
    },
    {
      from: lastQueriedDay,
      to: lastQueriedDay ? lastQueriedDay : MAX_TIMESTAMP,
      totalCount: 0,
    },
  );

  return {
    from: metaQuery.from,
    to: metaQuery.to,
  };
};

export const manualRecordingErrorCodeToTranslate = (
  error: HttpErrorResponse,
  defaultMessage: string,
) => {
  if (error.error.panorisCode && Number.isInteger(error.error.panorisCode)) {
    return panorisErrorCodeToTranslate(error.error.panorisCode);
  }

  return PANORIS_ERROR_CODES.includes(error.status)
    ? `error.manualScheduling.${error.status}`
    : defaultMessage;
};

export const panorisErrorCodeToTranslate = (code: number) =>
  `error.manualScheduling.${
    PANORIS_ERROR_CODES.includes(code) ? code : 'panorisDefault'
  }`;

// eslint-disable-next-line complexity
export const calculateTagDuration = (
  annotation: Annotation | undefined,
  video: CurrentVideoModel,
): TrimmingInputModel | undefined => {
  if (!(annotation && video)) {
    return undefined;
  }

  const tagCreated = annotation.timeStamp - video.startAt;
  const tagStart = tagCreated - annotation.from;
  const tagEnd = tagCreated + annotation.to;
  const minValue = tagStart - TRIMPANEL_EXPAND;
  const maxValue = tagEnd + TRIMPANEL_EXPAND;

  return {
    tagStart,
    tagEnd,
    minValue,
    maxValue,
  };
};

export const isIOS = (): boolean =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const sortRecordingVideos = (videos: Video[]) =>
  videos.sort((a, b) =>
    a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()),
  );

export const getRecordingTeamName = (team: RecordingTeam): string =>
  team.clubName !== team.teamName
    ? `${team.clubName}/${team.teamName}`
    : team.clubName;

export const sortPlaylists = (playlists: Playlist[]): Playlist[] =>
  playlists.sort((a, b) => a.name.localeCompare(b.name));

export const insertNewPlaylist = (
  playlists: Playlist[] | undefined,
  playlist: Playlist,
): Playlist[] => {
  const currentPlaylists = playlists || [];

  return sortPlaylists(
    currentPlaylists.find(p => p.id === playlist.id)
      ? currentPlaylists
      : [...currentPlaylists, playlist],
  );
};

export const until = (
  conditionFunction: Function,
  delay = UNTIL_DEFAULT_DELAY,
  maxTry = Infinity,
) => {
  const poll = (resolve: any, reject: any) => {
    if (conditionFunction()) {
      resolve();
    } else {
      maxTry -= 1;
      if (maxTry > 0) {
        setTimeout(() => poll(resolve, reject), delay);
      } else {
        return reject();
      }
    }
  };

  return new Promise(poll);
};

export const getVideoRemuxSnackbarMessage = (status?: string) => {
  switch (status) {
    case VideoStatus.REMUXING: {
      return 'success.videoRemux.start';
    }
    case VideoStatus.FAILED: {
      return 'error.videoRemux';
    }
    case VideoStatus.DONE: {
      return 'success.videoRemux.end';
    }
  }

  throw new Error(
    `VideoStatus(${status}) is not valid for remux snackbark message!`,
  );
};

export const getTomorrowDatetime = () => moment().add(1, 'day').startOf('day');
export const getRecordingDatetime = (recording: Recording) =>
  moment(recording.dateTime * 1000);

export const dateKey = (date: number): string =>
  moment.unix(date).format(MATCH_DAY_FORMAT);

export const groupRecordingsByKey = (
  recordings: Recording[],
  keyFn: (recording: Recording) => string,
): { [key: string]: Recording[] } =>
  recordings.reduce<{ [key: string]: Recording[] }>(
    (acc, item: any) => ({
      ...acc,
      [keyFn(item)]: [...(acc[keyFn(item)] || []), item],
    }),
    {},
  );

export const sortCurrentMatchDay = (matchDay: { [key: string]: Recording[] }) =>
  Object.keys(matchDay)
    .sort((a, b) => b.localeCompare(a))
    .map(key => matchDay[key].sort((a, b) => b.dateTime - a.dateTime));

export const secondsToHours = (value: number, round = true) => {
  let hours = value / 60 / 60;
  return round ? Math.ceil(hours) : hours;
};

export const getClubTeamName = (clubName: string, teamName: string): string => {
  let name = clubName;
  if (clubName !== teamName) {
    name += `/${teamName}`;
  }
  return name;
};

export const isRecordingEditable = (
  recording: Recording,
  recorders: Recorder[],
): boolean => recorders.some(rec => rec.id === recording.recorderId);

export const arraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  const countElements = (arr: any[]) =>
    arr.reduce((acc, el) => {
      const key = JSON.stringify(el);
      acc[key] = (acc[key] || 0) + 1;
      return acc;
    }, {} as Record<string, number>);

  const count1 = countElements(arr1);
  const count2 = countElements(arr2);

  return Object.keys(count1).every((key) => count1[key] === count2[key]);
};
