<ng-container *ngIf="match$ | async as match">
  <cmv-video-page-layout
    [showHeader]="match.status"
    [title]="'components.liveMatchTagging.title' | translate"
    [subtitle]="'components.liveMatchTagging.subtitle' | translate"
    [loadingResources]="loadingResources$ | async"
  >
    <ng-container left-column>
      <div [class.not-streaming]="(isStreamTagging$ | async) === false">
        <cmv-video-header-panel [match]="match"></cmv-video-header-panel>
      </div>
      @if (isStreamTagging$ | async) {
        <cmv-video-player-wrapper
          class="video-player"
          [isMissingVideo]="(currentVideo$ | async) === null"
          [notInRange]="(playerOffsetIsValid$ | async) === false"
          [loading]="currentVideoLoading$ | async"
          [offset]="playerOffset$ | async"
          [autoplay]="shouldAutoplay"
          [videoUrl]="currentVideoUrl$ | async"
          [createTag]="boundedCreateTag"
          [currentVideo]="currentVideo$ | async"
          [videoSources]="recordingVideos$ | async"
          (videoCurrentTime)="videoCurrentTimeChange($event)"
          (changeSrc)="changeSrc($event, true)"
          (seeked)="videoSeeked($event)"
          (playerReady)="onPlayerReady()"
          #videoPlayer
        ></cmv-video-player-wrapper>
      }

      <div class="tagging-controls">
        <cmv-live-tagging-controls [isStreamTagging]="isStreamTagging$ | async">
          <div class="tagging-controls--right">
            @if (match && isEditButtonVisible(match, recorders$ | async)) {
              <button
                [matTooltip]="'tooltips.editRecording' | translate"
                (click)="editRecording(match.id)"
                mat-icon-button
              >
                <mat-icon
                  class="button-icon cmv-dim-gray-icon open-recording-Info"
                  svgIcon="cmv-edit"
                ></mat-icon>
              </button>
            }
            @if (match && isShareButtonVisible(match, recorders$ | async)) {
              <button
                (click)="openShareModal(match.id)"
                [matTooltip]="'tooltips.shareRecording' | translate"
                mat-icon-button
              >
                <mat-icon
                  class="button-icon cmv-dim-gray-icon open-recording-Info"
                  svgIcon="cmv-share"
                ></mat-icon>
              </button>
            }
            <cmv-recording-info></cmv-recording-info>
          </div>
        </cmv-live-tagging-controls>
      </div>
      <div *ngIf="isTrimming$ | async" class="trimming-panel-wrapper">
        <cmv-trimming-panel
          [videoStartAt]="(currentVideo$ | async).startAt"
          [trimmingValues]="trimmingOffset$ | async"
          (submitChange)="trimAnnotation($event)"
          (seekTo)="seekWithHandleMove($event)"
          (valueRangeChange)="recalculateTag($event.from, $event.to)"
          (cancel)="cancelTrim()"
        ></cmv-trimming-panel>
      </div>
      <div
        class="tagging-pitch"
        *ngIf="
          (isStreamTagging$ | async) === false && (largeDeviceTagging$ | async)
        "
      >
        <div class="tagging-panel-wrapper" *ngIf="match$ | async">
          <cmv-tagging-panel
            (tagCreated)="createTag($event)"
          ></cmv-tagging-panel>
        </div>
      </div>
    </ng-container>
    <ng-container right-column>
      <ng-container *ngIf="largeDeviceTagging$ | async">
        <div
          class="tagging-pitch large-device-resolution-tagging"
          *ngIf="isStreamTagging$ | async"
        >
          <div class="tagging-panel-wrapper" *ngIf="match$ | async">
            <cmv-tagging-panel
              (tagCreated)="createTag($event)"
            ></cmv-tagging-panel>
          </div>
        </div>
        <div class="created-tags-live">
          <div
            class="cmv-section-header with-loading"
            [class.active]="isAnnotationListRendering$ | async"
          >
            <div class="cmv-section-header--inner">
              <div class="created-tags-header--buttons">
                <button
                  mat-icon-button
                  class="multiselect-icon"
                  (click)="toggleMultiselect()"
                  [matTooltip]="'tooltips.multiselect' | translate"
                >
                  <mat-icon
                    class="button-icon cmv-dim-gray-icon"
                    [svgIcon]="multiselectIcon$ | async"
                  ></mat-icon>
                </button>
                @if (showFilter$ | async) {
                  <button
                    mat-icon-button
                    class="filter-icon"
                    (click)="toggleFilter()"
                    [matTooltip]="'tooltips.tagsFilter' | translate"
                  >
                    <mat-icon
                      class="button-icon cmv-dim-gray-icon"
                      [svgIcon]="filterIcon$ | async"
                    ></mat-icon>
                  </button>
                }
              </div>
              <div class="cmv-h4">
                {{
                  ((isMultiselectEnabled$ | async)
                    ? 'components.selectedTags'
                    : 'components.createdTags'
                  ) | translate
                }}
              </div>
              <div class="create-tags-more-wrapper">
                <cmv-created-tags-more
                  *ngIf="isSharingEnabled$ | async"
                  [shareableList]="shareableList$ | async"
                  [annotationsEmpty]="(annotations$ | async).length === 0"
                  [isDownloadPrepared]="canDownloadAnnotationList$ | async"
                ></cmv-created-tags-more>
              </div>
            </div>
            <cmv-loading-bar></cmv-loading-bar>
          </div>
          <ng-container *ngTemplateOutlet="createdTags"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="(largeDeviceTagging$ | async) === false">
        <mat-tab-group
          class="small-device-resolution-tagging"
          animationDuration="0ms"
          [selectedIndex]="currentTab"
          (selectedTabChange)="tabIndexChange($event)"
        >
          <mat-tab
            label="{{ 'components.taggingPanel.addTags' | translate }}"
            class="mat-tab-tagging"
          >
            <div class="tagging-live">
              <div class="tagging-panel-wrapper" *ngIf="match$ | async">
                <cmv-tagging-panel
                  (tagCreated)="createTag($event)"
                ></cmv-tagging-panel>
              </div>
            </div>
          </mat-tab>
          <mat-tab labelClass="create-tags-label">
            <ng-template mat-tab-label class="mat-tab-label-with-icon">
              @if ((mBreakPoint$ | async) === false) {
                @if (currentTab === tagTabList.CREATED_TAGS) {
                  <div class="created-tags-header--buttons">
                    <button
                      mat-icon-button
                      class="multiselect-icon"
                      (click)="toggleMultiselect()"
                      [matTooltip]="'tooltips.multiselect' | translate"
                    >
                      <mat-icon
                        class="button-icon cmv-dim-gray-icon"
                        [svgIcon]="multiselectIcon$ | async"
                      ></mat-icon>
                    </button>
                    @if (showFilter$ | async) {
                      <button
                        mat-icon-button
                        class="filter-icon"
                        (click)="toggleFilter()"
                        [matTooltip]="'tooltips.tagsFilter' | translate"
                      >
                        <mat-icon
                          class="button-icon cmv-dim-gray-icon"
                          [svgIcon]="filterIcon$ | async"
                        ></mat-icon>
                      </button>
                    }
                  </div>
                }
              }
              <div class="tab-label-with-button">
                {{
                  ((isMultiselectEnabled$ | async)
                    ? 'components.selectedTags'
                    : 'components.createdTags'
                  ) | translate
                }}
              </div>
              <div
                class="create-tags-more-wrapper create-tags-more-wrapper-mobile"
              >
                <cmv-created-tags-more
                  *ngIf="isSharingEnabled$ | async"
                  [shareableList]="shareableList$ | async"
                  [annotationsEmpty]="(annotations$ | async).length === 0"
                  [isDownloadPrepared]="canDownloadAnnotationList$ | async"
                ></cmv-created-tags-more>
              </div>
              @if (isAnnotationListRendering$ | async) {
                <div class="created-tags-mobile-loading-bar">
                  <cmv-loading-bar></cmv-loading-bar>
                </div>
              }
            </ng-template>
            <div class="created-tags-live">
              <ng-container *ngTemplateOutlet="createdTags"></ng-container>
            </div>
          </mat-tab>
        </mat-tab-group>
      </ng-container>
    </ng-container>
  </cmv-video-page-layout>
</ng-container>

<ng-template #createdTags>
  <cmv-created-tags
    [showEmptyList]="true"
    [annotations]="annotations$ | async"
    (deleteTag)="deleteTag($event)"
    (trimVideo)="redirectToTrimming($event.id, $event.recordingId)"
    (editAnnotation)="editAnnotation($event)"
    (selectedWidgetIdChange)="selectTag($event.annotation)"
    [hideDownloadTag]="false"
    [trimmingDisabled]="(isStreamTagging$ | async) === false"
  ></cmv-created-tags>
</ng-template>
