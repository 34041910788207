import { ChangeDetectionStrategy, Component } from '@angular/core';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { PrivacyCookiePolicyComponent } from '../../../features/privacy-cookie-policy/privacy-cookie-policy.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cmv-privacy-and-cookie-policy-view',
  templateUrl: './privacy-and-cookie-policy-view.component.html',
  styleUrls: ['./privacy-and-cookie-policy-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [HeaderComponent, PrivacyCookiePolicyComponent, TranslateModule],
})
export class PrivacyAndCookiePolicyViewComponent {
  constructor() {}

  scrollTo(id: string, container: string = 'privacy-scroll-wrap'): void {
    const element = document.getElementById(id);
    const scrollContainer = document.getElementById(container);

    if (element && scrollContainer) {
      const difference = element.offsetTop - scrollContainer.scrollTop;

      scrollContainer.scrollBy({
        top: difference,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
