import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '../../models/app.state';
import { Store, select } from '@ngrx/store';
import { $currentRecording } from '../../../store/selectors/current-selections.selectors';
import { MatIconButton } from '@angular/material/button';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { MatIcon } from '@angular/material/icon';
import { NgIf, AsyncPipe, TitleCasePipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DurationPipe } from '../../pipes/duration.pipe';
import { TimePipe } from '../../pipes/time.pipe';
import { DateFormat } from '../../pipes/date-format.pipe';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'cmv-recording-info',
  templateUrl: './recording-info.component.html',
  styleUrls: ['./recording-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatIconButton,
    CdkOverlayOrigin,
    MatIcon,
    CdkConnectedOverlay,
    NgIf,
    AsyncPipe,
    TitleCasePipe,
    TranslateModule,
    DurationPipe,
    TimePipe,
    DateFormat,
    MatTooltip,
  ],
})
export class RecordingInfoComponent {
  readonly recording$ = this.store.pipe(select($currentRecording));

  isOpen = false;

  constructor(
    public readonly dialog: MatDialog,
    private readonly store: Store<AppState>,
  ) {}
}
