import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Recording } from 'src/api/models';
import { sortCurrentMatchDay } from 'src/app/app.utils';
import { MatchDayComponent } from 'src/app/shared/components-old/match-day/match-day.component';

@Component({
  selector: 'cmv-recorder-matches',
  templateUrl: './recorder-matches.component.html',
  styleUrls: ['./recorder-matches.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatchDayComponent],
})
export class RecorderMatchesComponent {
  @Input() recordingsDays: any;

  sortRecordings(recordingsDays: { [key: string]: Recording[] }) {
    console.log(recordingsDays);
    console.log(sortCurrentMatchDay(recordingsDays));
    return sortCurrentMatchDay(recordingsDays);
  }
}
