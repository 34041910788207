import { INITIAL_ENTITY_STATE, StateStatus } from '../../shared/models/app.state';
import { Club } from '../../../api/models';
import {
  getClubsFailureAction,
  getClubsRequestAction,
  getClubsSuccessAction,
} from '../actions/clubs.actions';
import { createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

export const clubsAdapter = createEntityAdapter<Club>();

const reducer = createReducer(
  INITIAL_ENTITY_STATE,
  on(getClubsRequestAction, state => ({
    ...state,
    status: StateStatus.LOADING,
  })),
  on(getClubsSuccessAction, (state, { clubs }) => ({
    ...state,
    ...clubsAdapter.setAll(clubs, state),
    status: StateStatus.LOADED,
    errors: [],
  })),
  on(getClubsFailureAction, (state, { errors }) => ({
    ...state,
    errors,
    status: StateStatus.ERROR,
  })),
);

export function clubsReducer(
  state: typeof INITIAL_ENTITY_STATE,
  action: Action,
): typeof INITIAL_ENTITY_STATE {
  return reducer(state, action);
}
