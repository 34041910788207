import { Action, createReducer, on } from '@ngrx/store';
import { RouteState } from '../../shared/models/app.state';
import { storeRouteAction } from '../actions/route.actions';

const initialState: RouteState = {
  path: '',
  params: {},
  pathSegments: [],
};

const reducer = createReducer(
  initialState,
  on(storeRouteAction, (_, { route }) => ({ ...route })),
);

export function routeReducer(state: RouteState, action: Action): RouteState {
  return reducer(state, action);
}
