<div tabindex="0" #elementToFocus class="cmv-modal-outer-card sharing-wrapper">
  <div class="cmv-modal-inner-card cmv-modal-share">
    <div class="cmv-modal-close-button" (click)="closeModal()">
      <mat-icon
        class="modal-icon-closing cmv-dim-gray"
        svgIcon="cmv-closing"
      ></mat-icon>
    </div>

    <div class="main-info main-info-share">
      <div class="icon-wrapper">
        <mat-icon
          class="modal-icon cmv-gray-icon"
          svgIcon="cmv-share"
        ></mat-icon>
      </div>
      <div class="cmv-h1">
        {{ 'components.modals.shareRecordingModal.title' | translate }}
      </div>
      <div class="cmv-body subtitle">
        {{ 'components.modals.shareRecordingModal.subtitle' | translate }}
      </div>
    </div>

    <div
      class="content-wrapper"
      *ngIf="isRecordingSharingLoaded$ | async; else loading"
    >
      <div class="email-input--wrapper">
        <mat-form-field class="cmv-input" [hideRequiredMarker]="true">
          <mat-label>
            {{ 'components.modals.shareRecordingModal.inputLabel' | translate }}
          </mat-label>
          <input
            matInput
            required
            type="email"
            maxlength="65"
            [formControl]="email"
            (keydown.enter)="addSharing()"
          />
          <mat-error *ngIf="email.invalid">
            {{ getErrorMessage() }}
          </mat-error>
        </mat-form-field>
        <button
          mat-icon-button
          (click)="addSharing()"
          class="email-input--send"
          [disabled]="!sharingIsEnabled"
        >
          <mat-icon
            class="cmv-gray-icon email-input--send-icon"
            svgIcon="cmv-add"
          ></mat-icon>
        </button>
      </div>

      <div class="recording-sharings">
        <div class="recording-sharings--title cmv-h2">
          {{
            'components.modals.shareRecordingModal.sharingsTitle' | translate
          }}
        </div>
        <div
          *ngIf="(recordingSharingUsers$ | async).length > 0; else noSharings"
          class="recording-sharings--users"
        >
          <div
            class="recording-sharings--user cmv-h3"
            *ngFor="let user of recordingSharingUsers$ | async"
          >
            <div class="recording-sharings--user-left">
              <div class="recording-sharings--user-icon--wrapper">
                <mat-icon
                  class="cmv-gray-icon recording-sharings--user-icon"
                  svgIcon="cmv-player"
                ></mat-icon>
              </div>
              <div class="recording-sharings--user-name">{{ user.name }}</div>
            </div>
            <div class="remove-sharing" (click)="removeSharing(user.id)">
              <mat-icon
                class="cmv-gray-icon remove-sharing--icon"
                svgIcon="cmv-delete"
              ></mat-icon>
            </div>
          </div>
        </div>
        <ng-template #noSharings>
          <div class="recording-sharings--no-users cmv-body">
            {{ 'components.modals.shareRecordingModal.noSharings' | translate }}
          </div>
        </ng-template>
      </div>
    </div>

    <ng-template #loading>
      <cmv-loading-spinner></cmv-loading-spinner>
    </ng-template>
  </div>
</div>
